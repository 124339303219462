import { Component, OnInit } from '@angular/core';
import { ToggleKiboSearchService } from '@shared/services/toggle-kibo-search/toggle-kibo-search.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toggle-kibo-search',
  templateUrl: './toggle-kibo-search.component.html',
  styleUrls: ['./toggle-kibo-search.component.scss'],
})
export class ToggleKiboSearchComponent implements OnInit {
  toggleIsOn: Observable<boolean>;

  constructor(private toggleKiboSearchService: ToggleKiboSearchService) {}

  ngOnInit(): void {
    this.toggleIsOn = this.toggleKiboSearchService.isKiboSearchEnabled();
  }

  setToggle() {
    this.toggleKiboSearchService.toggle();
  }
}
