<div class="zip-code-change-desktop">
  <ion-input
    class="zip-code-change-desktop-input"
    data-cy="zip-code-change-desktop-input"
    [placeholder]="'Enter New Zip Code'"
    [maxlength]="zipCodeLength"
  >
  </ion-input>
  <div class="zip-code-change-btn-centered">
    <ion-button
      class="usf-fill-green-button save-zip-code-button"
      data-cy="save-zip-code-button"
    >
      <span>{{ 'i18n.common.save' | translate }}</span>
    </ion-button>
  </div>
</div>
