/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { PimNutrientsProduction } from '@usf/product-types/Product-Detail';
import { Nutrient, RowDisplayEnum } from '../models/nutrient.model';

export const extractSupplementalFacts = (
  nutrientsList: PimNutrientsProduction[],
): PimNutrientsProduction[] => {
  if (!nutrientsList) {
    return [];
  }
  const nonSupplementalNutrients = new Set(
    labels?.map(nutrient => nutrient.toLowerCase()),
  );
  return Array.from(
    nutrientsList
      .filter(
        nutrient =>
          !nonSupplementalNutrients.has(nutrient.nutrientName.toLowerCase()) &&
          nutrient.nutrientValue > 0,
      )
      .reduce(
        (distinctCollection, supplementalFact) =>
          distinctCollection.set(
            supplementalFact.nutrientName.toLowerCase(),
            supplementalFact,
          ),
        new Map<string, PimNutrientsProduction>(),
      )
      .values(),
  );
};

export const extractCalories = (
  nutrientsList: PimNutrientsProduction[],
): number => {
  const calories = nutrientsList?.filter(
    item => item.nutrientName === 'Calories',
  );
  return calories?.length > 0 ? calories[0].nutrientValue : null;
};

export const extractServingSize = (
  nutrientsList: PimNutrientsProduction[],
): string => {
  const firstNutrientData = nutrientsList?.filter(
    item =>
      !!item.servingSizeHouseholdMeasure ||
      (!!item.servingSize && !!item.servingSizeUOM),
  )[0];
  const servingSize = !!firstNutrientData?.servingSizeHouseholdMeasure
    ? firstNutrientData?.servingSizeHouseholdMeasure
    : !!firstNutrientData
    ? `${firstNutrientData.servingSize} ${firstNutrientData.servingSizeUOM}`
    : null;
  return servingSize;
};

export const extractNutrients = (
  nutrientsList: PimNutrientsProduction[],
): Nutrient[] => {
  const map: Map<string, PimNutrientsProduction> = nutrientsList
    ?.filter(
      nutrient =>
        !new Set(labels).has(nutrient.nutrientName.toLowerCase()) &&
        nutrient.nutrientValue > 0,
    )
    .reduce(
      (distinctCollection, nutrientItem) =>
        distinctCollection.set(
          nutrientItem.nutrientName.toLowerCase(),
          nutrientItem,
        ),
      new Map<string, PimNutrientsProduction>(),
    );
  return [
    ...mainLabelItems,
    ...requiredVitaminsAndMinerals,
    ...optionalVitaminsAndMinerals,
  ]
    .filter(label => label !== 'Calories')
    .map(label => {
      let rowType = RowDisplayEnum.default;
      let data: PimNutrientsProduction = map?.get(label.toLowerCase());
      if (level2Items.has(label)) {
        rowType = RowDisplayEnum.level1;
      } else if (level3Items.has(label)) {
        rowType = RowDisplayEnum.level2;
      }

      //if it is main label and required vitamin
      if (!new Set(optionalVitaminsAndMinerals).has(label)) {
        if (!data) {
          data = {
            nutrientName: label,
            nutrientValue: 0,
            nutrientRdvPercentage: 0,
            nutrientUom: getDefaultUOM(label),
          } as PimNutrientsProduction;
        } else if (!data.nutrientUom) {
          data = { ...data, nutrientUom: getDefaultUOM(label) };
        }
      }
      //If the label is in optional and rdv or value is zero should not display.
      else if (!data?.nutrientRdvPercentage || !data?.nutrientValue) {
        data = null;
      }

      // Replace AltLabelMap
      let altLabel;
      if (!!data && !!altLabelFormatedTextMap[label]) {
        const nutrientUom = getDefaultUOM(label);
        altLabel = altLabelFormatedTextMap[label](
          `${data?.nutrientValue || 0}${nutrientUom}`,
        );
      }
      // Setting up the border
      if (label === requiredVitaminsAndMinerals[0]) {
        rowType = RowDisplayEnum.divider;
      }

      //requireVitaminAndOptional is regular text
      if (
        !new Set(mainLabelItems).has(label) &&
        rowType !== RowDisplayEnum.divider
      ) {
        rowType = RowDisplayEnum.regular;
      }
      const requiredPercentage = !new Set(optionalFieldsForPercentages).has(
        label,
      );
      return { rowType, data, altLabel, requiredPercentage };
    })
    .filter(item => !!item.data);
};
export const getDefaultUOM = (label: string): string =>
  new Set(mainLabelItems).has(label)
    ? defaultMainLabelRequiredUOMMap[label] || 'g'
    : defaultVitaminRequiredUOMMap[label] || 'mg';

export const isNutrientDataAvailable = (
  nutrientsList: PimNutrientsProduction[],
): boolean => {
  const map: Map<string, PimNutrientsProduction> = nutrientsList
    ?.filter(
      nutrient =>
        !new Set(labels).has(nutrient.nutrientName.toLowerCase()) &&
        nutrient.nutrientValue > 0,
    )
    .reduce(
      (distinctCollection, nutrientItem) =>
        distinctCollection.set(
          nutrientItem.nutrientName.toLowerCase(),
          nutrientItem,
        ),
      new Map<string, PimNutrientsProduction>(),
    );

  let isAvailable = false;
  labels.every(label => {
    const data = map?.get(label.toLowerCase());
    if (
      !new Set(optionalVitaminsAndMinerals).has(label) &&
      !!data &&
      data.nutrientValue > 0
    ) {
      isAvailable = true;
      return false;
    } else if (
      !!data &&
      data.nutrientValue > 0 &&
      !!data.nutrientRdvPercentage
    ) {
      isAvailable = true;
      return false;
    }
    return true;
  });
  return isAvailable;
};

export const filterNutrientsByPriority = (
  listPIMNutrientsProduction: PimNutrientsProduction[],
): PimNutrientsProduction[] => {
  // CHECK AND FILTER BY BasisTypeCode
  const mapBasisTypeCode = new Map<string, string>();
  listPIMNutrientsProduction?.forEach(value => {
    if (value.basisTypeCode === BasisTypeCodePriority.PerServing) {
      mapBasisTypeCode.set(
        BasisTypeCodePriority.PerServing,
        BasisTypeCodePriority.PerServing,
      );
    }
    if (value.basisTypeCode === BasisTypeCodePriority.ByMeasure) {
      mapBasisTypeCode.set(
        BasisTypeCodePriority.ByMeasure,
        BasisTypeCodePriority.ByMeasure,
      );
    }
    if (value.basisTypeCode === BasisTypeCodePriority.PerContainer) {
      mapBasisTypeCode.set(
        BasisTypeCodePriority.PerContainer,
        BasisTypeCodePriority.PerContainer,
      );
    }
    if (value.basisTypeCode === BasisTypeCodePriority.Legacy) {
      mapBasisTypeCode.set(
        BasisTypeCodePriority.Legacy,
        BasisTypeCodePriority.Legacy,
      );
    }
    if (value.basisTypeCode === BasisTypeCodePriority.Empty || null) {
      mapBasisTypeCode.set(
        BasisTypeCodePriority.Empty,
        BasisTypeCodePriority.Empty,
      );
    }
  });
  if (mapBasisTypeCode.get(BasisTypeCodePriority.PerServing)) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient => nutrient.basisTypeCode === BasisTypeCodePriority.PerServing,
    );
  } else if (mapBasisTypeCode.get(BasisTypeCodePriority.ByMeasure)) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient => nutrient.basisTypeCode === BasisTypeCodePriority.ByMeasure,
    );
  } else if (mapBasisTypeCode.get(BasisTypeCodePriority.PerContainer)) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient => nutrient.basisTypeCode === BasisTypeCodePriority.PerContainer,
    );
  } else if (mapBasisTypeCode.get(BasisTypeCodePriority.Legacy)) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient => nutrient.basisTypeCode === BasisTypeCodePriority.Legacy,
    );
  } else if (mapBasisTypeCode.get(BasisTypeCodePriority.Empty)) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient =>
        nutrient.basisTypeCode === BasisTypeCodePriority.Empty ||
        nutrient.basisTypeCode === null,
    );
  }
  // CHECK AND FILTER BY NutritionalDataLabelType
  const mapNutritionalDataLabelType = new Map<string, string>();
  listPIMNutrientsProduction?.forEach(value => {
    if (value.nutritionalDataLabelType === NutritionalDataLabelType.Standard) {
      mapNutritionalDataLabelType.set(
        NutritionalDataLabelType.Standard,
        NutritionalDataLabelType.Standard,
      );
    }
    if (
      value.nutritionalDataLabelType === NutritionalDataLabelType.RawIngredient
    ) {
      mapNutritionalDataLabelType.set(
        NutritionalDataLabelType.RawIngredient,
        NutritionalDataLabelType.RawIngredient,
      );
    }
    if (value.nutritionalDataLabelType === NutritionalDataLabelType.Prepared) {
      mapNutritionalDataLabelType.set(
        NutritionalDataLabelType.Prepared,
        NutritionalDataLabelType.Prepared,
      );
    }
    if (value.nutritionalDataLabelType === NutritionalDataLabelType.Multipack) {
      mapNutritionalDataLabelType.set(
        NutritionalDataLabelType.Multipack,
        NutritionalDataLabelType.Multipack,
      );
    }
    if (
      value.nutritionalDataLabelType === NutritionalDataLabelType.DualColumn
    ) {
      mapNutritionalDataLabelType.set(
        NutritionalDataLabelType.DualColumn,
        NutritionalDataLabelType.DualColumn,
      );
    }
    if (
      value.nutritionalDataLabelType === NutritionalDataLabelType.Empty ||
      null
    ) {
      mapNutritionalDataLabelType.set(
        NutritionalDataLabelType.Empty,
        NutritionalDataLabelType.Empty,
      );
    }
  });
  if (mapNutritionalDataLabelType.get(NutritionalDataLabelType.Standard)) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient =>
        nutrient.nutritionalDataLabelType === NutritionalDataLabelType.Standard,
    );
  } else if (
    mapNutritionalDataLabelType.get(NutritionalDataLabelType.RawIngredient)
  ) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient =>
        nutrient.nutritionalDataLabelType ===
        NutritionalDataLabelType.RawIngredient,
    );
  } else if (
    mapNutritionalDataLabelType.get(NutritionalDataLabelType.Prepared)
  ) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient =>
        nutrient.nutritionalDataLabelType === NutritionalDataLabelType.Prepared,
    );
  } else if (
    mapNutritionalDataLabelType.get(NutritionalDataLabelType.Multipack)
  ) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient =>
        nutrient.nutritionalDataLabelType ===
        NutritionalDataLabelType.Multipack,
    );
  } else if (
    mapNutritionalDataLabelType.get(NutritionalDataLabelType.DualColumn)
  ) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient =>
        nutrient.nutritionalDataLabelType ===
        NutritionalDataLabelType.DualColumn,
    );
  } else if (mapNutritionalDataLabelType.get(NutritionalDataLabelType.Empty)) {
    listPIMNutrientsProduction = listPIMNutrientsProduction.filter(
      nutrient =>
        nutrient.nutritionalDataLabelType === NutritionalDataLabelType.Empty ||
        nutrient.nutritionalDataLabelType === null,
    );
  }
  return listPIMNutrientsProduction;
};

const mainLabelItems = [
  'Calories',
  'Total Fat',
  'Saturated Fat',
  'Trans Fat',
  'Cholesterol',
  'Sodium',
  'Total Carbohydrate',
  'Dietary Fiber',
  'Total Sugar',
  'Added Sugars',
  'Protein',
];

const requiredVitaminsAndMinerals = [
  'Vitamin D',
  'Calcium',
  'Iron',
  'Potassium',
];

const optionalVitaminsAndMinerals = [
  'Calories From Fat',
  'Polyunsaturated Fat',
  'Monounsaturated Fat',
  'Soluble Fiber',
  'Insoluble Fiber',
  'Phosphorus',
  'Sugar Alcohol',
  'Carbohydrates Other',
  'Vitamin A (RE)',
  'Vitamin A IU',
  'Vitamin C',
  'Vitamin E',
  'Vitamin K',
  'Thiamin',
  'Iodine',
  'Riboflavin',
  'Niacin',
  'Vitamin B6',
  'Folate',
  'Vitamin B12',
  'Biotin',
  'Pantothenic Acid',
  'Magnesium',
];

const labels = [
  ...mainLabelItems,
  ...requiredVitaminsAndMinerals,
  ...optionalVitaminsAndMinerals,
];

const level2Items = new Set([
  'Trans Fat',
  'Saturated Fat',
  'Dietary Fiber',
  'Total Sugar',
]);

const level3Items = new Set(['Added Sugars']);

const defaultMainLabelRequiredUOMMap = {
  Cholesterol: 'mg',
  Sodium: 'mg',
};

const defaultVitaminRequiredUOMMap = {
  'Vitamin D': 'mcg',
};

const altLabelFormatedTextMap = {
  'Added Sugars': (a: string) => `Includes ${a} Added Sugars`,
  'Trans Fat': (a: string) => `<i>Trans</i> Fat ${a}`,
};

const optionalFieldsForPercentages = ['Protein', 'Trans Fat', 'Total Sugar'];

export enum BasisTypeCodePriority {
  PerServing = 'Per Serving',
  ByMeasure = 'By Measure',
  PerContainer = 'Per Container',
  Legacy = 'Legacy',
  Empty = '',
}

export enum NutritionalDataLabelType {
  Standard = 'Standard',
  RawIngredient = 'Raw Ingredient',
  Prepared = 'Prepared',
  Multipack = 'Multipack',
  DualColumn = 'Dual Column',
  Empty = '',
}
