import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController, ViewDidEnter } from '@ionic/angular';
import { SearchAnalyticsService } from 'src/app/search/services/search-analytics.service';
import { ProductStateService, SEARCH_TYPE } from '@usf/ngrx-product';
import { PlatformService } from '@panamax/app-state';
import {
  Typeahead,
  TypeaheadGroup,
  TypeaheadGroupNames,
  TypeaheadItem,
} from '@usf/product-types';
import { Subscription } from 'rxjs';
import { SEARCH_PAGES_ENUM } from '@shared/constants/search-pages.enum';
import { filter, take } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { TYPEAHEAD_DEBOUNCE_MS } from '@shared/constants/typeahead-constants';
import { FEATURES } from '@shared/constants/splitio-features';
import { ToggleKiboSearchService } from '@shared/services/toggle-kibo-search/toggle-kibo-search.service';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements ViewDidEnter, OnInit, OnDestroy {
  @ViewChild(IonSearchbar, { static: false }) searchbar: IonSearchbar;
  @Input() mode: string;
  typeaheadVisible = false;
  typeaheadNoResults = false;
  typeahead: Typeahead;
  modalTypeahead$: Subscription;
  typeaheadDebounce: number = TYPEAHEAD_DEBOUNCE_MS;
  minLength = 2;
  activeIndex = -1;
  customTypeAheadGroup: TypeaheadGroup[] = [];
  routeEvents$: Subscription;
  toogleKiboSearchFeatureFlag = FEATURES.split_user_toggle_kibo_search;
  constructor(
    private modalController: ModalController,
    readonly platformService: PlatformService,
    private searchAnalyticsService: SearchAnalyticsService,
    private navigationHelperService: NavigationHelperService,
    private productStateService: ProductStateService,
    private readonly toggleKiboSearchService: ToggleKiboSearchService,
    public router: Router,
  ) {}

  ionViewDidEnter(): void {
    // doesn't work without timeout, ref: https://github.com/ionic-team/ionic-framework/issues/17745
    setTimeout(() => this.searchbar.setFocus(), 500);
  }

  ngOnInit(): void {
    this.routeEvents$ = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(ev => {
        this.clearInputText();
        this.cancelTypeahead();
      });
  }

  ngOnDestroy(): void {
    this.routeEvents$?.unsubscribe();
    this.modalTypeahead$?.unsubscribe();
  }

  async executeSearch($event: any) {
    const searchText = $event?.target?.value?.trim();
    this.searchAnalyticsService.trackSearchSubmit(
      { searchText },
      SEARCH_TYPE.catalogSearch,
    );
    await this.closeModal();
    // Add mode property only if it exists
    const queryParams = {
      searchText: searchText,
      ...(!!this.mode && { mode: this.mode }),
    };
    this.toggleKiboSearchService
      .isKiboSearchEnabled()
      .pipe(take(1))
      .subscribe(kiboEnabled => {
        if (kiboEnabled) {
          this.navigationHelperService.routeToNewSearch(queryParams);
        } else {
          this.navigationHelperService.routeToSearch(queryParams);
        }
      });
  }

  handleInputChange(evt) {
    const inputValue = evt?.target?.value?.trim();
    if (inputValue.length > this.minLength) {
      this.modalTypeahead$?.unsubscribe();
      this.modalTypeahead$ = this.productStateService
        .getTypeahead(inputValue)
        .subscribe(result => {
          this.typeahead = this.filterGroupsWithoutItems(result);
          this.typeaheadVisible = true;
          this.typeaheadNoResults = !result?.groups?.some(
            g => g.items?.length > 0,
          );
          this.activeIndex = -1;
          this.highlightActiveIndex();
        });
    } else {
      this.typeaheadVisible = false;
      this.customTypeAheadGroup = [];
    }
  }

  filterGroupsWithoutItems(typeahead: Typeahead): Typeahead {
    if (!typeahead) return typeahead;
    return {
      ...typeahead,
      groups: typeahead.groups?.filter(group => group.items?.length > 0),
    };
  }

  highlightActiveIndex() {
    let index = 0;
    this.customTypeAheadGroup = this.typeahead.groups.map(group => {
      const typeAheadGroup = { ...group } as TypeaheadGroup;
      typeAheadGroup.items = typeAheadGroup.items.map(item => {
        const typeAheadItem = { ...item, isActive: false };
        if (index === this.activeIndex) {
          typeAheadItem.isActive = true;
        }
        index++;
        return typeAheadItem;
      });
      return typeAheadGroup;
    });
  }

  async handleItemClick(item: TypeaheadItem, category: string, rank: number) {
    if (!item || !item.searchDimensionId) {
      return;
    }
    if (category === TypeaheadGroupNames.products) {
      this.searchByProductNumber(item.searchDimensionId, rank);
    } else {
      // For Categories & Brands
      this.searchByDimensionId(item.searchDimensionId);
    }
    await this.closeModal();
  }

  searchByProductNumber(productNumber: number, rank: number) {
    const queryParams = {
      lnksrc: SEARCH_PAGES_ENUM.productTypeAhead,
      rank,
    };
    this.navigationHelperService.routeToProductDetailsQueryParams(
      productNumber,
      queryParams,
    );
    this.typeaheadVisible = false;
  }

  searchByDimensionId(dimensionId: number) {
    this.navigationHelperService.routeToSearch({
      searchFilterProperties: dimensionId,
      originSearchPage: SEARCH_PAGES_ENUM.typeAhead,
    });
    this.typeaheadVisible = false;
  }

  clearInputText() {
    if (!!this.searchbar) {
      this.searchbar.value = '';
    }
  }

  cancelTypeahead() {
    this.modalTypeahead$?.unsubscribe();
    this.typeaheadVisible = false;
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
