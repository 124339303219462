import { Injectable, OnDestroy } from '@angular/core';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import { ProfileAnalyticsService } from '@app/profile/analytics/profile-analytics.service';
import { UserActions } from '@app/user/store/actions/action-types';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PanAppState } from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { Observable, Subscription, combineLatest, map } from 'rxjs';
import { ToastService } from '../toast/toast.service';
import { UserKinds } from '@usf/user-types/user';

@Injectable({
  providedIn: 'root',
})
export class InviteNewUserService implements OnDestroy {
  onInviteNewUserSuccess$: Subscription = new Subscription();
  onInviteNewUserFail$: Subscription = new Subscription();

  constructor(
    readonly panAppState: PanAppState,
    private store: Store,
    private actionSubject: ActionsSubject,
    private toastService: ToastService,
    private translateService: TranslateService,
    private profileAnalyticsService: ProfileAnalyticsService,
  ) {}

  getInviteNewUserVisibility(): Observable<boolean> {
    return combineLatest([
      this.panAppState.feature$([FEATURES.split_global_invite_new_user]),
      this.panAppState.feature$([FEATURES.split_division_invite_new_user]),
      this.panAppState.feature$([
        FEATURES.split_division_local_only_invite_user,
      ]),
      this.panAppState.customer$,
      this.panAppState.user$,
    ]).pipe(
      map(([globalFlag, divisionFlag, isLocalCustomer, customer, user]) => {
        const showInviteNewUser =
          user?.userKind === UserKinds.Internal &&
          (!isLocalCustomer || customer?.customerType !== 'NA') &&
          globalFlag &&
          divisionFlag;

        return showInviteNewUser;
      }),
    );
  }

  setupInviteUserSubscriptions(): void {
    this.onInviteNewUserSuccess$ = this.actionSubject
      .pipe(ofType(UserActions.inviteNewUserSuccess))
      .subscribe(data => this.showSuccessToast(data.inviteEmail));

    this.onInviteNewUserFail$ = this.actionSubject
      .pipe(ofType(UserActions.inviteNewUserFail))
      .subscribe(data => this.showErrorToast(data.inviteEmail));
  }

  inviteUserFlag$ = (): Observable<boolean> => {
    return this.panAppState.feature$([FEATURES.split_global_alerts_user_mgmt]);
  };

  private showSuccessToast(inviteEmail: string): void {
    this.toastService.presentToastMsg(
      this.translateService.instant(
        'i18n.profilePage.inviteNewUser.successMsg',
      ) + inviteEmail,
      'green-toast',
      MessageTypeEnum.success,
      [],
    );
    if (this.onInviteNewUserSuccess$) {
      this.onInviteNewUserSuccess$.unsubscribe();
    }
  }

  private showErrorToast(inviteEmail: string): void {
    this.toastService.presentToastMsg(
      this.translateService.instant('i18n.profilePage.inviteNewUser.errorMsg', {
        value: inviteEmail,
      }),
      'red-toast',
      MessageTypeEnum.failure,
      [],
    );
    if (this.onInviteNewUserFail$) {
      this.onInviteNewUserFail$.unsubscribe();
    }
  }

  inviteNewUser(inviteEmail: string, customers?: any[]) {
    this.store.dispatch(
      UserActions.inviteNewUser({
        inviteEmail: inviteEmail,
        customers: customers,
      }),
    );
    this.trackInviteNewUserModalSubmit();
  }

  trackInviteNewUserModalOpen() {
    this.profileAnalyticsService.trackInviteNewUserModalOpen();
  }

  trackInviteNewUserModalSubmit() {
    this.profileAnalyticsService.trackInviteNewUserModalSubmit();
  }

  ngOnDestroy(): void {
    if (this.onInviteNewUserSuccess$) {
      this.onInviteNewUserSuccess$.unsubscribe();
    }
    if (this.onInviteNewUserFail$) {
      this.onInviteNewUserFail$.unsubscribe();
    }
  }
}
