export const FILE_EXTENSIONS = [
  'application/pdf',
  'application/csvm+json',
  'application/msword',
  'text/calendar',
  'application/octet-stream',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'text/csv',
  'application/vnd.sun.xml.calc',
  'application/vnd.sun.xml.impress',
  'application/vnd.sun.xml.writer',
  'text/plain',
  'application/vnd.ms-excel',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'application/vnd.visio',
  'audio/mpeg',
  'video/mpeg',
  'audio/ogg',
  'video/ogg',
  'audio/wav',
  'application/x-shockwave-flash',
  'video/quicktime',
  'application/vnd.visio',
];

export const CSV_LIMITED_EXTENSIONS = [
  'text/csv',
  'text/comma-separated-values',
  'application/csv',
];

export const XLSX_LIMITED_EXTENSIONS = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // For .xlsx files
  'application/vnd.ms-excel', // For .xls files
];

export const PHOTO_EXTENSTIONS = [
  'data:image/bmp',
  'data:image/gif',
  'data:image/jpeg',
  'data:image/jpg',
  'data:image/png',
  'data:image/tif',
  'data:image/tiff',
  'data:image/vsd',
];

export interface UserFile {
  filepath: string;
  name: string;
  webviewPath: string;
  extension: string;
}

export const enum FILE_ERRORS {
  wrongSize = 'File size exceeded our 5mb limit',
  wrongMime = 'File mime type did not match our whitelist',
  canceled = 'Picker was abandoned',
  delete = 'There was an issue deleting a file',
  missingAttachment = 'No attachment found',
}

export const enum FILE_ERROR_TOAST_MESSAGE {
  general = 'i18n.requestCredit.errorMessageGeneral',
  wrongSize = 'i18n.requestCredit.errorMessageWrongSize',
  mimeMismatch = 'i18n.requestCredit.errorMessageWrongMimeType',
  missingAttachment = 'i18n.requestCredit.errorMessageMissingFiles',
}

export const FILE_OPTIONS = [
  '.csv',
  '.doc',
  '.docx',
  '.dot',
  '.ics',
  '.mdb',
  '.pdf',
  '.pps',
  '.ppsx',
  '.sxc',
  '.sxi',
  '.sxw',
  '.txt',
  '.xls',
  '.xlsx',
];

export const PHOTO_OPTIONS = [
  '.bmp',
  '.gif',
  '.jpeg',
  '.jpg',
  '.png',
  '.tif',
  '.tiff',
  '.vsd',
];
