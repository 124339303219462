<ion-header class="ion-no-border ion-header-modal-mobile">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        id="back-modal-btn"
        data-cy="back-add-to-inventory-input-modal-button"
        text=""
        (click)="returnToSelectedAddInventoryActions()"
      ></ion-back-button>
    </ion-buttons>
    <ion-label data-cy="selected-add-to-inventory-option-text">
      {{ selectedAddToinventoryOption }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-modal-mobile">
  <!-- Choose List Options -->
  <div *ngIf="selectedAddToinventoryOption === addToInventory.chooseInventory">
    <ion-row class="inventory-sub-header">
      <ion-col size="10">
        <p class="select-inv-title">
          {{ 'i18n.inventory.selectAnInventory' | translate }}
        </p>
      </ion-col>
      <ion-col size="2">
        <p class="product-text">
          {{ 'i18n.inventory.products' | translate }}
        </p>
      </ion-col>
    </ion-row>
    <ion-searchbar
      id="inventory-searchbar"
      data-cy="inventory-searchbar-input"
      class="search"
      mode="md"
      placeholder="{{ 'i18n.inventory.searchForInventory' | translate }}"
      [(ngModel)]="searchKey"
      (ionClear)="resetSearchInventory()"
      (ionInput)="searchInventory()"
    >
    </ion-searchbar>
    <div
      *ngIf="filteredInventories?.length > 0"
      class="add-to-inventory-input-option"
    >
      <div
        *ngFor="let inventory of filteredInventories; let i = index"
        [attr.data-index]="i"
        data-cy="inventory-add-product"
        class="inventory-section"
        (click)="onChooseInventory(inventory)"
        [class.is-selected]="inventory === selectedInventory"
      >
        <div
          data-cy="inventory-checkbox-section"
          class="inventory-checkbox-section"
        >
          <ion-checkbox
            data-cy="inventory-checkbox"
            mode="ios"
            [checked]="inventory === selectedInventory"
            (ionChange)="onChooseInventory(inventory)"
          ></ion-checkbox>
        </div>
        <ion-item data-cy="inventory-info-section" lines="none">
          <ion-label
            id=" {{ inventory?.inventoryName }}"
            data-cy="inventory-name-text"
            class="inventory-bold-text"
          >
            {{ inventory?.inventoryName }} -
            {{ inventory?.inventoryDate | date: 'M/d/yyyy' }}
            <p class="inventory-reg-text">
              {{ 'i18n.inventory.lastUpdated' | translate }}&nbsp;{{
                inventory?.ecomUpdateDtm | date: 'M/d/yyyy'
              }}
            </p>
          </ion-label>
          <ion-badge
            aria-labelledby="inventory-name"
            data-cy="inventory-num-products-text"
            mode="ios"
            slot="end"
          >
            {{ getCount(inventory) }}
          </ion-badge>
        </ion-item>
      </div>
    </div>
    <div
      *ngIf="filteredInventories?.length === 0"
      class="add-to-inventory-input-no-data"
    >
      <span data-cy="no-inventory-to-show-messsage-text">
        {{ 'i18n.inventory.noInventoryToShow' | translate }}
      </span>
    </div>
  </div>
  <!-- Choose Group Options -->
  <div *ngIf="selectedAddToinventoryOption === addToInventory.chooseGroup">
    <ion-searchbar
      id="group-searchbar"
      data-cy="group-searchbar-input"
      class="search"
      mode="md"
      placeholder="{{ 'i18n.lists.searchGroup' | translate }}"
      [(ngModel)]="searchKey"
      (ionClear)="resetSearchGroup()"
      (ionInput)="searchGroup()"
    >
    </ion-searchbar>
    <div
      *ngIf="filteredGroups?.length > 0"
      class="add-to-inventory-input-option"
    >
      <ion-radio-group [(ngModel)]="group">
        <ion-item-divider></ion-item-divider>
        <ion-item *ngFor="let groupChoice of filteredGroups">
          <ion-label
            id="group-name"
            [attr.data-cy]="'group-name' + groupChoice?.groupName + 'text'"
          >
            {{ groupChoice?.groupName }}
          </ion-label>
          <ion-radio
            aria-labelledby="group-name"
            mode="md"
            slot="end"
            [value]="groupChoice"
            [attr.data-cy]="'group-name' + groupChoice?.groupName + 'radio'"
          ></ion-radio>
        </ion-item>
      </ion-radio-group>
    </div>
    <div
      *ngIf="filteredGroups?.length === 0"
      class="add-to-inventory-input-no-data"
    >
      <span data-cy="no-inventory-to-show-messsage-text">
        {{ 'i18n.inventory.noInventoryToShow' | translate }}
      </span>
    </div>
  </div>
  <!-- Choose Position Options -->
  <div *ngIf="selectedAddToinventoryOption === addToInventory.choosePosition">
    <div class="add-to-inventory-input-option">
      <ion-radio-group [(ngModel)]="groupPosition">
        <ion-item-divider></ion-item-divider>
        <ion-item>
          <ion-label id="top-of-group" data-cy="top-of-the-group-text">
            {{ 'i18n.lists.topOfTheGroup' | translate }}
          </ion-label>
          <ion-radio
            aria-labelledby="top-of-group"
            mode="md"
            slot="end"
            value="TOP"
            data-cy="top-of-group-radio"
          ></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label id="bottom-of-group" data-cy="bottom-of-the-group-text">
            {{ 'i18n.lists.bottomOfTheGroup' | translate }}
          </ion-label>
          <ion-radio
            aria-labelledby="bottom-of-group"
            mode="md"
            slot="end"
            value="BOTTOM"
            data-cy="bottom-of-group-radio"
          ></ion-radio>
        </ion-item>
      </ion-radio-group>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="add-to-inventory-cancel-button"
        class="usf-outline-green-mobile-modal-button"
        (click)="returnToSelectedAddInventoryActions()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="save-modal-btn"
        data-cy="save-add-to-inventory-modal-button"
        [ngClass]="
          (
            selectedAddToinventoryOption === addToInventory.chooseInventory
              ? this.selectedInventory !== undefined
              : true
          )
            ? 'usf-fill-green-mobile-modal-button'
            : 'usf-fill-disabled-mobile-modal-button'
        "
        class="usf-fill-green-mobile-modal-button"
        (click)="returnToSelectedAddInventoryActions()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
