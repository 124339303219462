import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ZipCodeChangeBaseComponent } from '../zip-code-change-base.component';

@Component({
  selector: 'app-zip-code-change-touch',
  templateUrl: './zip-code-change-touch.component.html',
  styleUrls: ['./zip-code-change-touch.component.scss'],
})
export class ZipCodeChangeTouchComponent extends ZipCodeChangeBaseComponent {
  zipCodeLength = 6;

  constructor(
    modalController: ModalController,
    platformService: PlatformService,
  ) {
    super(modalController, platformService);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }
}
