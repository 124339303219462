import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState, TokenAuthContext } from '@panamax/app-state';
import { LoginActions } from '../store/actions/action-types';
import { ORDER_ACTIONS } from '@order/actions/order-actions';
import { CART_ACTIONS } from '@usf/ngrx-order';
import { LAST_SEARCH_TERM } from '@search/shared/constants/search-config';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private store: Store<IAppState>) {}

  logout(): void {
    this.store.dispatch(LoginActions.logout());
  }

  punchout(): void {
    this.store.dispatch(LoginActions.punchout());
  }

  //Updated to use new token services via a new LoginAction
  //TODO: settle on a good pattern here ... action vs dependency
  async switchToCustomer(
    customerNumber: number,
    departmentNumber: number,
    divisionNumber: number,
  ): Promise<void> {
    sessionStorage.removeItem('orderInCart');
    sessionStorage.removeItem(LAST_SEARCH_TERM);
    this.store.dispatch(
      LoginActions.refreshTokenWithContext({
        authContext: {
          customerNumber,
          departmentNumber,
          divisionNumber,
        } as TokenAuthContext,
      }),
    );
    this.store.dispatch(CART_ACTIONS.clearCart());
    return;
  }

  switchGuestZip(zipCode: number) {
    sessionStorage.removeItem(LAST_SEARCH_TERM);
    this.store.dispatch(LoginActions.switchGuestZipCode({ zipCode }));
  }
}
