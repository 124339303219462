import { PimNutrientsProduction } from '@usf/product-types';

// eslint-disable-next-line no-shadow
export enum RowDisplayEnum {
    default = 'row',
    level1 = 'row-2',
    level2 = 'row-3',
    divider = 'divider',
    regular = 'regular'
}

export class Nutrient {
    rowType: RowDisplayEnum;
    data: PimNutrientsProduction;
    altLabel?: string;
    requiredPercentage?: boolean = true;
}
