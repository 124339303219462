import {
  GroupRow,
  ListRow,
  ProductRow,
} from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { SeperatedColumn } from '@shared/models/seperated-columns';
import { DownloadRequestFileType } from '@usf/list-types';
import { ListColumns } from './document-helper-models-and-enums';
import {
  addValueToColumns,
  getDocumentStatus,
  getDocumentApiUpdatable,
  getDocumentCurrency,
  getDocumentMultipleCurrency,
  getDocumentRaw,
  getDocumentStatusImage,
  getDocumentString,
  getDocumentTypeImage,
  getDocumentTypeText,
  getDocumentCurrencyWithZero,
  getDocumentTypeImageForList,
} from '@shared/helpers/document-creation.helpers';
import { ProductPropertiesEnum } from '@usf/product-types';

export const isProductNotAvailable = (productRow: ProductRow) => {
  return (
    productRow.summary?.productDescLong === undefined &&
    productRow.summary?.productDescTxtl === undefined
  );
};

export const operateOnColumnEachAndCasePrice = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  const priceArray = [];
  const uomArray = [];
  let result = '';

  // Exception for pricing
  if (productRow.summary?.properties?.has(ProductPropertiesEnum.specialOrder)) {
    return addValueToColumns(columnName, columnMap, 'Call for price');
  }

  if (
    productRow.pricing?.unitPrice !== undefined &&
    productRow.pricing?.unitPrice * 1 > 0 &&
    !isProductNotAvailable(productRow)
  ) {
    priceArray.push(productRow.pricing?.unitPrice);
    uomArray.push(
      productRow.summary?.catchWeightFlag
        ? productRow?.summary?.priceUom
        : productRow?.summary?.priceUom || 'CS',
    );
  } else {
    result = 'No Price';
  }

  if (
    priceArray.length > 0 &&
    result === '' &&
    !productRow.summary?.catchWeightFlag &&
    productRow.pricing?.eachPrice !== undefined &&
    productRow.pricing?.eachPrice * 1 > 0 &&
    productRow.pricing?.eachPrice !== productRow.pricing?.unitPrice &&
    productRow.summary?.priceUom !== productRow.summary?.eachUom
  ) {
    priceArray.push(productRow.pricing?.eachPrice);
    uomArray.push(productRow?.summary?.eachUom || 'EA');
  }

  if (result === '') {
    result = getDocumentMultipleCurrency(priceArray, uomArray);
  } else {
    result = 'No Price';
  }

  return addValueToColumns(columnName, columnMap, result);
};

export const operateOnColumnProductPriceWithZeroDollarDisplay = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  if (
    productRow.summary?.properties?.has(ProductPropertiesEnum.specialOrder) ||
    (productRow.summary?.productDescLong === undefined &&
      productRow.summary?.productDescTxtl === undefined)
  ) {
    return addValueToColumns(columnName, columnMap, '"$0.00"');
  }
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentCurrencyWithZero(productRow.pricing?.unitPrice),
  );
};

export const operateOnColumnProductPrice = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  if (productRow.summary?.properties?.has(ProductPropertiesEnum.specialOrder)) {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString('Call for price'),
    );
  }
  if (
    productRow.summary?.productDescLong === undefined &&
    productRow.summary?.productDescTxtl === undefined
  ) {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString('No Price'),
    );
  }
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentCurrency(productRow.pricing?.unitPrice),
  );
};

export const operateOnColumnCustomerProductNumber = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.customerProductNumber),
  );
};

export const operateOnColumnNetWeight = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow?.summary?.netWeight),
  );
};

export const operateOnColumnCatchWeightFlag = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow?.summary?.catchWeightFlag ? 'Y' : 'N'),
  );
};

export const operateOnColumnBreakable = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow?.summary?.breakable ? 'Y' : 'N'),
  );
};

export const operateOnColumnCaseCount = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentApiUpdatable(productRow.casesOrdered),
  );
};

export const operateOnColumnEachCount = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentApiUpdatable(productRow.eachesOrdered),
  );
};

export const operateOnColumnGroupName = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.groupName),
  );
};

export const operateOnColumnLineNumber = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow?.sequenceNumber),
  );
};

export const operateOnColumnOnHand = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
) => {
  return addValueToColumns(columnName, columnMap, getDocumentString(''));
};

export const operateOnColumnProductBrand = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(
      isProductNotAvailable(productRow) ? '' : productRow.summary?.brand ?? '',
    ),
  );
};

export const operateOnColumnProductDescription = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  if (isProductNotAvailable(productRow)) {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString('Not Available'),
    );
  } else {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString(
        productRow.summary?.productDescTxtl
          ? productRow.summary?.productDescTxtl
          : productRow.summary?.productDescLong,
        75,
      ),
    );
  }
};

export const operateOnColumnProductNumber = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.productNumber),
  );
};
export const operateOnColumnProductPackageSize = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
  fileType: DownloadRequestFileType,
) => {
  const packageSize = isProductNotAvailable(productRow)
    ? ''
    : productRow.summary?.salesPackSize ?? '';
  return addValueToColumns(
    columnName,
    columnMap,
    fileType === 'CSV'
      ? getDocumentRaw(packageSize)
      : getDocumentString(packageSize),
  );
};

export const operateOnColumnProductNote = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  const note = productRow?.item
    ? productRow?.item?.note
    : productRow?.masterListItem?.referenceComment;
  return addValueToColumns(columnName, columnMap, getDocumentString(note, 50));
};

export const operateOnColumnProductStatus = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentStatus(productRow),
  );
};

export const operateOnColumnProductType = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentTypeText(productRow),
  );
};

export const operateOnColumnProductUOM = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  let uom = '';

  if (
    productRow.pricing?.unitPrice !== undefined &&
    productRow.pricing?.unitPrice * 1 > 0 &&
    !isProductNotAvailable(productRow) &&
    !productRow.summary?.properties?.has(ProductPropertiesEnum.specialOrder)
  ) {
    uom = productRow.pricing?.priceUom;
  }

  return addValueToColumns(columnName, columnMap, getDocumentString(uom));
};

export const operateOnColumnStorageDescription = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.summary?.groupStorageCodeDescription),
  );
};

export const operateOnColumnClassDescription = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.summary?.classDescription),
  );
};

export const operateOnColumnImageStatus = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentStatusImage(productRow),
  );
};

export const operateOnColumnImageType = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: ProductRow,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentTypeImageForList(productRow),
  );
};

export const filterOutGroupItemTypes = (listRows: ListRow[]): ProductRow[] => {
  return listRows.filter(item => item.itemType !== 'group') as ProductRow[];
};

export const filterOutProductItemTypes = (listRows: ListRow[]): GroupRow[] => {
  return listRows.filter(item => item.itemType === 'group') as GroupRow[];
};

// general function map for efficently calling functions
export const documentFunctionMap = {
  [ListColumns.caseCount]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnCaseCount(columnName, columnMap, productRow);
  },
  [ListColumns.eachAndCasePrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnEachAndCasePrice(columnName, columnMap, productRow);
  },
  [ListColumns.customerProductNumber]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnCustomerProductNumber(
      columnName,
      columnMap,
      productRow,
    );
  },
  [ListColumns.eachCount]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnEachCount(columnName, columnMap, productRow);
  },
  [ListColumns.groupName]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnGroupName(columnName, columnMap, productRow);
  },
  [ListColumns.lineNumber]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnLineNumber(columnName, columnMap, productRow);
  },
  [ListColumns.onHand]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnOnHand(columnName, columnMap);
  },
  [ListColumns.productBrand]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductBrand(columnName, columnMap, productRow);
  },
  [ListColumns.productDescription]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductDescription(columnName, columnMap, productRow);
  },
  [ListColumns.productNote]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductNote(columnName, columnMap, productRow);
  },
  [ListColumns.productNumber]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductNumber(columnName, columnMap, productRow);
  },
  [ListColumns.productPackageSize]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductPackageSize(
      columnName,
      columnMap,
      productRow,
      fileType,
    );
  },
  [ListColumns.productPriceWithZero]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductPriceWithZeroDollarDisplay(
      columnName,
      columnMap,
      productRow,
    );
  },
  [ListColumns.productPrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductPrice(columnName, columnMap, productRow);
  },
  [ListColumns.productStatus]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductStatus(columnName, columnMap, productRow);
  },

  [ListColumns.productType]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductType(columnName, columnMap, productRow);
  },
  [ListColumns.productUom]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnProductUOM(columnName, columnMap, productRow);
  },

  [ListColumns.storageDescription]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnStorageDescription(columnName, columnMap, productRow);
  },
  [ListColumns.usFoodsClassDescription]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnClassDescription(columnName, columnMap, productRow);
  },
  [ListColumns.imageStatus]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnImageStatus(columnName, columnMap, productRow);
  },
  [ListColumns.imageType]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnImageType(columnName, columnMap, productRow);
  },
  [ListColumns.netWeight]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnNetWeight(columnName, columnMap, productRow);
  },
  [ListColumns.catchWeightFlag]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnCatchWeightFlag(columnName, columnMap, productRow);
  },
  [ListColumns.breakable]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    productRow: ProductRow,
    fileType: DownloadRequestFileType,
  ) => {
    return operateOnColumnBreakable(columnName, columnMap, productRow);
  },
};
