import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DualPaneViewModel } from '@shared/models/dual-pane-model';

@Component({
  selector: 'app-pick-a-file',
  templateUrl: './pick-a-file.component.html',
  styleUrls: ['./pick-a-file.component.scss', '../../dual-pane-shared.scss'],
})
export class PickAFileComponent {
  @Input() dataCyTag = 'dual-pane';
  @Input() vm: DualPaneViewModel;

  processingData: boolean;
  badData: boolean;
  @Output() fileAddEvent = new EventEmitter<void>();
  @Output() fileDeleteEvent = new EventEmitter<void>();
  @Output() checkValidity = new EventEmitter<void>();
  constructor() {}

  async onDeleteFile() {
    this.fileDeleteEvent.emit();
  }

  onAddFile = async () => {
    this.fileAddEvent.emit();
    this.checkValidity.emit();
  };

  async requestInfo() {
    this.vm.rightView?.filePane?.requestInfo();
  }
}
