export enum ProductCompareEnum {
  default = 'default',
  replacementCompare = 'replacementCompare',
  replacementListsCompare = 'replacementListsCompare',
  orderSubCompare = 'orderSubCompare',
  exceptionSubCompare = 'exceptionSubCompare',
  betterBuyCompare = 'betterBuyCompare',
  betterBuyListsCompare = 'betterBuyListsCompare',
  listsDetailCompare = 'listsDetailCompare',
  reviewOrder = 'reviewOrder',
  didYouForgetCompare = 'didYouForgetCompare',
  topSellerCompare = 'topSellerCompare',
  trendingNowCompare = 'trendingNowCompare',
}

export enum ButtonsEventsEnum {
  continue = 'continue',
  cancel = 'cancel',
}
