/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
@Directive({
  selector: '[alphanumeric]',
})
export class AlphaNumericDirective {
  private regex = new RegExp(/^([a-zA-Z0-9]+ ?)*$/);
  private antiRegex = new RegExp(/[^a-zA-Z\d\s]+/g);
  private specialRemoved: boolean;

  @Output() specialRemovedEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor(private el: ElementRef) {}

  // this formats the text to be pasted, then pastes the updated text where the cursor is
  @HostListener('paste', ['$event'])
  formatPaste(event: any) {
    event.preventDefault();
    const current: string = this.el.nativeElement.value;
    const cursorPos = event.target.selectionStart;
    const pasteText = event.clipboardData.getData('text') as string;
    const next: string =
      current.slice(0, cursorPos) + pasteText + current.slice(cursorPos);
    const formattedPaste = next.replace(this.antiRegex, '');
    this.specialRemovedEmitter.emit(this.antiRegex.test(pasteText));
    this.el.nativeElement.value = formattedPaste;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }
}
