import { Component, Input } from '@angular/core';
import {
  CustomerPill,
  ProductClaim,
  ProductContract,
  ProductPropertiesEnum,
  ProductSummary,
} from '@usf/product-types';
import { JitConfigMessage } from '../../../product-detail/models/jit-config-message.model';
import { ProductAlertMessage } from '../../../product-detail/models/product-alert-message';
import { RecentPurchaseRecord } from '../../models/recent-purchase.record';
import { ChipTypeEnum } from '../usf-chip/models/chip-type.enum';
import { IconConfig } from '../usf-chip/models/icon-config.model';
import { TagProduct } from '../usf-product-tag-list/models/tag-product.model';

@Component({
  selector: 'app-product-detail-status-tags-info',
  templateUrl: './product-detail-status-tags-info.component.html',
  styleUrls: ['./product-detail-status-tags-info.component.scss'],
})
export class ProductDetailStatusTagsInfoComponent {
  readonly propertiesEnum = ProductPropertiesEnum;
  readonly chipTypeEnum = ChipTypeEnum;

  @Input() productDetailStatusData: ProductDetailStatusData;
  // Temp fix to not show warning banner twice on quickView/productStatus modal
  @Input() isQuickView: boolean;

  scoopConfig: IconConfig = {
    name: 'SCOOP',
    iconWidth: 4.313,
    iconHeight: 1.438,
  };

  constructor() {}
}

export interface ProductDetailStatusData {
  isSplitCard: boolean;
  showAlternative: boolean;
  isQuickViewProductStatusModal: boolean;
  hasPromotions: boolean;
  vendorMessage: string;
  jitConfig: JitConfigMessage;
  contractPill: TagProduct;
  recentPurchaseRecords: RecentPurchaseRecord[];
  isMslRestricted: boolean;
  masterLists: string[];
  lists: string[];
  claims: ProductClaim[];
  alertMessage: ProductAlertMessage;
  contract: ProductContract;
  summary: ProductSummary;
  customerPill: CustomerPill;
}
