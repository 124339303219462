<ion-header>
  <app-usf-header-touch
    *ngIf="platformService?.isTouchExperience$() | async; else desktopHeader"
    [isSuperUser]="isSuperUser$ | async"
    [isGuestUser]="isGuestUser$ | async"
    [hideBackButton]="hideBackButton"
    [isAlerts]="isAlerts"
    [isInventory]="isInventory"
    [inventoryValue]="inventoryValue"
  >
  </app-usf-header-touch>
  <ng-template #desktopHeader>
    <app-usf-header-desktop
      [isAlerts]="isAlerts"
      [isSuperUser]="isSuperUser$ | async"
      [isGuestUser]="isGuestUser$ | async"
    ></app-usf-header-desktop>
  </ng-template>
</ion-header>
