import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PlatformService } from '@panamax/app-state';
import {
  DownloadRequestListType,
  MasterListItemResponse,
} from '@usf/list-types';
import {
  DownloadListOptions,
  MASTER_LIST_ACTIONS,
  MASTER_LIST_ITEM_ACTIONS,
  MasterListItemFacadeService,
} from '@usf/ngrx-list';
import { ProductPricingService } from '@usf/ngrx-pricing';
import {
  LoadingState,
  MslProductActions,
  ProductStateService,
  getLoadingStateOfProducts,
  selectProductInventoryEntities,
} from '@usf/ngrx-product';
import { combineLatest, filter, map, mergeMap, of, take, tap } from 'rxjs';
import { DownloadListService } from '../../../shared/services/document/download-list/download-list.service';
import { MasterListItemService } from '../../pages/master-list/services/master-list-item.service';
import { MasterListService } from '../../pages/master-list/services/master-list.service';
import { ProductService } from '../../../shared/services/product/product.service';

@Injectable({
  providedIn: 'root',
})
export class ClientMasterListEffects {
  loadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MASTER_LIST_ITEM_ACTIONS.loadSuccess),
        map(action => {
          const productNumbers = this.collectProductNumbers(
            action.masterListItems,
          );
          if (productNumbers.length === 0) {
            this.store.dispatch(
              MslProductActions.insertMslProducts({ mslProducts: [] }),
            );
          } else {
            this.productStateService.loadProductsWithRetry(productNumbers);
          }
          return { action, productNumbers };
        }),
        mergeMap(({ action, productNumbers }) => {
          return combineLatest([
            this.masterListItemFacadeService.allMasterListItems$,
            this.productService.selectLoadingStateOfProducts(productNumbers),
            this.store.select(selectProductInventoryEntities),
          ]).pipe(
            filter(
              ([masterListItems, isLoaded, inventories]) =>
                isLoaded === LoadingState.loaded,
            ),
            take(1),
          );
        }),
        map(([masterListItems, isLoaded, inventories]) => {
          if (masterListItems.length > 0) {
            this.masterListItemService.calculateMslProducts(
              masterListItems,
              inventories,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  downloadMasterList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MASTER_LIST_ACTIONS.downloadMasterList),
        // Load prices if user wants to include them
        tap(action => {
          if (action.downloadListOptions.includeProductPrices) {
            this.loadPricingForMasterListItems(action.downloadListOptions);
          }
        }),
        // Make sure product/price data is loaded before attempting to download
        mergeMap(action => {
          const loadingSelector = action.downloadListOptions
            .includeProductPrices
            ? this.masterListService.isMasterListAndPricingDataLoaded$(
                String(action.downloadListOptions.listId),
              )
            : this.masterListService.isMasterListAndProductDataLoaded$(
                String(action.downloadListOptions.listId),
              );
          return combineLatest([of(action), loadingSelector]).pipe(
            filter(([action, isLoaded]) => isLoaded),
            take(1),
          );
        }),
        // Select view model so that we have the array of data to download
        mergeMap(([action, isLoaded]) => {
          return combineLatest([
            of(action),
            this.masterListService.selectMasterListViewModel(
              action.downloadListOptions.listId,
              this.platformService.platformType,
            ),
          ]).pipe(take(1));
        }),
        // Execute download with options from action and data
        tap(([action, viewModel]) => {
          this.downloadListService.downloadList(
            action.downloadListOptions.listId,
            action.downloadListOptions.includeProductPrices,
            action.downloadListOptions.includeProductNotes,
            action.downloadListOptions.includeProductType,
            action.downloadListOptions.includeProductStatus,
            action.downloadListOptions.listTypeId as DownloadRequestListType,
            action.downloadListOptions.format,
            action.downloadListOptions.fileName,
            '',
            viewModel?.items,
            action.downloadListOptions.listName,
            action.downloadListOptions.isDownload,
          );
        }),
      ),
    {
      dispatch: false,
    },
  );

  loadPricingForMasterListItems(downloadListOptions: DownloadListOptions) {
    const masterListItemProductState = this.masterListService.selectMasterListItemProductState();
    const masterListStatesAreLoaded = this.masterListService.selectMasterListStatesAreLoaded();
    combineLatest([masterListItemProductState, masterListStatesAreLoaded])
      .pipe(
        filter(([mslItemProductState, isLoaded]) => isLoaded),
        take(1),
      )
      .subscribe(([mslItemProductState, isLoaded]) => {
        const productNumbers: number[] = [];
        // Collect product numbers from master list items on this list
        mslItemProductState.ids.forEach(id => {
          const masterListItemProduct = mslItemProductState.entities[id];
          if (
            masterListItemProduct &&
            masterListItemProduct.listId === downloadListOptions.listId
          ) {
            productNumbers.push(masterListItemProduct.productNumber);
          }
        });
        // Get prices for msl products
        this.productPricingService.getPrices(productNumbers);
      });
  }

  private collectProductNumbers(items: MasterListItemResponse[]): number[] {
    const productNumbers: number[] = [];
    items.forEach(item => {
      if (!productNumbers.includes(item.productNumber)) {
        productNumbers.push(item.productNumber);
      }
    });
    return productNumbers;
  }

  constructor(
    private actions$: Actions,
    private masterListItemService: MasterListItemService,
    private productPricingService: ProductPricingService,
    private masterListService: MasterListService,
    private platformService: PlatformService,
    private downloadListService: DownloadListService,
    private productStateService: ProductStateService,
    private productService: ProductService,
    private store: Store,
    private masterListItemFacadeService: MasterListItemFacadeService,
  ) {}
}
