<ng-container>
  <div class="basic-list-header" *ngIf="title">
    <ion-label [attr.data-cy]="dataCyTag + '-basic-list-title'">
      {{ title | translate }}
    </ion-label>
  </div>
  <ion-list
    class="basic-list-container"
    [ngClass]="
      platform === platformRef.desktop
        ? 'desktop'
        : platform === platformRef.tablet
        ? 'tablet'
        : 'mobile'
    "
  >
    <div *ngFor="let item of dataItems; let i = index">
      <ion-item-divider *ngIf="i === 0 && !item?.title" class="clamp-height">
      </ion-item-divider>
      <ion-list-header *ngIf="!!item?.title" [class.not-first]="i !== 0">
        {{ item?.title }}
      </ion-list-header>
      <ion-item-divider *ngIf="!!item?.title"></ion-item-divider>
      <app-highlighted-selectable-item
        [label]="item.label"
        [value]="!!item.subLabel ? item.subLabel : item.placeHolder"
        [selected]="item.selected"
        [index]="i"
        [attr.data-cy]="dataCyTag + '-basic-list-item-' + i"
        (clickValueEmitter)="selectItem(i)"
      ></app-highlighted-selectable-item>
    </div>
  </ion-list>
</ng-container>
