<div class="better-buy-outline">
  <div class="better-buy-card-content">
    <div class="top-row">
      <span class="sf-pro-display">{{
        'i18n.betterBuys.betterBuySuggestion' | translate
      }}</span>
      <span class="close-icon" (click)="declineSwapHandler()"
        ><ion-icon name="close-outline"></ion-icon
      ></span>
    </div>

    <div class="content-row">
      <div class="product-details-row">
        <div class="image-column">
          <app-product-card-image
            [isOnline]="isOnline"
            [imageThumbnail]="betterBuyProduct?.product?.imageThumbnail"
            [productNumber]="betterBuyProduct?.product?.productNumber"
            [clickable]="!product?.notFound"
            (productImageClickEmitter)="productClick()"
          ></app-product-card-image>
        </div>
        <div class="information-column">
          <app-product-card-information
            [brand]="betterBuyProduct?.product?.summary?.brand"
            [description]="betterBuyProduct?.product?.summary?.productDescTxtl"
            [productNumber]="betterBuyProduct?.product?.productNumber"
            [salesPackSize]="betterBuyProduct?.product?.summary?.salesPackSize"
            [truncateAmount]="platformType === platformRef.mobile ? 30 : 25"
            (productDescriptionClickEmitter)="productClick()"
            [pricePerPortion]="betterBuyProduct?.product?.pricePerPortion"
          >
          </app-product-card-information>
        </div>
      </div>
      <div class="pricing-row">
        <app-product-card-ordering
          [productSummary]="betterBuyProduct?.product?.summary"
          [pricing]="betterBuyProduct?.product?.pricing"
          [casesOrdered]="betterBuyProduct?.product?.casesOrdered"
          [eachesOrdered]="betterBuyProduct?.product?.eachesOrdered"
          [isOrderable]="betterBuyProduct?.product?.orderable"
          [isOnline]="isOnline"
          [platformType]="platformType"
          [index]="index"
          [listId]="listId"
          [hotkeyIds]="betterBuyProduct?.product?.hotkeyIds"
          [pricingDisabled]="pricingDisabled"
          (quantityUpdateEmitter)="
            changeQuantityHandler($event, betterBuyProduct)
          "
          (quantityElementEmitter)="quantityInputUpdate($event)"
        >
        </app-product-card-ordering>
      </div>
      <app-better-buy-savings
        [savings]="betterBuyProduct?.savings"
        [savingsUom]="betterBuyProduct?.savingsUom"
        [annualSavings]="betterBuyProduct?.annualSavings"
        [annualSavingsUom]="betterBuyProduct?.annualSavingsUom"
        [flexDirection]="'column'"
        [pricingAvailable]="
          (product?.pricing?.eachPrice > 0 ||
            product?.pricing?.unitPrice > 0) &&
          (betterBuyProduct?.product?.pricing?.eachPrice > 0 ||
            betterBuyProduct?.product?.pricing?.unitPrice > 0)
        "
      ></app-better-buy-savings>
      <div
        *ngIf="betterBuyProduct?.product?.productTags?.length > 0"
        class="tags-row"
      >
        <app-usf-product-tag-list
          [tagList]="betterBuyProduct?.product?.productTags"
          [infoIconEnabled]="false"
          [allTagsClickable]="true"
          [product]="transformProductToProductDetail()"
          [maxTags]="3"
        >
        </app-usf-product-tag-list>
      </div>
      <div class="buttons-row">
        <div class="compare-column">
          <ion-button
            class="usf-outline-green-button"
            (click)="goToCompareProducts()"
          >
            <div class="compare-icon">
              <img
                data-cy="better-buy-card-compare-icon"
                src="assets/icon/Compare.svg"
              />
            </div>
            {{ 'i18n.betterBuys.compare' | translate }}</ion-button
          >
        </div>
        <div class="accept-swap-column">
          <ion-button (click)="acceptSwap()" class="usf-fill-green-button">{{
            'i18n.betterBuys.acceptSwap' | translate
          }}</ion-button>
        </div>
      </div>
    </div>
  </div>
</div>
