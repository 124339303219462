import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import {
  defTraceTransFunc,
  defSuccessTraceTransFunc,
  defTrackTransFunc,
  upcLinkTransformerFunc,
  inviteNewUserModalOpen,
  inviteNewUserModalSubmit,
} from './profile-analytics.transformers';
import { PROFILE_ANALYTICS_ACTIONS } from './profile-analytics.actions';
import {
  clearAppDataSuccessTrace,
  clearAppDataTrace,
  logoutAllSessionsTrace,
  logoutAllSessionsTraceSuccess,
} from './profile-analytics.trace';
import { ProfileAnalyticsMessages } from './profile-analytics.constants';

@Injectable({
  providedIn: 'root',
})
export class ProfileAnalyticsService {
  constructor(private store: Store) {}

  trackPageLoad(): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          pageName: ProfileAnalyticsMessages.pageName,
          event: ProfileAnalyticsMessages.pageLoadedEvent,
          capability: ProfileAnalyticsMessages.capability,
        },
        transformFunc: defTrackTransFunc,
      },
    };
    this.store.dispatch(PROFILE_ANALYTICS_ACTIONS.trackPageLoad({ tracking }));
  }

  trackUPCLink(url: string): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          linkUrl: url,
          event: ProfileAnalyticsMessages.upcLink,
          capability: ProfileAnalyticsMessages.capability,
        },
        transformFunc: upcLinkTransformerFunc,
      },
    };
    this.store.dispatch(PROFILE_ANALYTICS_ACTIONS.trackUPCLink({ tracking }));
  }

  traceLogoutSession(userId: number): void {
    const tracking: Tracking = JSON.parse(
      JSON.stringify(logoutAllSessionsTrace),
    );
    tracking.tracing.data.attributes.userID = userId;
    tracking.tracing.transformFunc = defTraceTransFunc;

    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.logoutAllSessions({ tracking }),
    );
  }

  traceLogoutSessionSuccess(userId: number): void {
    const tracking: Tracking = JSON.parse(
      JSON.stringify(logoutAllSessionsTraceSuccess),
    );
    tracking.tracing.data.attributes.userID = userId;
    tracking.tracing.transformFunc = defSuccessTraceTransFunc;

    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.logoutAllSessionsSuccess({ tracking }),
    );
  }

  clearAppDataTracking(): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          pageName: ProfileAnalyticsMessages.pageName,
          event: ProfileAnalyticsMessages.clearAppDataTrackingEvent,
          capability: ProfileAnalyticsMessages.capability,
        },
        transformFunc: defTrackTransFunc,
      },
    };
    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.clearAppDataTracking({ tracking }),
    );
  }

  clearAppData(userId?: number): void {
    const tracking: Tracking = JSON.parse(JSON.stringify(clearAppDataTrace));
    tracking.tracing.data.attributes.userID = userId || undefined;
    tracking.tracing.transformFunc = defTraceTransFunc;

    this.store.dispatch(PROFILE_ANALYTICS_ACTIONS.clearAppData({ tracking }));
  }

  clearAppDataSuccess(userId?: number): void {
    const tracking: Tracking = JSON.parse(
      JSON.stringify(clearAppDataSuccessTrace),
    );
    tracking.tracing.data.attributes.userID = userId || undefined;
    tracking.tracing.transformFunc = defSuccessTraceTransFunc;

    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.clearAppDataSuccess({ tracking }),
    );
  }

  trackInviteNewUserModalOpen(): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          modal: {
            name: ProfileAnalyticsMessages.inviteNewUserModalName,
          },
          event: ProfileAnalyticsMessages.inviteNewUserModalEvent,
          capability: ProfileAnalyticsMessages.capability,
        },
        transformFunc: inviteNewUserModalOpen,
      },
    };
    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.trackInviteNewUserModalOpen({ tracking }),
    );
  }

  trackInviteNewUserModalSubmit(): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          event: ProfileAnalyticsMessages.inviteNewUserModalName,
          capability: ProfileAnalyticsMessages.capability,
        },
        transformFunc: inviteNewUserModalSubmit,
      },
    };
    this.store.dispatch(
      PROFILE_ANALYTICS_ACTIONS.trackInviteNewUserModalSubmit({ tracking }),
    );
  }
}
