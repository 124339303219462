import { Component, Input, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { PlatformEnum, PlatformService } from '@panamax/app-state';

@Component({
  template: '',
})
export class ZipCodeChangeBaseComponent {
  @Input() showSearchbar = true;
  @ViewChild(IonSearchbar, { static: false }) searchbar: IonSearchbar;

  searchText = '';
  PlatformEnum = PlatformEnum;

  constructor(
    private modalController: ModalController,
    readonly platformService: PlatformService,
  ) {}

  async close() {
    await this.modalController.dismiss();
  }
}
