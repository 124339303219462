<p class="item-caption top-label">
  {{ vm?.rightView?.filePane?.fileTitle ?? ''| translate }}
</p>
<!-- Add File -->
<div
  data-cy="file-card"
  class="file-card"
  [class.error]="vm?.rightView?.error | async"
  *ngIf="!vm?.rightView?.value && !vm?.rightView?.filePane?.showSpinner"
  (click)="onAddFile()"
>
  <div data-cy="add-file-button" class="add-file">
    <ion-icon data-cy="add-file-icon" name="reader-outline"></ion-icon>
    <ion-label class="item-subtitle-regular go-green" data-cy="add-file-text">
      {{ 'i18n.requestCredit.uploadAFile' | translate }}
    </ion-label>
  </div>
</div>
<!-- View File -->
<div
  data-cy="file-card"
  class="file-card"
  [class.error]="vm?.rightView?.error | async"
  *ngIf="!!vm?.rightView?.value && !vm?.rightView?.filePane?.showSpinner"
  (click)="onDeleteFile()"
>
  <div class="view-file">
    <div class="file-info">
      <ion-icon
        data-cy="view-file-icon"
        class="icon-file"
        name="reader"
      ></ion-icon>
      <ion-label data-cy="view-file-text">
        {{ vm?.rightView?.value?.name | truncateWithEllipsis: 20 }}
      </ion-label>
    </div>
    <div class="delete-change-file">
      <a data-cy="delete-file-button">
        {{ 'i18n.common.delete' | translate }}
      </a>
    </div>
  </div>
</div>
<div
  data-cy="fild-spinner-card"
  class="file-card"
  *ngIf="vm?.rightView?.filePane?.showSpinner"
>
  <app-loading-spinner></app-loading-spinner>
</div>
<p class="bad-data" *ngIf="vm?.rightView?.error | async">
  {{ 'i18n.lists.importListModal.fileError' | translate }}
</p>
<p
  *ngIf="vm?.rightView?.filePane?.showExtraInfo"
  (click)="requestInfo()"
  class="template item-subtitle-regular go-green"
>
  <ion-icon name="download-outline" data-cy="template-download-icon"></ion-icon>
  {{ vm?.rightView?.filePane?.infoLabel | translate }}
</p>
