import jsPDF from 'jspdf';
import { CustomerDivisionDepartment } from '../../../../ngrx-customer/models/customer-division-department.model';
import { PDFColors } from '../../../constants/pdf-colors.enum';
import {
  ImageIconPair,
  ProductRowsAndNeededImages,
} from './document-helper-models-and-enums';
import autoTable, {
  CellDef,
  CellHookData,
  HAlignType,
  Styles,
} from 'jspdf-autotable';
import { desiredColumn } from '../../../models/desired-column';
import { SeperatedColumn } from '../../../models/seperated-columns';
import { InventoryPdfOptions } from '@shared/models/inventory-pdf-options';
import {
  BODY_FONT,
  FOOTER_FONT,
  HEADER_FONT,
  LARGE_HEADER_FONT,
  LOGO_FONT,
  SUB_HEADER_FONT,
} from '@shared/constants/pdf-font';
import { InventoryViewModel } from '../../../../inventory/models/inventory-view.model';
import { DatePipe } from '@angular/common';
import { InventoryStatus } from '@usf/inventory-types';

export const loadImage = src =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });

export const addHeaderToPDF = async (
  doc: jsPDF,
  name: string,
  numberOfProducts: number,
  customerDivisionDepartment: CustomerDivisionDepartment,
  isMobile: boolean,
  inventory: InventoryViewModel,
) => {
  await addLogoToHeader(doc, isMobile);
  addNameToHeader(doc, name);
  addProductCountToHeader(doc, name, numberOfProducts);
  addTimeStampsToHeader(doc, isMobile, inventory);
  addUserDataToHeader(doc, customerDivisionDepartment, isMobile);
};

export const addLogoToHeader = async (doc: jsPDF, isMobile: boolean) => {
  if (!isMobile) {
    const usfLogo = await loadImage('../assets/images/usfoods-logo-print.jpg');
    doc.addImage(usfLogo, 35, 25, 82, 80);
  } else {
    doc.setFontSize(LOGO_FONT);
    doc.setFont('helvetica', 'bold');
    doc.text('US', 65, 55);
    doc.text('Foods', 50, 80);
  }
};

export const addNameToHeader = (doc: jsPDF, inventoryName: string) => {
  doc.setFontSize(LARGE_HEADER_FONT);
  doc.setFont('helvetica', 'bold');
  doc.text(inventoryName, 140, 50);
};

export const addProductCountToHeader = (
  doc: jsPDF,
  name: string,
  productCount: number,
) => {
  doc.setFontSize(HEADER_FONT);
  const numLower = name.replace(/[^a-z]/g, '').length;
  const numUpperAndSpecial = name.length - numLower;
  const lenOfName = 18 * numUpperAndSpecial + 16 * numLower;
  const productText =
    productCount + ' ' + (productCount === 1 ? 'product' : 'products');
  doc.text(productText, lenOfName + 140, 50);
};

export const addTimeStampsToHeader = (
  doc: jsPDF,
  isMobile: boolean,
  inventory: InventoryViewModel,
) => {
  doc.setFontSize(SUB_HEADER_FONT);
  doc.setFont('helvetica', 'normal');
  const createdDateString = getCreatedDateString(inventory);
  doc.text(createdDateString, 140, isMobile ? 75 : 85);
  const completedDateString = getCompletedDateString(inventory);
  if (completedDateString) {
    const completedXPosition = 140 + createdDateString.length * 6 + 40;
    doc.text(completedDateString, completedXPosition, isMobile ? 75 : 85);
  }
};

export const getCreatedDateString = (inventory: InventoryViewModel) => {
  let createdDateString = '';
  const createdDay = new DatePipe('en').transform(
    inventory.ecomCreatedDtm,
    'M/d/yyyy',
  );
  const createdTime = new DatePipe('en').transform(
    inventory.ecomCreatedDtm,
    'h:mm a',
  );
  createdDateString = 'Created: ' + createdDay + ' at ' + createdTime;
  return createdDateString;
};

export const getCompletedDateString = (inventory: InventoryViewModel) => {
  let completedDateString = '';
  if (
    inventory.status === InventoryStatus.COMPLETED &&
    inventory.completedDtm
  ) {
    const completedDay = new DatePipe('en').transform(
      inventory.completedDtm,
      'M/d/yyyy',
    );
    const completedTime = new DatePipe('en').transform(
      inventory.completedDtm,
      'h:mm a',
    );
    completedDateString = 'Completed: ' + completedDay + ' at ' + completedTime;
  }
  return completedDateString;
};

export const addUserDataToHeader = (
  doc: jsPDF,
  customerDivisionDepartment: CustomerDivisionDepartment,
  isMobile: boolean,
) => {
  const lastLineHeight = isMobile ? 95 : 105;
  let runningXPosition = 140;
  doc.setFontSize(SUB_HEADER_FONT);
  doc.setFont('helvetica', 'bold');
  doc.text('Division: ', runningXPosition, lastLineHeight);
  runningXPosition += 'Division: '.length * 7;
  doc.setFont('helvetica', 'normal');
  doc.text(
    `${customerDivisionDepartment.divisionName} (${customerDivisionDepartment.divisionNumber})`,
    runningXPosition,
    lastLineHeight,
  );
  runningXPosition +=
    `${customerDivisionDepartment.divisionName} (${customerDivisionDepartment.divisionNumber})`
      .length *
      7 +
    40;
  doc.setFont('helvetica', 'bold');
  doc.text('Customer: ', runningXPosition, lastLineHeight);
  runningXPosition += `Customer: `.length * 7;
  doc.setFont('helvetica', 'normal');
  doc.text(
    `${customerDivisionDepartment.customerName} (${customerDivisionDepartment.customerNumber})`,
    runningXPosition,
    lastLineHeight,
  );
  runningXPosition +=
    `${customerDivisionDepartment.customerName} (${customerDivisionDepartment.customerNumber})`
      .length *
      7 +
    40;

  if (!!customerDivisionDepartment.departmentName) {
    doc.setFont('helvetica', 'bold');
    doc.text('Department: ', runningXPosition, lastLineHeight);
    runningXPosition += 'Department: '.length * 7;
    doc.setFont('helvetica', 'normal');
    doc.text(
      `${customerDivisionDepartment.departmentName}(${customerDivisionDepartment.departmentNumber})`,
      runningXPosition,
      lastLineHeight,
    );
  }

  doc.setDrawColor(PDFColors.pdfGrey);
  doc.line(
    0,
    lastLineHeight + 25,
    doc.internal.pageSize.getWidth(),
    lastLineHeight + 25,
  );
};

export const addFooterToPDF = async (doc: jsPDF) => {
  const pageCount: number = (doc as any).internal.getNumberOfPages();
  const footerHeight = 50;
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    doc.line(
      0,
      pageHeight - footerHeight,
      pageSize.getWidth(),
      pageHeight - footerHeight,
    );

    doc.setFontSize(FOOTER_FONT);
    doc.text(
      'For the exclusive use by US Foods customers',
      20,
      pageHeight - footerHeight / 2,
    );

    let xOffset = 225;
    if (i > 9) {
      xOffset = 235;
    } else if (i > 99) {
      xOffset = 250;
    }

    const printDate = new Date();
    doc.text(
      `Print Date: ${printDate
        .toLocaleDateString('en-US')
        .toString()}  |  Page ${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - xOffset,
      pageHeight - footerHeight / 2,
    );
  }
};

export const getImageMap = async (
  imageColumnIndexes: number[],
  imageKeys: Set<string>,
): Promise<Map<string, HTMLImageElement>> => {
  const imageMap = new Map<string, HTMLImageElement>();
  let imagePromises: Promise<ImageIconPair>[] = [];
  if (imageColumnIndexes.length > 0) {
    imageKeys.forEach(imageSrc => {
      imagePromises.push(loadImagePair(imageSrc));
    });
  }
  const imageArray = await Promise.all(imagePromises);
  imageArray.forEach(imageIconPair => {
    imageMap.set(imageIconPair.icon, imageIconPair.image);
  });
  return imageMap;
};

export const loadImagePair = src =>
  new Promise<ImageIconPair>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ icon: src, image: img });
    img.onerror = reject;
    img.src = '../../../../../assets/' + src;
  });

// Styles that related to column cells should be added here
export const getColumnStyles = (styleColumnReference: desiredColumn[]) => {
  let columnStyles: { [key: string]: Partial<Styles> } = {};
  let colSpanIndexAdjustmment = 0;
  for (let index = 0; index < styleColumnReference.length; index++) {
    const column = styleColumnReference[index];
    let columnToPush = {};
    const adjustedIndex = index + colSpanIndexAdjustmment;

    if (column.halign) {
      columnToPush = {
        ...columnToPush,
        halign: column.halign as HAlignType,
      };
    }

    if (column.cellPadding) {
      columnToPush = {
        ...columnToPush,
        cellPadding: column.cellPadding,
      };
    }

    if (column.colSpan) {
      for (
        let colSpanIndex = 0;
        colSpanIndex < column.colSpan;
        colSpanIndex++
      ) {
        columnStyles[adjustedIndex + colSpanIndex] = columnToPush;
      }
      colSpanIndexAdjustmment += column.colSpan;
    } else {
      columnStyles[adjustedIndex] = columnToPush;
    }
  }
  return columnStyles;
};

export const createProductRows = (
  seperatedColumns: SeperatedColumn[],
  groupForProducts: string[],
  isMobile: boolean,
): ProductRowsAndNeededImages => {
  const productRowsByGroup: string[][][] = [];
  const imageKeys: Set<string> = new Set();
  const groupMap: Map<string, string[][]> = new Map();
  if (seperatedColumns.length > 0) {
    for (let i = 0; i < seperatedColumns[0].columnValues.length; i++) {
      const groupName = groupForProducts[i];
      const productDataArray = [];
      seperatedColumns.forEach(value => {
        if (value.columnName === 'On Hand / Order') {
          productDataArray.push(...['', '', '', '', '', '', '']);
        } else if (
          (value.columnName === 'Product Status' ||
            value.columnName === 'Product Type') &&
          !isMobile
        ) {
          productDataArray.push(value.columnValues[i].toString());
          if (
            value.columnValues[i].toString() !== '' &&
            !imageKeys.has(value.columnValues[i].toString())
          ) {
            imageKeys.add(value.columnValues[i].toString());
          }
        } else {
          productDataArray.push(
            value.columnValues[i].toString().replaceAll('"', ''),
          );
        }
      });

      if (!groupMap.has(groupName)) {
        groupMap.set(groupName, [productDataArray]);
      } else {
        groupMap.get(groupName).push(productDataArray);
      }
    }
  }
  groupMap.forEach(value => {
    productRowsByGroup.push(value);
  });
  return { productRowsByGroup, imageKeys };
};

export const createProductRowsGLCodes = (
  seperatedColumns: SeperatedColumn[],
): ProductRowsAndNeededImages => {
  const productRowsByGroup: string[][][] = [];
  const glCodeNoDeets: string[][] = [];
  const imageKeys: Set<string> = new Set();
  if (seperatedColumns.length > 0) {
    for (let i = 0; i < seperatedColumns[0].columnValues.length; i++) {
      const productDataArray = [];
      seperatedColumns.forEach(value => {
        productDataArray.push(
          value.columnValues[i].toString().replaceAll('"', ''),
        );
      });
      glCodeNoDeets.push(productDataArray);
    }
  }
  productRowsByGroup.push(glCodeNoDeets);
  return { productRowsByGroup, imageKeys };
};

// Styles that need to match column headers and columns cells should be adder here.
export const createHeadersForPDF = (columns: desiredColumn[]): CellDef[][] => {
  const headerStructure = [[]];
  columns.forEach(column => {
    let headerToPush: CellDef = {
      content: column.columnName,
      styles: {
        fillColor: PDFColors.pdfBlack,
        halign: 'center',
        valign: 'middle',
        minCellHeight: 34,
      },
    };
    if (column.cellWidth) {
      headerToPush.styles = {
        ...headerToPush.styles,
        cellWidth: column.cellWidth,
      };
    }
    if (column.colSpan) {
      headerToPush = { ...headerToPush, colSpan: column.colSpan };
    }
    headerStructure[0].push(headerToPush);
  });
  return headerStructure;
};

export const addGroupRow = (
  doc: jsPDF,
  itemTablePerGroup: string[][],
  groupName: string,
  nextTableYPosition: number,
) => {
  if (nextTableYPosition > 860) {
    doc.addPage('', 'l');
    nextTableYPosition = 25;
  }
  const groupText =
    itemTablePerGroup?.length === 1
      ? `${groupName} (1 product)`
      : `${groupName} (${itemTablePerGroup.length} products)`;
  autoTable(doc, {
    body: [
      [
        {
          content: groupText,
          styles: {
            fillColor: PDFColors.pdfWhite,
            halign: 'left',
            valign: 'middle',
            fontSize: BODY_FONT,
            textColor: PDFColors.pdfBlack,
            lineColor: PDFColors.pdfBlack,
            lineWidth: 1,
            minCellHeight: 34,
            font: 'helvetica',
            fontStyle: 'bold',
            cellPadding: { left: 10 },
          },
        },
      ],
    ],
    startY: nextTableYPosition,
    margin: {
      left: 10,
      right: 10,
      bottom: 100,
    },
    theme: 'grid',
    tableWidth: 'auto',
    pageBreak: 'auto',
    rowPageBreak: 'avoid',
    styles: {
      lineColor: PDFColors.pdfBlack,
      lineWidth: 1,
    },
  });
};

export const addDataColumns = (
  doc: jsPDF,
  itemTablePerGroup: string[][],
  nextTableYPosition: number,
  cellDefinitions: CellDef[][],
  desiredColumns: desiredColumn[],
  imageMap: Map<string, HTMLImageElement>,
  imageColumnIndexes: number[],
  inventoryPdfOptions?: InventoryPdfOptions,
) => {
  const columnStyles = getColumnStyles(desiredColumns);
  autoTable(doc, {
    head: cellDefinitions,
    body: itemTablePerGroup,
    startY: nextTableYPosition,
    margin: {
      left: 10,
      right: 10,
      bottom: 100,
    },
    theme: 'grid',
    tableWidth: 'auto',
    pageBreak: 'auto',
    rowPageBreak: 'avoid',
    styles: {
      fontSize: BODY_FONT,
      halign: 'center',
      valign: 'middle',
      lineColor: PDFColors.pdfBlack,
      lineWidth: 1,
      minCellHeight: 40,
    },
    columnStyles,
    willDrawCell: (cellData: CellHookData) => {
      if (
        cellData.row.section == 'body' &&
        imageColumnIndexes.includes(cellData.column.index)
      ) {
        doc.setTextColor(PDFColors.pdfWhite);
      } else if (cellData.row.section == 'body') {
        doc.setTextColor(PDFColors.pdfBlack);
      }
    },
    didDrawCell: (cellData: CellHookData) => {
      if (
        cellData.row.section == 'body' &&
        imageColumnIndexes.includes(cellData.column.index)
      ) {
        let imageIcon = '';
        cellData.cell.text.forEach(text => (imageIcon += text));
        const imageFound = imageIcon !== '';
        if (imageFound) {
          try {
            doc.addImage(
              imageMap.get(imageIcon),
              'PNG',
              cellData.cell.x + 7.5,
              cellData.cell.y + (cellData.cell.height - 20) / 2,
              90,
              20,
            );
          } catch (ignore) {}
        }
      }
      if (
        cellData.row.section == 'body' &&
        inventoryPdfOptions?.includeFreehandCount
      ) {
        if (
          (inventoryPdfOptions.includeProductStatus &&
            cellData.column.index === 8) ||
          (!inventoryPdfOptions.includeProductStatus &&
            cellData.column.index === 6)
        ) {
          const cellWidth = cellData.cell.width / 7;
          for (let i = 0; i < 6; i++) {
            doc.line(
              cellData.cell.x + cellWidth * (i + 1),
              cellData.cell.y + cellData.cell.height,
              cellData.cell.x + cellWidth * (i + 1),
              cellData.cell.y,
            );
          }
        }
      }
    },
  });
};
