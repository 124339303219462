import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ListAnalyticsHelperService } from '@app/lists/services/list-analytics-helper.service';
import {
  ListRow,
  ProductRow,
} from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { selectedCustomer } from '@app/ngrx-customer/store';
import { MessageStoreService } from '@app/ngrx-message/services/message/message-store.service';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UsfTokenStorageService } from '@panamax/app-state';
import { desiredColumn } from '@shared/models/desired-column';
import { SeperatedColumn } from '@shared/models/seperated-columns';
import { SentenceCasePipe } from '@shared/pipes/sentence-case.pipe';
import { LoadingSpinnerService } from '@shared/services/loading-spinner/loading-spinner.service';
import { ToastService } from '@shared/services/toast/toast.service';
import {
  DownloadRequestFileType,
  DownloadRequestListType,
  ImportErrorDetail,
} from '@usf/list-types';
import { first } from 'rxjs';
import { DownloadPrintBaseService } from '../download-print-base.service';
import {
  documentFunctionMap,
  filterOutGroupItemTypes,
  filterOutProductItemTypes,
} from '../helpers/download-list-helper';
import {
  createHeadersForPDF,
  createProductRows,
} from '../helpers/general-pdf-helper';
import { getASCIIColumns, stringifyASCII } from '../helpers/list-ascii-helper';
import { getCSVColumns, stringifyCSV } from '../helpers/list-csv-helper';
import {
  collectGroupNames,
  collectGroupRows,
  getPDFColumns,
  pdfToBlob,
} from '../helpers/list-pdf-helper';
import { getPRICEColumns, stringifyPRICE } from '../helpers/list-price-helper';
import { getXMLColumns, stringifyXML } from '../helpers/list-xml-helper';
import { Papa } from 'ngx-papaparse';
import { utils, write } from 'xlsx';
import { b64toBlob } from '@shared/helpers/file.helpers';

@Injectable({
  providedIn: 'root',
})
export class DownloadListService extends DownloadPrintBaseService {
  constructor(
    http: HttpClient,
    toastService: ToastService,
    translateService: TranslateService,
    platform: Platform,
    datePipe: DatePipe,
    loadingSpinnerService: LoadingSpinnerService,
    listAnalyticsHelperService: ListAnalyticsHelperService,
    sentenceCasePipe: SentenceCasePipe,
    private store: Store,
    private customerStoreService: CustomerStoreService,
    private tokenStorageService: UsfTokenStorageService,
    private sanitizer: DomSanitizer,
    messageStoreService: MessageStoreService,
    private papa: Papa,
  ) {
    super(
      http,
      toastService,
      translateService,
      platform,
      datePipe,
      loadingSpinnerService,
      listAnalyticsHelperService,
      sentenceCasePipe,
      messageStoreService,
    );
  }

  public donwloadImportTemplate = async () => {
    this.http
      .get('assets/documents/example/List_Mgmt_import_list_template.csv', {
        responseType: 'text',
      })
      .pipe(first())
      .subscribe(data =>
        this.saveDocument(data, 'List_Mgmt_import_list_template', 'CSV', true),
      );
  };

  public downloadImportErrors = async (
    importErrors: ImportErrorDetail[],
    listName: string,
  ) => {
    const errorsforPapa = importErrors.map(errorDetail => {
      return ['' + errorDetail.rowNumber, errorDetail.errorMessage];
    });
    errorsforPapa.unshift(['Row Number', 'Error Description']);
    const data = this.papa.unparse(errorsforPapa);
    this.saveDocument(data, 'ImportListErrors-' + listName, 'CSV', true);
  };

  public downloadList = async (
    listId: number,
    includePoductPrices: boolean,
    includePoductNotes: boolean,
    includeProductType: boolean,
    includeProductStatus: boolean,
    listType: DownloadRequestListType,
    fileType: DownloadRequestFileType,
    fileName: string,
    listState: string,
    listRows: ListRow[],
    listName: string,
    isDownload: boolean,
  ) => {
    try {
      this.trackDownloadPrintAnalytics(
        listId,
        listType,
        listState,
        'download:list:menu',
      );

      const isRecentPurchase = listType === 'RP';

      switch (fileType) {
        case 'CSV':
          await this.generateAndSaveCSV(
            fileName,
            listRows,
            includePoductPrices,
            includePoductNotes,
            includeProductType,
            includeProductStatus,
            isDownload,
            isRecentPurchase,
          );
          break;

        case 'XLSX':
          await this.generateAndSaveXLSX(
            fileName,
            listRows,
            includePoductPrices,
            includePoductNotes,
            includeProductType,
            includeProductStatus,
            isDownload,
            isRecentPurchase,
          );
          break;

        case 'XML':
          await this.generateAndSaveXML(
            fileName,
            listRows,
            includePoductPrices,
            includePoductNotes,
            includeProductType,
            includeProductStatus,
            isDownload,
            isRecentPurchase,
          );
          break;

        case 'PDF':
          await this.generateAndSavePDF(
            fileName,
            listName,
            listRows,
            includePoductPrices,
            includePoductNotes,
            includeProductType,
            includeProductStatus,
            isDownload,
            isRecentPurchase,
          );
          break;

        case 'Price File':
          await this.generateAndSavePRICEFile(
            fileName,
            listRows,
            includePoductPrices,
            includePoductNotes,
            includeProductType,
            includeProductStatus,
            isDownload,
          );
          break;

        case 'Price File ASCII':
          await this.generateAndSaveASCIIFile(
            fileName,
            listRows,
            includePoductPrices,
            includePoductNotes,
            includeProductType,
            includeProductStatus,
            isDownload,
          );
          break;

        default:
          this.cleanUp();
          break;
      }
    } catch (error) {
      this.cleanUp();
      this.showErrorToast(
        isDownload ? 'i18n.lists.downloadError' : 'i18n.lists.printListError',
      );
      console.error('Error creating file', error);
    }
  };

  generateAndSavePDF = async (
    fileName: string,
    listName: string,
    listRows: ListRow[],
    includePoductPrices: boolean,
    includePoductNotes: boolean,
    includeProductType: boolean,
    includeProductStatus: boolean,
    isDownload: boolean,
    isRecentPurchase: boolean,
  ) => {
    const isMobile = Capacitor.isNativePlatform();
    const columnsConfig = getPDFColumns(
      includePoductPrices,
      includePoductNotes,
      includeProductType,
      includeProductStatus,
      isMobile,
      isRecentPurchase,
    );
    const productsOnly = filterOutGroupItemTypes(listRows);
    const groupNamesInOrder = collectGroupRows(
      filterOutProductItemTypes(listRows),
    );
    const groupNamesForProducts = collectGroupNames(productsOnly);
    const seperatedColumns = this.createMultipleSeperatedColumnsForList(
      productsOnly,
      columnsConfig,
      'PDF',
    );

    const productRowsAndNeededImages = createProductRows(
      seperatedColumns,
      groupNamesForProducts,
      isMobile,
    );
    const headers = createHeadersForPDF(columnsConfig);
    // need to adjust image index based on if an optional column is not present before it

    let imageIndex = [];
    let startingIndex = 4;

    if (isRecentPurchase) startingIndex--;
    if (!includePoductNotes) startingIndex--;

    if (includeProductStatus && !isMobile) {
      imageIndex.push(startingIndex);
      startingIndex++;
    }

    if (includeProductType && !isMobile) {
      imageIndex.push(startingIndex);
      startingIndex++;
    }

    this.store
      .select(selectedCustomer)
      .pipe(first())
      .subscribe(async customer => {
        this.customerStoreService
          .loadDivisionByDivisionNumber$(customer.divisionNumber)
          .pipe(first())
          .subscribe(async division => {
            this.getToken$()
              .pipe(first())
              .subscribe(async token => {
                const departmentNumber = token.departmentNumber;
                const pdf = await pdfToBlob(
                  headers,
                  productRowsAndNeededImages.productRowsByGroup,
                  groupNamesInOrder,
                  columnsConfig,
                  fileName,
                  imageIndex,
                  customer,
                  productsOnly.length,
                  division.divisionName,
                  listName,
                  departmentNumber,
                  productRowsAndNeededImages.imageKeys,
                  isMobile,
                );
                await this.saveDocument(pdf, fileName, 'PDF', isDownload);
              });
          });
      });
  };

  /**
   *
   * @param fileName User generated name for the file
   * @param listRows Array of list rows
   * @param includePoductPrices hide price column in report
   * @returns void
   */
  generateAndSaveCSV = async (
    fileName: string,
    listRows: ListRow[],
    includePoductPrices: boolean,
    includePoductNotes: boolean,
    includeProductType: boolean,
    includeProductStatus: boolean,
    isDownload: boolean,
    isRecentPurchase: boolean,
  ) => {
    const csv = this.generateCSV(
      listRows,
      includePoductPrices,
      includePoductNotes,
      includeProductType,
      includeProductStatus,
      isDownload,
      isRecentPurchase,
    );
    await this.saveDocument(csv, fileName, 'CSV', isDownload);
  };

  private generateCSV(
    listRows: ListRow[],
    includePoductPrices: boolean,
    includePoductNotes: boolean,
    includeProductType: boolean,
    includeProductStatus: boolean,
    isDownload: boolean,
    isRecentPurchase: boolean,
  ) {
    const filteredResponse = filterOutGroupItemTypes(listRows);
    const columnsConfig = getCSVColumns(
      includePoductPrices,
      includePoductNotes,
      includeProductType,
      includeProductStatus,
      isRecentPurchase,
    );
    const seperatedColumns = this.createMultipleSeperatedColumnsForList(
      filteredResponse,
      columnsConfig,
      'CSV',
    );
    return stringifyCSV(
      seperatedColumns,
      seperatedColumns[0].columnValues.length,
      ',',
    );
  }

  /**
   *
   * @param fileName User generated name for the file
   * @param listRows Array of list rows
   * @param includePoductPrices hide price column in report
   * @returns void
   */
  generateAndSaveXLSX = async (
    fileName: string,
    listRows: ListRow[],
    includePoductPrices: boolean,
    includePoductNotes: boolean,
    includeProductType: boolean,
    includeProductStatus: boolean,
    isDownload: boolean,
    isRecentPurchase: boolean,
  ) => {
    const csv = this.generateCSV(
      listRows,
      includePoductPrices,
      includePoductNotes,
      includeProductType,
      includeProductStatus,
      isDownload,
      isRecentPurchase,
    );

    const parsedCSV = this.papa.parse(csv, { header: false });
    const excelData = parsedCSV.data;

    // Create a new XLSX workbook and worksheet
    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(excelData);

    utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const xlsxData = write(workbook, { compression: true, type: 'base64' });

    const xlsxBlob = b64toBlob(xlsxData);

    await this.saveDocument(xlsxBlob, fileName, 'XLSX', isDownload);
  };

  generateAndSaveXML = async (
    fileName: string,
    listRows: ListRow[],
    includePoductPrices: boolean,
    includePoductNotes: boolean,
    includeProductType: boolean,
    includeProductStatus: boolean,
    isDownload: boolean,
    isRecentPurchase: boolean,
  ) => {
    const filteredResponse = filterOutGroupItemTypes(listRows);
    const columnsConfig = getXMLColumns(
      includePoductPrices,
      includePoductNotes,
      includeProductType,
      includeProductStatus,
      isRecentPurchase,
    );
    const seperatedColumns = this.createMultipleSeperatedColumnsForList(
      filteredResponse,
      columnsConfig,
      'XML',
    );
    const xml = stringifyXML(seperatedColumns);
    await this.saveDocument(xml, fileName, 'XML', isDownload);
  };

  generateAndSavePRICEFile = async (
    fileName: string,
    listRows: ListRow[],
    includePoductPrices: boolean,
    includePoductNotes: boolean,
    includeProductType: boolean,
    includeProductStatus: boolean,
    isDownload: boolean,
  ) => {
    const filteredResponse = filterOutGroupItemTypes(listRows);
    const columnsConfig = getPRICEColumns(
      includePoductPrices,
      includePoductNotes,
      includeProductType,
      includeProductStatus,
    );
    const seperatedColumns = this.createMultipleSeperatedColumnsForList(
      filteredResponse,
      columnsConfig,
      'Price File',
    );
    const csv = stringifyPRICE(
      seperatedColumns,
      seperatedColumns[0].columnValues.length,
      ',',
      includePoductPrices,
    );
    await this.saveDocument(csv, fileName, 'Price File', isDownload);
  };

  generateAndSaveASCIIFile = async (
    fileName: string,
    listRows: ListRow[],
    includePoductPrices: boolean,
    includePoductNotes: boolean,
    includeProductType: boolean,
    includeProductStatus: boolean,
    isDownload: boolean,
  ) => {
    const filteredResponse = filterOutGroupItemTypes(listRows);
    const columnsConfig = getASCIIColumns(
      includePoductPrices,
      includePoductNotes,
      includeProductType,
      includeProductStatus,
    );
    const seperatedColumns = this.createMultipleSeperatedColumnsForList(
      filteredResponse,
      columnsConfig,
      'Price File ASCII',
    );
    const ascii = stringifyASCII(
      seperatedColumns,
      seperatedColumns[0].columnValues.length,
      ',',
      includePoductPrices,
    );
    await this.saveDocument(ascii, fileName, 'Price File ASCII', isDownload);
  };

  /**
   *
   * @param listRows an array of list item rows.
   * @returns SeperatedColumn[]
   */
  createMultipleSeperatedColumnsForList(
    products: ProductRow[],
    columnConfig: desiredColumn[],
    fileType: DownloadRequestFileType,
  ): SeperatedColumn[] {
    let columnMap = new Map<string, SeperatedColumn>();

    products.forEach((productRow: any) => {
      columnConfig.forEach(column => {
        columnMap = documentFunctionMap[column.columnType](
          column.columnName,
          columnMap,
          productRow,
          fileType,
        );
      });
    });

    let seperatedColumns: SeperatedColumn[] = [];
    columnMap.forEach(column => {
      seperatedColumns.push(column);
    });

    return seperatedColumns;
  }

  getToken$ = () => {
    return this.tokenStorageService.getContext();
  };
}
