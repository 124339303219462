import { Component, OnInit } from '@angular/core';
import { ImportListModalBaseComponent } from '../import-list-modal-base.component';
import { IonNav, ModalController } from '@ionic/angular';
import { ListsService } from '@app/lists/services/lists.service';
import { IMPORT_LIST_OPTION } from '@app/lists/model/import-list-enum';

@Component({
  selector: 'app-import-list-modal-desktop-tablet',
  templateUrl: './import-list-modal-desktop-tablet.component.html',
  styleUrls: ['./import-list-modal-desktop-tablet.component.scss'],
})
export class ImportListModalDesktopTabletComponent extends ImportListModalBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    listsService: ListsService,
  ) {
    super(nav, modalController, listsService);
    this.selectedOption = IMPORT_LIST_OPTION.listFile;
  }
}
