<ion-header
  class="ion-no-border"
  [class.ion-header-modal-desktop]="
    platformService?.platformType === platformService?.platformEnum?.desktop
  "
  [class.ion-header-modal-tablet]="
    platformService?.platformType === platformService?.platformEnum?.tablet
  "
  [class.ion-header-modal-mobile]="
    platformService?.platformType === platformService?.platformEnum?.mobile
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="
        platformService?.platformType !== platformService?.platformEnum?.mobile
      "
      slot="end"
    >
      <ion-button data-cy="invite-new-user-close-button" (click)="dismiss()">
        <ion-icon
          data-cy="invite-new-user-close-button-icon"
          slot="icon-only"
          name="close-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label
      data-cy="invite-new-user-actions-label"
      *ngIf="(inviteUserFlag$ | async) === false"
    >
      {{ 'i18n.profilePage.inviteNewUser.title' | translate }}
      {{ 'i18n.profilePage.inviteNewUser.moxe' | translate }}
    </ion-label>
    <ion-label
      data-cy="invite-new-user-actions-label"
      *ngIf="inviteUserFlag$ | async"
    >
      {{ 'i18n.profilePage.inviteUser.title' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  [class.ion-content-modal-desktop]="
    platformService?.platformType === platformService?.platformEnum?.desktop
  "
  [class.ion-content-modal-tablet]="
    platformService?.platformType === platformService?.platformEnum?.tablet
  "
  [class.ion-content-modal-mobile]="
    platformService?.platformType === platformService?.platformEnum?.mobile
  "
>
  <div *ngIf="(inviteUserFlag$ | async) === false">
    <div class="description-container">
      <ion-label data-cy="invite-new-user-actions-label">
        {{ 'i18n.profilePage.inviteNewUser.modalDescription' | translate }}
      </ion-label>
      <ion-label data-cy="invite-new-user-actions-label">
        {{ 'i18n.profilePage.inviteNewUser.modalNewUsersEmail' | translate }}
      </ion-label>
    </div>
    <form [formGroup]="inviteNewUserForm" (ngSubmit)="inviteNewUser()">
      <ion-item>
        <ion-input
          aria-label="invite-new-user"
          data-cy="invite-new-user-input"
          placeholder="name@example.com"
          type="email"
          maxlength="80"
          formControlName="newUserEmailAddress"
        >
        </ion-input>
      </ion-item>
    </form>
    <div class="customer-container">
      <div class="customer-information">
        <ion-label data-cy="invite-new-user-actions-label">
          {{ 'i18n.profilePage.inviteNewUser.modalCustomerName' | translate }}
        </ion-label>
        <ion-label data-cy="invite-new-user-actions-label">
          {{ this.customer?.customerName }}
        </ion-label>
      </div>
      <div class="customer-information">
        <ion-label data-cy="invite-new-user-actions-label">
          {{ 'i18n.profilePage.inviteNewUser.modalCustomerNumber' | translate }}
        </ion-label>
        <ion-label data-cy="invite-new-user-actions-label">
          {{ this.customer?.customerNumber }}
        </ion-label>
      </div>
      <div class="customer-information">
        <ion-label data-cy="invite-new-user-actions-label">
          {{ 'i18n.profilePage.inviteNewUser.modalDivisionNumber' | translate }}
        </ion-label>
        <ion-label data-cy="invite-new-user-actions-label">
          {{ this.customer?.divisionNumber }}
        </ion-label>
      </div>
    </div>
  </div>
  <div *ngIf="inviteUserFlag$ | async">
    <div class="description-container">
      <ion-label data-cy="invite-new-user-actions-label">
        {{ 'i18n.profilePage.inviteUser.subtitle' | translate }}
      </ion-label>
    </div>
    <form [formGroup]="inviteUserForm" class="invite-user-form">
      <ion-list>
        <ion-radio-group formControlName="inviteUserType">
          <ion-item>
            <ion-radio
              labelPlacement="start"
              mode="md"
              value="newUser"
              [attr.data-cy]="'radio-newUser'"
              >{{
                'i18n.profilePage.inviteUser.newUser' | translate
              }}</ion-radio
            >
          </ion-item>
          <ion-item>
            <ion-radio
              labelPlacement="start"
              mode="md"
              value="existingUser"
              [attr.data-cy]="'radio-existingUser'"
              >{{
                'i18n.profilePage.inviteUser.existingUser' | translate
              }}</ion-radio
            >
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </form>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    platformService?.platformType === platformService?.platformEnum?.desktop
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="invite-new-user-cancel-button"
        [class.usf-outline-green-mobile-modal-button]="
          platformService?.platformType ===
          platformService?.platformEnum?.mobile
        "
        [class.usf-outline-green-dsktp-tblt-modal-button]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
        "
        (click)="dismiss()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        *ngIf="(inviteUserFlag$ | async) === false"
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.inviteNewUserForm.valid
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
              platformService?.platformEnum?.mobile
            ? 'usf-fill-disabled-dsktp-tblt-modal-button'
            : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="inviteNewUser()"
      >
        {{ 'i18n.profilePage.inviteNewUser.button' | translate }}
      </ion-button>
      <ion-button
        *ngIf="inviteUserFlag$ | async"
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.inviteUserForm.valid
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
              platformService?.platformEnum?.mobile
            ? 'usf-fill-disabled-dsktp-tblt-modal-button'
            : 'usf-fill-disabled-mobile-modal-button'
        "
      >
        {{ 'i18n.common.next' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
