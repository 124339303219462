<p *ngIf="!isInternal" class="choose">
  {{ 'i18n.lists.importListModal.chooseFileExternalUserText' | translate }}
</p>
<p *ngIf="isInternal" class="choose">
  {{ 'i18n.lists.importListModal.chooseFileInternalUserText' | translate }}
</p>

<!-- Add File -->
<div
  data-cy="file-card"
  class="file-card"
  [class.error]="badData"
  *ngIf="!file && !processingData"
  (click)="onAddFile()"
>
  <div data-cy="add-file-button" class="add-file">
    <ion-icon data-cy="add-file-icon" name="reader-outline"></ion-icon>
    <ion-label class="add-file-text" data-cy="add-file-text">
      {{ 'i18n.requestCredit.uploadAFile' | translate }}
    </ion-label>
  </div>
</div>
<!-- View File -->
<div
  data-cy="file-card"
  class="file-card"
  [class.error]="badData"
  *ngIf="file && !processingData"
  (click)="onDeleteFile()"
>
  <div class="view-file">
    <div class="file-info">
      <ion-icon
        data-cy="view-file-icon"
        class="icon-file"
        name="reader"
      ></ion-icon>
      <ion-label data-cy="view-file-text">
        {{ file?.name | truncateWithEllipsis: 20 }}
      </ion-label>
    </div>
    <div class="delete-change-file">
      <a data-cy="delete-file-button">
        {{ 'i18n.common.delete' | translate }}
      </a>
    </div>
  </div>
</div>
<div data-cy="fild-spinner-card" class="file-card" *ngIf="processingData">
  <app-loading-spinner></app-loading-spinner>
</div>
<p class="bad-data" *ngIf="badData">
  {{ 'i18n.lists.importListModal.fileError' | translate }}
</p>
<p class="template" (click)="downloadTemplate()">
  <ion-icon
    name="download-outline"
    data-cy="list-csv-template-download-icon"
  ></ion-icon>
  {{ 'i18n.lists.importListModal.downloadTemplate' | translate }}
</p>
