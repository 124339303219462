import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  ModalController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '@order/services/order.service';
import {
  PageInfoService,
  PanAppState,
  PlatformService,
} from '@panamax/app-state';
import { UsfProductCardModeEnum } from '@shared/constants/usf-product-card-mode.enum';
import { DeliveryStoreService } from '@usf/ngrx-order';
import { HomeService } from 'src/app/home/services/home.service';
import { LoginService } from 'src/app/login/services';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { NavigationHelperService } from 'src/app/shared/helpers/navigation.helpers.service';
import { PopoverHelperService } from 'src/app/shared/helpers/popover.helpers';
import { ModalService } from '@shared/services/modal/modal.service';
import { CustomerSelectionTouchComponent } from '../customer-selection/customer-selection-touch/customer-selection-touch.component';
import { HeaderComponent } from '../header.component';
import { HeaderService } from '../header.service';
import { DetailHistoryService } from '../history/detail-history.service';
import { SearchModalComponent } from '../search-modal/search-modal.component';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { CustomerService } from '@app/customer/services/customer.service';
import { UserService } from '@app/user/services';
import { ZipCodeChangeTouchComponent } from '@shared/components/header/zip-code-change/zip-code-change-touch/zip-code-change-touch.component';
import { PATHS } from '@shared/constants/paths';

@Component({
  selector: 'app-usf-header-touch',
  templateUrl: './usf-header-touch.component.html',
  styleUrls: ['./usf-header-touch.component.scss', '../header.component.scss'],
})
export class UsfHeaderTouchComponent extends HeaderComponent {
  @Input() isSuperUser: boolean;
  @Input() isGuestUser: boolean;

  private pageTitle: string;
  defaultPage: string;
  isCurrentPageCompare = this.router.url.includes(PATHS.SEARCH_COMPARE);

  constructor(
    modalController: ModalController,
    popoverController: PopoverController,
    panAppState: PanAppState,
    customerService: CustomerService,
    customerStoreService: CustomerStoreService,
    deliveryStoreService: DeliveryStoreService,
    detailHistoryService: DetailHistoryService,
    headerService: HeaderService,
    homeService: HomeService,
    loginService: LoginService,
    modalService: ModalService,
    navigationHelperService: NavigationHelperService,
    notificationsService: NotificationsService,
    orderService: OrderService,
    platformService: PlatformService,
    popoverHelperService: PopoverHelperService,
    translateService: TranslateService,
    userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
    private pageInfoService: PageInfoService,
  ) {
    super(
      modalController,
      popoverController,
      panAppState,
      customerService,
      customerStoreService,
      deliveryStoreService,
      detailHistoryService,
      headerService,
      homeService,
      loginService,
      modalService,
      navigationHelperService,
      notificationsService,
      orderService,
      platformService,
      popoverHelperService,
      translateService,
      userService,
    );
    this.findTitle();
    this.setDefaultUrl();
  }

  findTitle(): void {
    this.routerEventsSub$ = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.url !== '/') {
        if (event.url.includes('/notifications')) {
          this.pageTitle = '/notifications';
        } else {
          this.pageTitle = event.urlAfterRedirects;
        }
      }
    });
  }

  setDefaultUrl() {
    if (this.router.url.includes('/lists/detail')) {
      this.defaultPage = '/lists';
    } else if (this.router.url.includes('/products')) {
      this.defaultPage = '/search';
    } else if (this.router.url.includes('/order/submitted-order')) {
      this.defaultPage = '/my-orders-view';
    } else if (
      this.router.url.includes('/deals') ||
      this.router.url.includes('/inventory') ||
      this.router.url.includes('/profile') ||
      this.router.url.includes('/request-credit') ||
      this.router.url.includes('/manageVendors')
    ) {
      this.defaultPage = '/more';
    } else if (this.router.url.includes('/review')) {
      this.defaultPage = '/search';
    } else if (this.router.url.includes('/did-you-forget')) {
      this.defaultPage = '/order';
    } else {
      this.defaultPage = '/home';
    }
  }

  back(previousUrl: string) {
    if (
      this.pageTitle.indexOf('products') > 1 ||
      this.pageTitle.indexOf('search') > 1
    ) {
      if (this.router.url.includes('review-products')) {
        history.go(-2);
      } else {
        this.detailHistoryService.goBack();
      }
    } else if (
      this.pageTitle.indexOf('lists') > 1 &&
      this.pageTitle.indexOf('management') === -1
    ) {
      if (previousUrl.indexOf('inventory') !== -1) {
        this.pageInfoService.goBack(previousUrl);
      } else {
        this.router.navigateByUrl('/lists');
      }
    } else {
      this.pageInfoService.goBack(previousUrl);
    }
  }

  async openSearchModal() {
    this.closeExistingToast();
    const currentMode = this.route.snapshot.queryParams['mode'];
    // Carry mode through mobile search for add to inventory
    const params =
      currentMode === UsfProductCardModeEnum.addToInventorySearch
        ? { mode: currentMode }
        : undefined;
    this.modalService.setModalOptions(true, SearchModalComponent, params);
    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );

    await modal.present();
  }

  openCustomerSelection() {
    this.closeExistingToast();
    if (!this.isOnline) {
      this.openWarningPopover();
    } else {
      this.isSuperUser
        ? this.customerService.setIsSuperUserCustomersModalOpen(true)
        : this.openCustomerSelectionModal();
    }
  }

  openZipCodeChange() {
    this.closeExistingToast();
    if (!this.isOnline) {
      this.openWarningPopover();
    } else {
      this.openZipCodeChangeModal();
    }
  }

  async openCustomerSelectionModal() {
    this.modalService.setModalOptions(true, CustomerSelectionTouchComponent, {
      searchText$: this.searchText$,
      vm$: this.getCustomerSelectionVM$(),
    });
    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );

    await modal.present();
    await modal.onDidDismiss().then((resp: any) => {
      if (resp?.data) {
        this.onCustomerDropdownDismiss(resp.data);
      }
    });
  }

  async openZipCodeChangeModal() {
    this.modalService.setModalOptions(true, ZipCodeChangeTouchComponent, {});
    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );

    await modal.present();
  }

  async closeExistingToast() {
    const toastExists = await this.toastController.getTop();
    if (toastExists) {
      await this.toastController.dismiss();
    }
  }
}
