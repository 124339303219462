import { createAction, props } from '@ngrx/store';
import { Customer, CustomerSummary } from '@usf/customer-types/customer';
import { CustomerSwitchStatus } from '../../constants/customer-switch-status';

export const getCustomerList = createAction('[Customers] Get Customer List');
export const getCustomerListSuccess = createAction(
  '[Customers] Get Customer List Success',
  props<{ customers: Customer[] }>(),
);
export const getCustomerListFail = createAction(
  '[Customers] Get Customer List Fail',
  props<{ error: any }>(),
);

export const CUSTOMER_SWITCH_ACTIONS = {
  getCustomerSwitchStatus: createAction(
    '[Customers] Get Customer Switch Status',
  ),
  setCustomerSwitchStatus: createAction(
    '[Customers] Set Customer Switch Status',
    props<{ customerSwitchStatus: CustomerSwitchStatus }>(),
  ),
  resetCustomerSwitchStatus: createAction(
    '[Customers] Reset Customer Switch Status',
  ),
};

export const CUSTOMER_ACTIONS = {
  searchCustomers: createAction(
    '[NgRxCustomer] Search Customers',
    props<{
      searchKey: string;
    }>(),
  ),
  searchCustomersSuccess: createAction(
    '[NgRxCustomer] Search Customers Success',
    props<{
      customerSummaries: CustomerSummary[];
    }>(),
  ),
  searchCustomersFail: createAction(
    '[NgRxCustomer] Search Customers Fail',
    props<{
      error: any;
    }>(),
  ),
};
