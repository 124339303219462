import {
  ExceptionResolution,
  OrderException,
  OrderItem,
  Tallies,
  UqeProductLimits,
} from '@usf/ngrx-order';
import { OrderItemState } from '@usf/ngrx-order';
import { ProductState } from '@usf/ngrx-product';
import { ProductPricing } from '@usf/price-types';
import { TagProduct } from 'src/app/shared/components/usf-product-tag-list/models/tag-product.model';
import { OrderHeader } from '@usf/ngrx-order';
import { ProductInventory } from '@usf/product-types';
import { ProductAlternative } from 'src/app/product-detail/models/product-alternative';
import { ApiUpdatable } from 'src/app/shared/models/api-updatable';
import { ProductAlertMessage } from 'src/app/product-detail/models/product-alert-message';
import { Product } from 'src/app/shared/models/product.model';
import { DyfProductInfo } from './dyf-view-model';
import { UsfProductCardViewModel } from '../../shared/models/usf-product-card.model';
import { InspiredPicksViewModel } from './inspired-picks-view-model';
import { UqeProductNumber } from '@usf/ngrx-order/lib/models/client/uqe-product';
import { MerchZoneViewModel } from './merch-zone-view-model';
import { FrequentlyBoughtTogetherViewModel } from './frequently-bought-together-view-model';

// eslint-disable-next-line no-shadow
export enum ShipmentTypeEnum {
  routed = 'routed',
  vendor = 'vendor',
}

/**
 * Viewmodel for the Order feature (page and popups)
 */
export interface OrderViewModel {
  orderHeader: OrderHeader; // Normal OrderHeader Obj
  orderItemState: OrderItemState;
  shipments: Shipment[]; // Grouping of products by shipment
  orderTallies: Tallies; // Order Pricing, eaches, cases, items and shipments totals
  alternatives: Map<number, AlternativeCartItem>;
  usfOrderDeliveryDate: string | Date; // use confirmed delivery date when orderStatus is SUBMITTED || SUBMITTED_WITH_EXCEPTIONS
  sellerPrice: {};
  tmUser: boolean;
  online: boolean;
  allProductIds: number[];
  productsMap: Map<number, Product>;
  uqeProductNumbers?: UqeProductNumber[];
  uqeProductLimits?: Map<number, UqeProductLimits>;
  uqeCards?: UsfProductCardViewModel[];
  dyfProducts?: DyfProductInfo[]; //only includes dyf products displayed on banner (max of 4, w/ image)
  totalDyfProducts?: number;
  restrictToOG: boolean;
  inspiredPickProducts?: InspiredPicksViewModel;
  merchZoneProducts?: MerchZoneViewModel;
  frequentlyBoughtTogetherProducts?: FrequentlyBoughtTogetherViewModel;
  hasOrder?: boolean;
  submitButtonText: string;
  partnerAutotransfer: boolean;
  disableSubmit?: boolean;
  additionalDeliveryInstructions?: string;
}

export interface AlternativeCartItem {
  productAlternative: ProductAlternative;
  unitsOrdered: ApiUpdatable<number>;
  eachesOrdered: ApiUpdatable<number>;
  unusualQuantityLimit?: number;
  unitPriceOverride: number;
  parentProductNumber: number;
  specialVendor?: boolean;
  vendorLeadTime?: number;
}

export interface Shipment {
  productCardVms: UsfProductCardViewModel[];
  shipmentType: ShipmentTypeEnum;
  deliveryDate: Date; // Calculated by Adding the OrderItem.vendorLeadTime to new Date();
  cartItems: CartItem[]; // Order Item + ProductSummary + Pricing array
  shipmentTallies: Tallies; // All products in the shipment's totals (pricing, cases, eaches, items)
  vendorNumber?: number;
  containsItemBeingFiltered: boolean; // Boolean to see whether any items are matching search query
}

export interface CartItem {
  orderItem: OrderItem;
  productState: ProductState;
  productInventory: ProductInventory;
  pricing: ProductPricing;
  unusualQuantityLimit?: number;
  altProductNumber?: number;
  warningMsg?: ProductAlertMessage;
  isAvailable?: boolean;
  thumbnailImageURL?: string;
  xlargeImageURL?: string;
  displayUpdatePricingLink?: boolean;
  matchesSearchFilter?: boolean;
}

export interface HandPricingDetails {
  productNumber: number;
  brand: string;
  breakable: boolean;
  description: string;
  packSize: string;
  eachPrice: number;
  eachUom: string;
  currentPrice: number;
  priceUom: string;
  thumbnailImageURL: string;
  xlargeImageURL: string;
  newUnitPrice?: number;
  orderException?: OrderException;
  orderItem?: OrderItem;
  currentPriceOverride?: number;
  maximum?: number;
  minimum?: number;
  target?: number;
  additionalPricingInfo?: HandPricingAdditionalDetails;
}

export interface HandPricingProductDetails {
  currentDetails: HandPricingDetails;
  altProductDetails?: HandPricingDetails;
  exceptionResolution?: ExceptionResolution;
}

export interface HandPricingAdditionalDetails {
  ascInd: string;
  unitPrice: number;
  eachPrice: number;
  commissionAmount: number;
  unitAscRate: number;
  eachAscRate: number;
  grossProfitPercent: number;
  grossProfitDollars: number;
}
