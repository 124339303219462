import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';

@Component({
  selector: 'app-simple-action-modal',
  templateUrl: './simple-action-modal.component.html',
  styleUrls: ['./simple-action-modal.component.scss'],
})
export class SimpleActionModalComponent {
  @Input() withIcon?: boolean = true;
  @Input() title: string;
  @Input() content: string;
  @Input() leftButtonText = 'i18n.common.no';
  @Input() rightButtonText = 'i18n.common.yes';
  @Input() leftButtonClass?: string = '';
  @Input() rightButtonClass?: string = '';
  @Input() note?: string;
  @Input() exitButtonForTablet? = false;
  @Input() exitButtonForMobile? = false;
  @Input() subtitle?: string;
  @Input() subtitleClass?: string = '';
  @Input() titleClass?: string = '';
  @Input() contentClass?: string = '';
  @Input() iconClass?: string = 'alert-icon-border';

  constructor(
    readonly platformService: PlatformService,
    private modalController: ModalController,
  ) {}

  async dismissModal(confirmation: boolean) {
    await this.modalController.dismiss({ dispatchAction: confirmation });
  }
}
