import {
  getCustomers,
  selectAppStateSelectedCustomer,
} from '@app/ngrx-customer/store';
import { createSelector } from '@ngrx/store';
import { AddToOrderViewModel } from '@order/models/add-to-order-view-model';
import {
  SelectedCustomerState,
  selectAllFeaturesEnabled,
} from '@panamax/app-state';
import { OrderState, OrderStatus, orderSelectors } from '@usf/ngrx-order';
import { OrderStatusDetails } from '@shared/constants/order-status-title.enum';
import { FEATURES } from '@shared/constants/splitio-features';
import { Customer } from '@usf/customer-types';
import { OrderHeaderService } from '@order/services/order-header.service';

export const selectAddToOrderModel = createSelector(
  selectAllFeaturesEnabled([FEATURES.split_global_create_new_order_option]),
  selectAppStateSelectedCustomer,
  orderSelectors.selectOrderContextState,
  getCustomers,
  (
    addToOrderFlagEnabled: boolean,
    currentCustomer: SelectedCustomerState,
    orderState: OrderState,
    customers: Customer[],
  ) => {
    if (!addToOrderFlagEnabled) {
      return;
    }
    const customerDictionary = {};

    let addToOrderVM: AddToOrderViewModel[] = [];
    customers.map(c => {
      customerDictionary[c.customerNumber] = c;
    });

    let modifiableOrders = (orderState?.ids as Array<string | number>)
      .filter(
        id =>
          orderState?.entities[id]?.orderHeader?.customerNumber ===
            currentCustomer?.customerNumber &&
          (orderState?.entities[id]?.orderHeader?.orderStatus ===
            OrderStatus.IN_PROGRESS ||
            (!!orderState?.entities[id]?.orderHeader?.tandemOrderNumber &&
              orderState?.entities[id]?.orderHeader?.orderModifiable &&
              orderState?.entities[id]?.orderHeader?.orderType === 'RT' &&
              orderState?.entities[id]?.orderHeader?.orderStatus !==
                OrderStatus.CANCELLED &&
              orderState?.entities[id]?.orderHeader?.orderStatus !==
                OrderStatus.DELETED &&
              orderState?.entities[id]?.orderHeader?.orderStatus !==
                OrderStatus.TANDEM_DELETED &&
              orderState?.entities[id]?.orderHeader?.orderStatus !==
                OrderStatus.CANCELLING)),
      )
      .map((id: string | number) => {
        let wmtDeliveryDate;
        const order = orderState?.entities[id];
        const selectedOrderCustomer =
          customerDictionary[order?.orderHeader.customerNumber];
        const orderStatusDetails =
          OrderStatusDetails[order?.orderHeader?.orderStatus];

        wmtDeliveryDate = OrderHeaderService.getDeliveryDateRange(
          order.orderHeader.deliveredDtm,
          order.orderHeader.deliveryEta,
          order.orderHeader.orderDelivered,
          order.orderHeader.etaRangeMinimum,
          order.orderHeader.etaRangeMaximum,
        );
        if (
          (wmtDeliveryDate?.deliveryDate instanceof Date &&
            isNaN(wmtDeliveryDate?.deliveryDate?.getTime())) ||
          (typeof wmtDeliveryDate.deliveryDate === 'string' &&
            isNaN(Date.parse(wmtDeliveryDate.deliveryDate)))
        ) {
          wmtDeliveryDate = undefined;
        }
        const hasETADetails =
          !order?.orderHeader?.orderDelivered &&
          (!!wmtDeliveryDate ||
            (!!order.orderHeader?.etaRangeMaximum &&
              !!order.orderHeader?.etaRangeMinimum));

        let OrderListRow: AddToOrderViewModel = {
          orderHeader: order.orderHeader,
          orderItems: order.orderItems,
          orderStatusDetails: orderStatusDetails,
          selectedOrderCustomer: selectedOrderCustomer,
          deliveryDate: !!order.orderHeader.confirmedDeliveryDate
            ? order.orderHeader.confirmedDeliveryDate
            : 'N/A',
          hasETADetails: hasETADetails,
          wmtDeliveryDate: wmtDeliveryDate,
        };
        addToOrderVM.push(OrderListRow);
      });

    addToOrderVM.sort(addToOrderCustomSortFn);
    return addToOrderVM;
  },
);

export const addToOrderCustomSortFn = (
  a: AddToOrderViewModel,
  b: AddToOrderViewModel,
) => {
  // Sort by order status
  if (
    a.orderHeader.orderStatus === OrderStatus.IN_PROGRESS &&
    b.orderHeader.orderStatus !== OrderStatus.IN_PROGRESS
  ) {
    return -1; // Place IP orders after other statuses
  }
  if (
    a.orderHeader.orderStatus !== OrderStatus.IN_PROGRESS &&
    b.orderHeader.orderStatus === OrderStatus.IN_PROGRESS
  ) {
    return 1; // Place other statuses before IP orders
  }

  // Sort IP orders by conform delivery date in descending order
  if (a.orderHeader.orderStatus === OrderStatus.IN_PROGRESS) {
    // Sort by date in descending order
    if (
      a.orderHeader.confirmedDeliveryDate &&
      b.orderHeader.confirmedDeliveryDate
    ) {
      return (
        new Date(b.orderHeader.confirmedDeliveryDate).getTime() -
        new Date(a.orderHeader.confirmedDeliveryDate).getTime()
      );
    }
    if (
      !a.orderHeader.confirmedDeliveryDate &&
      b.orderHeader.confirmedDeliveryDate
    ) {
      return 1; // Place orders with undefined/null dates at the end of group
    }
    if (
      a.orderHeader.confirmedDeliveryDate &&
      !b.orderHeader.confirmedDeliveryDate
    ) {
      return -1; // Place orders with valid dates before orders with undefined/null dates
    }
  }

  // Sort statuses other than IN_PROGRESS by conform delivery date in descending order
  if (
    a.orderHeader.tandemOrderNumber &&
    b.orderHeader.tandemOrderNumber &&
    a.orderHeader.tandemOrderNumber > 0 &&
    b.orderHeader.tandemOrderNumber > 0
  ) {
    if (
      a.orderHeader.confirmedDeliveryDate &&
      b.orderHeader.confirmedDeliveryDate
    ) {
      return (
        new Date(b.orderHeader.confirmedDeliveryDate).getTime() -
        new Date(a.orderHeader.confirmedDeliveryDate).getTime()
      );
    }
  }
  return 0; // Maintain original order if all criteria are equal
};
