import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ZipCodeChangeBaseComponent } from '../zip-code-change-base.component';

@Component({
  selector: 'app-zip-code-change-desktop',
  templateUrl: './zip-code-change-desktop.component.html',
  styleUrls: ['./zip-code-change-desktop.component.scss'],
})
export class ZipCodeChangeDesktopComponent extends ZipCodeChangeBaseComponent {
  zipCodeLength = 6;

  constructor(
    modalController: ModalController,
    platformService: PlatformService,
  ) {
    super(modalController, platformService);
  }
}
