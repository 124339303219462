import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToggleKiboSearchService {
  public static readonly KIBO_SEARCH_ENABLED = 'toggle-kibo-search';
  private isKiboEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
    const enabled =
      sessionStorage.getItem(ToggleKiboSearchService.KIBO_SEARCH_ENABLED) ===
      'true';
    this.isKiboEnabled.next(enabled);
  }

  toggle() {
    const isToggleOn = !this.isKiboEnabled.value;
    sessionStorage.setItem(
      ToggleKiboSearchService.KIBO_SEARCH_ENABLED,
      isToggleOn.toString(),
    );
    this.isKiboEnabled.next(isToggleOn);
  }

  isKiboSearchEnabled(): Observable<boolean> {
    return this.isKiboEnabled;
  }
}
