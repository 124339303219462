import { Injectable } from '@angular/core';
import {
  ActionSheetController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PanAppState, PlatformEnum, PlatformService } from '@panamax/app-state';
import { CustDivDeptInfo, List, ListItem } from '@usf/list-types';
import {
  ListGroupState,
  ListItemState,
  ListState,
  MasterListState,
} from '@usf/ngrx-list';
import { AddToListDesktopTabletComponent } from '../../shared/components/add-to-list/add-to-list-desktop-tablet/add-to-list-desktop-tablet.component';
import { AddToListMobileComponent } from '../../shared/components/add-to-list/add-to-list-mobile/add-to-list-mobile.component';
import { DeleteGroupModalComponent } from '../../shared/components/delete-group-modal/delete-group-modal.component';
import { ModalWithNavComponent } from '../../shared/components/modal-with-nav/modal-with-nav.component';
import { EllipsisOptionsPopoverComponent } from '../../shared/components/popovers/ellipsis-options/ellipsis-options-popover.component';
import { ListTypes } from '../../shared/constants/lists-constants';
import { ModalService } from '../../shared/services/modal/modal.service';
import { ListsDataForHomePage, ListsPageData } from '../model/lists-page.model';
import { CreateEditNonUsfVendorComponent } from '../../inventory/pages/manage-vendors/components/create-edit-non-usf-vendor/create-edit-non-usf-vendor.component';
import { VendorsMobileSortComponent } from '../../inventory/pages/manage-vendors/components/vendors-mobile-sort/vendors-mobile-sort.component';
import { ManageVendorsModel } from '../../inventory/pages/manage-vendors/model/manage-vendors.model';
import { ButtonsEventsEnum } from '@shared/constants/product-compare.enum';
import { ReplaceFunc } from '@shared/models/usf-product-card.model';
import { ListNoteModalComponent } from '../components/list-note-modal/list-note-modal.component';
import { CreateGroupComponent } from '../shared/create-group/create-group.component';
import { CreateListDesktopComponent } from '../shared/create-list/create-list-desktop/create-list-desktop.component';
import { CreateListTouchComponent } from '../shared/create-list/create-list-touch/create-list-touch.component';
import { DeleteListComponent } from '../shared/delete-list/delete-list.component';
import { EditNameComponent } from '../shared/edit-list-group-name/edit-name.component';
import { SelectedItem } from '../shared/list-detail-management/model/selected-product.model';
import { PrintListComponent } from '../shared/print-list/print-list.component';
import { ReplaceProductComponent } from '../shared/replace-product/replace-product.component';
import { SelectedProductsDesktopTabletComponent } from '../shared/selected-products/selected-products-desktop-tablet/selected-products-desktop-tablet.component';
import { SelectedProductsMobileComponent } from '../shared/selected-products/selected-products-mobile/selected-products-mobile.component';
import { SortFilterListDesktopTabletComponent } from '../shared/sort-filter-list/sort-filter-list-desktop-tablet/sort-filter-list-desktop-tablet.component';
import { SortFilterListMobileComponent } from '../shared/sort-filter-list/sort-filter-list-mobile/sort-filter-list-mobile.component';
import { ListsService } from './lists.service';
import { FEATURES } from '../../shared/constants/splitio-features';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { DownloadListModalOptions } from '../model/download-list-options.model';
import { MobileModalHeights } from '@shared/constants/modal-heights.enum';
import { Product } from '../../shared/models/product.model';
import { MessageStoreService } from '../../ngrx-message/services/message/message-store.service';
import { MessageTypeEnum } from '../../ngrx-message/constants/messageTypeEnum';
import { Message } from '../../ngrx-message/models/client/message';
import { CopyListModalDesktopComponent } from '../components/copy-list-modal/copy-list-desktop-modal/copy-list-modal-desktop-component';
import { CopyListModalTouchComponent } from '../components/copy-list-modal/copy-list-touch-modal/copy-list-modal-touch-component';
import { ImportListModalDesktopTabletComponent } from '../components/import-list-modal/import-list-modal-desktop-tablet/import-list-modal-desktop-tablet.component';
import { ImportListModalMobileComponent } from '../components/import-list-modal/import-list-modal-mobile/import-list-modal-mobile.component';
import { CustomerStoreService } from '../../ngrx-customer/services';
import { DivisionSelection } from '../model/copy-list-options.model';
import { Customer } from '@usf/customer-types';
import { UserService } from '@app/user/services';
import { MassDeleteDualPaneContainerComponent } from '../shared/delete-list/mass-delete-dual-pane-container/mass-delete-dual-pane-container.component';
import { CustomerDivisionDepartment } from '@app/ngrx-customer/models/customer-division-department.model';
import { Router } from '@angular/router';
import { UsfProductCardModeEnum } from '../../shared/constants/usf-product-card-mode.enum';
import { DeleteListSummaryModalComponent } from '../shared/delete-list-summary-modal/delete-list-summary-modal.component';
import { ImportListModalContainerComponent } from '../components/import-list-modal-container/import-list-modal-container.component';
import { ToggleKiboSearchService } from '@shared/services/toggle-kibo-search/toggle-kibo-search.service';
import { PATHS } from '../../shared/constants/paths';

@Injectable({
  providedIn: 'root',
})
export class ListsOverlayService {
  listDownloadModalOptions$: BehaviorSubject<DownloadListModalOptions> = new BehaviorSubject(
    {
      isOpen: false,
      listName: '',
      listId: undefined,
      listTypeId: undefined,
      isDownload: true,
    },
  );
  listImportErrorsModal$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private modalService: ModalService,
    readonly platformService: PlatformService,
    private translateService: TranslateService,
    private listsService: ListsService,
    readonly panAppState: PanAppState,
    private messageStoreService: MessageStoreService,
    private customerStoreService: CustomerStoreService,
    private userService: UserService,
    private router: Router,
    private readonly toggleKiboSearchService: ToggleKiboSearchService,
  ) {}

  // LISTS
  async openCreateListModal(listData: ListsPageData | ListsDataForHomePage) {
    let modal: HTMLIonModalElement;

    if (!this.platformService.isTouch.value) {
      this.modalService.setModalOptions(
        false,
        ModalWithNavComponent,
        { rootPage: CreateListDesktopComponent, rootPageParams: { listData } },
        'modal-desktop-size-lg',
      );
      modal = await this.modalCtrl.create(this.modalService.modalOptions);
    } else {
      this.modalService.setModalOptions(
        true,
        ModalWithNavComponent,
        {
          rootPage: CreateListTouchComponent,
          rootPageParams: { listData, selectedCreateListOption: '' },
        },
        null,
      );
      modal = await this.modalCtrl.create(this.modalService.modalOptions);
    }
    return await modal.present();
  }

  async openImportListModal() {
    let modal: HTMLIonModalElement;
    let listData;
    this.listsService
      .listsView$()
      .pipe(take(1))
      .subscribe(data => (listData = data));
    if (this.platformService.platformType !== PlatformEnum.mobile) {
      this.modalService.setModalOptions(
        this.platformService.isTouch.value,
        ModalWithNavComponent,
        {
          rootPage: ImportListModalDesktopTabletComponent,
          rootPageParams: {
            platform: this.platformService.platformType,
            listData,
          },
        },
        'modal-desktop-size-xl',
      );
      modal = await this.modalCtrl.create(this.modalService.modalOptions);
    } else {
      this.modalService.setModalOptions(
        true,
        ModalWithNavComponent,
        {
          rootPage: ImportListModalMobileComponent,
          rootPageParams: {
            platform: this.platformService.platformType,
            listData,
          },
        },
        null,
      );
      modal = await this.modalCtrl.create(this.modalService.modalOptions);
    }
    return await modal.present();
  }

  async openAddToListModal(
    listData: ListsPageData,
    selectedProducts: Product[],
    addMethod?: string,
    merchFeatureName?: string,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage:
          this.platformService.platformType ===
          this.platformService.platformEnum.mobile
            ? AddToListMobileComponent
            : AddToListDesktopTabletComponent,
        rootPageParams: {
          listPageData: listData,
          selectedProducts,
          addMethod,
          merchFeatureName,
        },
      },
      'modal-desktop-size-xl',
    );

    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    await modal.present();
    return modal;
  }
  async openAddToListModalForMultipleProducts(
    listData: ListsPageData,
    selectedProducts: Product[],
    showAlternativeToast: boolean,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage:
          this.platformService.platformType ===
          this.platformService.platformEnum.mobile
            ? AddToListMobileComponent
            : AddToListDesktopTabletComponent,
        rootPageParams: {
          listPageData: listData,
          selectedProducts,
          addMultipleEntryPoint: true,
          showAlternativeToast,
        },
      },
      'modal-desktop-size-xl',
    );

    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    await modal.present();
    return modal;
  }

  // LISTS, LIST DETAIL, RECENTLY PURCHASED
  async openListOptions(
    event: Event,
    shoppingListPath: string[],
    list: ListState,
    listName: string,
    listTypeId: string,
    goBack?: boolean,
    downloadPrintFlag?: boolean,
    isDetailManagement?: boolean,
    massDeleteModalRef?: MassDeleteDualPaneContainerComponent,
  ) {
    event.stopPropagation();
    const massDeleteFlag = await this.panAppState.featureEnabled(
      FEATURES.split_global_mass_delete_list,
    );
    const showMassDelete = await firstValueFrom(
      this.userService.showMassDelete$(),
    );
    const isSuperUser = await firstValueFrom(this.userService.isSuperUser$());
    const buttons: any[] = [
      // {
      //   text: this.translateService.instant('i18n.lists.print'),
      //   handler: () => {},
      // },
      // {
      //   text: this.translateService.instant('i18n.common.share'),
      //   handler: () => {},
      // },
    ];
    if (
      downloadPrintFlag &&
      (!isDetailManagement ||
        (isDetailManagement &&
          this.platformService.platformType === PlatformEnum.mobile)) &&
      list?.listItemCount > 0
    ) {
      listName =
        listTypeId === 'OG' ? `Order Guide #${list.listKey.listId}` : listName;
      buttons.push(
        {
          text: this.translateService.instant('i18n.common.download'),
          handler: () => {
            this.openDownloadListModal(
              list.listKey.listId,
              listTypeId,
              listName,
            );
            this.dismissListOptions();
          },
        },
        {
          text: this.translateService.instant('i18n.lists.print'),
          handler: () => {
            this.openPrintListModal(
              list.listKey.listId,
              listTypeId,
              listName,
              list.customerNumber,
              list?.listState,
            );
            this.dismissListOptions();
          },
        },
      );
    }
    const copyFlag = await this.panAppState.featureEnabled(
      FEATURES.split_global_copy_list,
    );
    const listsPresent = list.listItemCount > 0;
    if (copyFlag && listsPresent) {
      buttons.push({
        text: this.translateService.instant('i18n.lists.copy'),
        handler: () => {
          this.openCopyListModal(list);
          this.dismissListOptions();
        },
      });
    }
    if (
      !(
        listName.toLowerCase() === ListTypes.recentlyPurchased.toLowerCase() &&
        (listTypeId === undefined || listTypeId === ListTypes.recentlyPurchased)
      ) &&
      listTypeId !== ListTypes.og &&
      listTypeId !== ListTypes.masterList
    ) {
      buttons.unshift({
        text: this.translateService.instant('i18n.lists.edit'),
        handler: () => {
          this.listsService.goToShoppingList(shoppingListPath);
          this.dismissListOptions();
        },
      });
      buttons.push({
        text: this.translateService.instant('i18n.common.delete'),
        handler: () => {
          if (
            massDeleteFlag &&
            !isSuperUser &&
            massDeleteModalRef &&
            showMassDelete
          ) {
            this.openMassDeleteModal(list, massDeleteModalRef);
            this.dismissListOptions();
          } else {
            this.openDeleteListModal(list, goBack);
            this.dismissListOptions();
          }
        },
      });
    }

    if (!this.platformService.isTouch.value) {
      const listOptionsPopover = await this.popoverCtrl.create({
        component: EllipsisOptionsPopoverComponent,
        event,
        componentProps: {
          buttons,
        },
        cssClass: 'ellipsis-options-popover-desktop',
        mode: 'ios',
        showBackdrop: false,
      });
      if (buttons.length > 0) {
        await listOptionsPopover.present();
      }
    } else {
      buttons.push({
        text: this.translateService.instant('i18n.common.cancel'),
        role: 'cancel',
        cssClass: 'cancel-button',
      });
      const listOptionsActionSheet = await this.actionSheetCtrl.create({
        cssClass: 'action-sheet-touch',
        mode: 'ios',
        buttons,
      });
      if (buttons.length > 0) {
        await listOptionsActionSheet.present();
      }
    }
  }

  async openDownloadListModal(
    listId: number,
    listTypeId: string,
    listName: string,
  ) {
    const modalOptions: DownloadListModalOptions = {
      isOpen: true,
      listName,
      listId,
      listTypeId,
      isDownload: true,
    };
    this.listDownloadModalOptions$.next(modalOptions);
  }

  closeDownloadListModal() {
    this.listDownloadModalOptions$.next({
      ...this.listDownloadModalOptions$.value,
      isOpen: false,
    });
  }

  async openPrintListModal(
    listId: number,
    listTypeId: string,
    listName: string,
    customerNumber: number,
    listState?: string,
  ) {
    const useNewDownloadModal = await this.panAppState.featureEnabled(
      FEATURES.split_global_list_download,
    );
    if (useNewDownloadModal) {
      const modalOptions: DownloadListModalOptions = {
        isOpen: true,
        listName,
        listId,
        listTypeId,
        isDownload: false,
      };
      this.listDownloadModalOptions$.next(modalOptions);
    } else {
      this.modalService.setModalOptions(
        this.platformService.isTouch.value,
        PrintListComponent,
        {
          listId,
          listTypeId,
          listName,
          customerNumber,
          listState,
        },
        'print-list-modal-desktop',
      );
      const modal = await this.modalCtrl.create(this.modalService.modalOptions);
      return await modal.present();
    }
  }

  async openCopyListModal(list?: ListState | MasterListState) {
    const userState = this.panAppState.user$;
    const allDivisions$ = this.customerStoreService.loadDivisions$().pipe(
      withLatestFrom(userState),
      map(([divisions, user]) =>
        divisions
          .filter(division => division.tandemSystem !== 'CLOSED')
          .filter(division =>
            user.assignedCustomers.some(
              customer => customer.divisionNumber === division.divisionNumber,
            ),
          )
          .map(division => ({ ...division, isSelected: false })),
      ),
    );

    const customers$ = this.customerStoreService.loadCustomers$().pipe(
      map(customers =>
        customers.map(customer => {
          let deptSelection = [];
          if (customer?.departments?.length > 0) {
            deptSelection = customer.departments.map(department => ({
              ...department,
              isSelected: false,
            }));
          }
          return { ...customer, isSelected: false, departments: deptSelection };
        }),
      ),
    );

    const massCopyUser$ = this.userService.userProfiles$().pipe(
      map(userProfile => {
        const massCopyProfile = userProfile.find(
          profile => profile.key === 'MASS_SL_COPY',
        );

        if (!massCopyProfile || massCopyProfile.value.toUpperCase() === 'N') {
          return false;
        } else {
          const superUserProfile = userProfile.find(
            profile => profile.key === 'SUPER_USER',
          );
          if (
            !superUserProfile ||
            superUserProfile?.value?.toUpperCase() === 'N'
          ) {
            return true;
          }
          return false;
        }
      }),
    );

    const useCopyListModal = await this.panAppState.featureEnabled(
      FEATURES.split_global_copy_list,
    );
    if (useCopyListModal) {
      const massCopyEnabled = await this.panAppState.featureEnabled(
        FEATURES.split_global_mass_copy_list,
      );

      let listData: ListsPageData;
      let divisions: DivisionSelection[];
      let customers: Customer[];
      let showMassCopy;
      let listState;
      this.listsService
        .listsView$()
        .pipe(
          take(1),
          withLatestFrom(allDivisions$, customers$, massCopyUser$),
          tap(([listView, divisionsData, customerData, massCopyUser]) => {
            listData = listView;
            divisions = divisionsData;
            customers = customerData;
            showMassCopy = massCopyUser && massCopyEnabled;
            listState = list;
          }),
        )
        .subscribe();
      this.modalService.setModalOptions(
        this.platformService.isTouch.value,
        ModalWithNavComponent,
        {
          rootPage:
            this.platformService.platformType ===
            this.platformService.platformEnum.mobile
              ? CopyListModalTouchComponent
              : CopyListModalDesktopComponent,
          rootPageParams: {
            platformType: this.platformService.platformType,
            listData,
            divisions,
            customers,
            showMassCopy,
            listState,
          },
        },
        'modal-desktop-size-xl',
      );
      const modal = await this.modalCtrl.create(this.modalService.modalOptions);
      await modal.present();
      modal.onDidDismiss().then(modalOutput => {
        const { data } = modalOutput;
        if (data) {
          this.listsService.copyList(data);
        }
      });
    }
  }

  openCopyListModalFromListpage() {
    this.openCopyListModal();
  }

  // LIST DETAIL, LIST MANAGEMENT
  async dismissListOptions() {
    if (this.platformService.isTouch.value) {
      await this.actionSheetCtrl.dismiss();
    } else {
      await this.popoverCtrl.dismiss();
    }
  }

  async openReplaceProductModal(
    ogProductNumber: number,
    replacementProductNumber: number,
    list: ListState,
    onDidDismissFunc?: ReplaceFunc,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ReplaceProductComponent,
      {
        productNumber: ogProductNumber,
        alternativeProductNumber: replacementProductNumber,
        list: list,
      },
      'replace-product-modal-desktop',
    );

    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    modal.onDidDismiss().then((response: any) => {
      if (response?.data === ButtonsEventsEnum.continue && onDidDismissFunc) {
        onDidDismissFunc();
      }
    });
    await modal.present();
    return modal;
  }

  // LIST DETAIL
  async openSortAndFilterListModal(
    groupNames: string[],
    productClasses: string[],
    selectedSort: string,
    selectedFilters: string[],
    groupToSort: string,
    isRecentPurchase: boolean,
    isMasterList: boolean,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage:
          this.platformService.platformType ===
          this.platformService.platformEnum.mobile
            ? SortFilterListMobileComponent
            : SortFilterListDesktopTabletComponent,
        rootPageParams: {
          groupNames: groupNames,
          productClasses: productClasses,
          selectedSort: selectedSort,
          selectedFilters: selectedFilters,
          selectedGroup: groupToSort,
          isRecentPurchase,
          isMasterList,
        },
      },
      'sort-filter-list-modal-desktop',
    );

    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    await modal.present();
    return modal;
  }

  // LIST MANAGEMENT
  async openSelectedProductsModal(
    action: string,
    selectedProducts: SelectedItem[],
    list: ListState,
    groups: ListGroupState[],
    selectedGroup: ListGroupState,
    listItems: ListItemState[],
    fromListDetail = false,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage:
          this.platformService.platformType ===
          this.platformService.platformEnum.mobile
            ? SelectedProductsMobileComponent
            : SelectedProductsDesktopTabletComponent,
        rootPageParams: {
          selectedAction: action,
          selectedProducts,
          list,
          groups,
          selectedGroup,
          listItems,
          fromListDetail,
        },
      },
      'selected-products-modal-desktop',
    );

    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    await modal.present();
    return modal;
  }

  async openEditNameModal(
    name: string,
    list: ListState,
    groups: ListGroupState[],
    isGroup: boolean,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      EditNameComponent,
      {
        name,
        list,
        groups,
        isGroup,
      },
      'modal-desktop-size-sm',
    );
    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    return await modal.present();
  }

  async openDeleteListModal(list: ListState, goBack: boolean) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      DeleteListComponent,
      {
        list,
        goBack,
      },
      'modal-desktop-size-md',
    );
    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    return await modal.present();
  }

  openMassDeleteModal(
    list: ListState,
    massDeleteModal?: MassDeleteDualPaneContainerComponent,
  ) {
    this.listsService.getCustomersWithSameList(list.listName);
    massDeleteModal.massDeleteViewModel$ = this.listsService.selectMassDeleteViewModel$(
      list.listName,
      list.listKey,
    );
    massDeleteModal.isLoading$ = this.listsService.selectMassDeleteLoading$();
    massDeleteModal.openModal();
  }

  async openCreateGroupModal(
    list: ListState,
    groups: ListGroupState[],
    listId: number,
    listTypeId: string,
  ) {
    this.modalService.setModalOptions(true, CreateGroupComponent, {
      list,
      groups,
      listId,
      listTypeId,
    });
    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    await modal.present();
    return modal;
  }

  async openDeleteGroupModal(selectedGroup: ListGroupState) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      DeleteGroupModalComponent,
      {
        platformType: this.platformService.platformType,
        numberOfProducts: selectedGroup?.listItemKeys?.length || 0,
        groupName: selectedGroup?.groupName,
      },
      'modal-desktop-size-md',
    );
    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    await modal.present();
    return modal;
  }

  // MANAGE VENDORS
  async openMobileSort(vendorData: ManageVendorsModel) {
    this.modalService.setModalOptions(true, VendorsMobileSortComponent, {
      selectedSort:
        vendorData.selectedSort.columnName +
        '-' +
        vendorData.selectedSort.sortDirection,
    });
    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    return await modal.present();
  }

  async openCreateEditNonUsfVendorModal(
    mode: string,
    vendorData: ManageVendorsModel,
    selectedVendor: any,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage: CreateEditNonUsfVendorComponent,
        rootPageParams: {
          selectedState: '',
          mode,
          selectedVendor,
          externalVendorData: vendorData,
        },
      },
      'create-edit-non-usf-vendor-modal-desktop',
    );
    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    return await modal.present();
  }

  async openCreateEditNoteModal(
    isEdit: boolean,
    item: ListItem,
    list: List,
    masterListNote?: string,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage: ListNoteModalComponent,
        rootPageParams: {
          item,
          isEdit,
          list,
          masterListNote,
        },
      },
      'create-edit-list-note-modal-desktop',
      undefined,
      undefined,
      MobileModalHeights.short,
    );
    const modal = await this.modalCtrl.create(this.modalService.modalOptions);
    return await modal.present();
  }

  // Avoiding binding mmanagement service or store to list-product-card-service
  async requestThroughDeleteNote(item: ListItem, list: List) {
    this.listsService.deleteListItemNote(item, list);
  }

  showListLoadingErrorToast() {
    this.messageStoreService.upsertMessage({
      type: MessageTypeEnum.error,
      display: this.translateService.instant('i18n.lists.loadingErrorMessage'),
      toast: true,
      sticky: false,
    } as Message);
  }

  async showOptions(buttonBeforeCloseActionAdded: any[]) {
    const buttons = buttonBeforeCloseActionAdded.map(button => {
      return {
        ...button,
        handler: () => {
          button.handler(), this.dismissListOptions();
        },
      };
    });
    if (!this.platformService.isTouch.value) {
      const listOptionsPopover = await this.popoverCtrl.create({
        component: EllipsisOptionsPopoverComponent,
        event,
        componentProps: {
          buttons,
        },
        cssClass: 'ellipsis-options-popover-desktop',
        mode: 'ios',
        showBackdrop: false,
      });
      await listOptionsPopover.present();
    } else {
      buttons.push({
        text: this.translateService.instant('i18n.common.cancel'),
        role: 'cancel',
        cssClass: 'cancel-button',
      });
      const listOptionsActionSheet = await this.actionSheetCtrl.create({
        cssClass: 'action-sheet-touch',
        mode: 'ios',
        buttons,
      });
      await listOptionsActionSheet.present();
    }
  }

  getSearchPageProductEllipsisButton = () => {
    return {
      text: this.translateService.instant('i18n.lists.addToListSearch'),
      handler: () => {
        firstValueFrom(this.toggleKiboSearchService.isKiboSearchEnabled())
          .then(kiboEnabled => {
            const url = kiboEnabled ? [PATHS.NEW_SEARCH] : [PATHS.SEARCH];
            const queryParams = kiboEnabled
              ? { addToList: 'true' }
              : {
                  searchFilterProperties: '0',
                  mode: UsfProductCardModeEnum.addToListSearch,
                };

            this.router.navigate([`${url}`], { queryParams });
          })
          .catch(error => {
            console.error('Error determining Kibo search status', error);
          });
      },
    };
  };

  async openCreateListOptions(
    listData: ListsPageData | ListsDataForHomePage,
    importReference?: ImportListModalContainerComponent,
  ) {
    const buttons: any[] = [
      {
        text: this.translateService.instant('i18n.lists.newList'),
        handler: () => {
          this.openCreateListModal(listData);
          this.dismissListOptions();
        },
      },
    ];
    const copyFlag = await this.panAppState.featureEnabled(
      FEATURES.split_global_copy_list,
    );
    const listsPresent = this.checkListLength(listData);
    if (copyFlag && listsPresent) {
      buttons.push({
        text: this.translateService.instant('i18n.lists.copyList'),
        handler: () => {
          this.openCopyListModalFromListpage();
          this.dismissListOptions();
        },
      });
    }
    const importFlag = await this.panAppState.featureEnabled(
      FEATURES.split_global_import_list,
    );
    if (importFlag) {
      buttons.push({
        text: this.translateService.instant('i18n.lists.importList'),
        handler: () => {
          if (!!importReference) {
            importReference.openModal();
          } else {
            this.openImportListModal();
          }
          this.dismissListOptions();
        },
      });
    }

    if (!this.platformService.isTouch.value) {
      const listOptionsPopover = await this.popoverCtrl.create({
        component: EllipsisOptionsPopoverComponent,
        event,
        componentProps: {
          buttons,
        },
        cssClass: 'ellipsis-options-popover-desktop',
        mode: 'ios',
        showBackdrop: false,
      });
      await listOptionsPopover.present();
    } else {
      buttons.push({
        text: this.translateService.instant('i18n.common.cancel'),
        role: 'cancel',
        cssClass: 'cancel-button',
      });
      const listOptionsActionSheet = await this.actionSheetCtrl.create({
        cssClass: 'action-sheet-touch',
        mode: 'ios',
        buttons,
      });
      await listOptionsActionSheet.present();
    }
  }
  checkListLength(listData: any): boolean {
    if (listData.lists) {
      return (
        listData.lists.publicLists.length > 0 ||
        listData.lists.internalLists.length > 0 ||
        listData.lists.privateLists.length > 0 ||
        listData.lists.recentlyViewedLists.length > 0 ||
        listData.managedByUsfLists.length > 0
      );
    }

    if (listData.allLists) {
      return listData.allLists.lenght > 0;
    }
    return false;
  }
}
