import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ChipTypeEnum } from './models/chip-type.enum';
import { IconConfig } from './models/icon-config.model';
import { PlatformService } from '@panamax/app-state';
import { PopoverController, PopoverOptions } from '@ionic/angular';

@Component({
  selector: 'app-usf-chip',
  templateUrl: './usf-chip.component.html',
  styleUrls: ['./usf-chip.component.scss'],
})
export class UsfChipComponent {
  @Input() type: ChipTypeEnum;
  @Input() clickable = false;
  @Input() iconConfig: IconConfig = {
    iconWidth: 0.8125,
    iconHeight: 0.8125,
    color: '#000',
  };
  @Input() iconUrl: string;
  @Input() popoverOptions: PopoverOptions;
  @Input() textColor = '';
  @Input() backgroundColor = '';
  @Output() clickEmitter = new EventEmitter<boolean>();
  @Output() imageError = new EventEmitter<void>();

  chipType = ChipTypeEnum;
  productPillPopover: HTMLIonPopoverElement;
  hideTimeoutValue = 1000;
  hidePill = false;

  constructor(
    readonly platformService: PlatformService,
    private popoverController: PopoverController,
  ) {}

  async onProductPillClick($event: any) {
    if (!!this.popoverOptions && this.platformService.isTouch.value) {
      await this.openProductPillPopover($event);
      this.closeProductPillPopover(5000);
    } else {
      if (this.clickable) {
        this.clickEmitter.emit();
      }
    }
  }

  async openProductPillPopover(ev: any) {
    if (!!this.popoverOptions) {
      this.productPillPopover?.dismiss();

      this.productPillPopover = await this.popoverController.create({
        ...this.popoverOptions,
        event: ev,
      });

      this.productPillPopover.present();
    }
  }

  closeProductPillPopover(timeout?: number) {
    setTimeout(() => {
      this.productPillPopover?.dismiss();
      this.productPillPopover = undefined;
    }, timeout ?? this.hideTimeoutValue);
  }

  onImageLoadError() {
    this.hidePill = true;
    this.imageError.emit();
  }
}
