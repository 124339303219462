import { PlatformEnum } from '@panamax/app-state';
import { dualPaneSelectInventoryCheck } from '@shared/constants/dual-pane-constants';
import {
  DualPaneView,
  MultiSelectItems,
} from '@shared/constants/dual-pane.enum';
import {
  BasicListItem,
  DualPaneViewModel,
  InputLabelItems,
  RightPaneInfo,
  SelectableItem,
} from '@shared/models/dual-pane-model';
import { combineLatest, map, Observable } from 'rxjs';
import { File } from '@robingenz/capacitor-file-picker';
import { RadioBtnInventory } from '../../../../../inventory/models/radio-btn-inventory.model';
import { areDatesEqual } from '@shared/helpers/calendar.helpers';
import {
  currencyCleanup,
  trimSpaceForInput,
} from '@shared/helpers/input-cleanup.helpers';

// Left pane selection functions
export const dualPaneDefaultTouchSelection = (
  viewModel: DualPaneViewModel,
  item: BasicListItem,
) => {
  return {
    ...viewModel,
    navigationPageCount: viewModel.navigationPageCount + 1,
    rightView: !item.onSelectfunction
      ? {
          ...viewModel.rightView,
          view: item.view,
        }
      : item.onSelectfunction(viewModel, item.label),
    leftView: {
      ...viewModel.leftView,
      view: DualPaneView.noView,
    },
    submitIsBackButton: true,
  } as DualPaneViewModel;
};

export const multiInventorySelection = (
  vm: DualPaneViewModel,
  platform: PlatformEnum,
  pane: RightPaneInfo,
  value: any,
) => {
  let tempArray = [...(pane.value ?? [])] as any[];
  if (value?.selected) {
    tempArray.push(value);
  } else {
    const index = tempArray.findIndex(invCheck => {
      return inventoryRadioMatch(invCheck, value);
    });
    tempArray = tempArray.filter((val, i) => {
      return i !== index;
    });
  }
  pane.value = tempArray;

  let subLabel = undefined;
  if (tempArray.length === 1) {
    subLabel = tempArray[0].inventoryName;
  } else if (tempArray.length > 1) {
    subLabel = 'Selected: ' + tempArray.length;
  }

  vm.leftView.content = vm.leftView.content.map(item => {
    if (item.selected) {
      return {
        ...item,
        subLabel,
        value: tempArray,
      };
    } else {
      return item;
    }
  });
  return vm;
};

export const dualPaneDefaultDesktopTabletSelection = (
  viewModel: DualPaneViewModel,
  item: BasicListItem,
) => {
  return {
    ...viewModel,
    rightView: !item.onSelectfunction
      ? {
          ...viewModel.rightView,
          view: item.view,
        }
      : item.onSelectfunction(viewModel, item.label),
  } as DualPaneViewModel;
};

// Right pane selection functions
export const dualPaneBasicSelection = (
  vm: DualPaneViewModel,
  platform: PlatformEnum,
  pane: RightPaneInfo,
  value: any,
) => {
  vm.leftView.content = vm.leftView.content.map(item => {
    if (item.selected) {
      return { ...item, subLabel: value, value: value };
    } else {
      return item;
    }
  });
  if (vm.rightView.view !== DualPaneView.noView) {
    vm.rightView.value = value;
  }
  return vm;
};

export const radioBtnInventorySelection = (
  vm: DualPaneViewModel,
  platform: PlatformEnum,
  pane: RightPaneInfo,
  value: any,
) => {
  vm.leftView.content = vm.leftView.content.map(item => {
    if (item.selected) {
      return {
        ...item,
        subLabel: value?.inventoryName,
        value: value?.inventoryId,
      };
    } else {
      return item;
    }
  });
  if (vm.rightView.view !== DualPaneView.noView) {
    vm.rightView.value = value;
  }
  return vm;
};

export const dualPaneMultiSelection = (
  vm: DualPaneViewModel,
  platform: PlatformEnum,
  pane: RightPaneInfo,
  value: any,
  selectedCount: string,
) => {
  vm.leftView.content = vm.leftView.content.map(listItem => {
    if (listItem.selected) {
      return { ...listItem, subLabel: selectedCount, value: value };
    } else {
      return listItem;
    }
  });
  if (vm.rightView.view !== DualPaneView.noView) {
    vm.rightView.value = value;
  }
  return vm;
};

export const dualPaneBasicFileSelection = (
  vm: DualPaneViewModel,
  platform: PlatformEnum,
  pane: RightPaneInfo,
  file: File,
) => {
  vm.leftView.content = vm.leftView.content.map(item => {
    if (item.selected) {
      return { ...item, subLabel: file.name, value: file };
    } else {
      return item;
    }
  });
  return vm;
};

// Submission handlers
export const dualPaneDefaultHandleSubmit = (
  vm: DualPaneViewModel,
): Map<string, any | any[]> => {
  const mapToReturn = new Map<string, any | any[]>();
  vm.leftView.content.forEach(item => {
    mapToReturn.set(item.label, item.value);
  });
  return mapToReturn;
};

export const dualPaneDefaultVerifySubmit = (vm: DualPaneViewModel) => {
  let valid = true;
  vm.leftView.content.forEach(item => {
    if (!item.value) {
      valid = false;
    }
  });
  return valid;
};

// Save verification functions
export const dualPaneDefaultVerifySave = (vm: DualPaneViewModel) => {
  return !!vm.rightView.value;
};

export const singleSelectInventoryVerifySave = (vm: DualPaneViewModel) => {
  return (
    !!vm.rightView.value && vm.rightView.value !== dualPaneSelectInventoryCheck
  );
};

export const multiSelectInventoryVerifySave = (vm: DualPaneViewModel) => {
  return (vm.rightView.value ?? []).length > 0;
};

// Navigation functions
export const inventoryMultiSaveOnNavigation = (
  vm: DualPaneViewModel,
  item: BasicListItem,
  value: any,
) => {
  let subLabel = undefined;
  if (value.length === 1) {
    subLabel = value[0].inventoryName;
  } else if (value.length > 1) {
    subLabel = 'Selected: ' + value.length;
  }
  item.value = value;
  item.subLabel = subLabel;
  return item;
};

export const fileSaveOnNavigation = (
  vm: DualPaneViewModel,
  item: BasicListItem,
  value: File,
) => {
  item.value = value;
  item.subLabel = value.name;
  return item;
};

// Pane building helpers
export const updateRightPaneWithValue = (
  viewModel: DualPaneViewModel,
  paneSelected: string,
  rightPane: RightPaneInfo,
) => {
  if (!!paneSelected) {
    viewModel.leftView.content.forEach(element => {
      if (element.label === paneSelected) rightPane.value = element.value;
    });
  }
};

export const updateRightPaneWithValueForMaps = (
  viewModel: DualPaneViewModel,
  paneSelected: string,
  rightPane: RightPaneInfo,
) => {
  if (!!paneSelected) {
    viewModel.leftView.content.forEach(element => {
      if (element.label === paneSelected) {
        rightPane.value = element.value;
        if (!!rightPane.value) {
          rightPane.value = new Map(rightPane.value);
        }
      }
    });
  }
};

export const getSearchableObservable$ = (rightPane: RightPaneInfo) => {
  return combineLatest([
    rightPane?.searchPane.searchBehavior,
    rightPane.itemsObservable$,
  ]).pipe(
    map(([search, items]) => {
      if (!!rightPane.countPane) {
        rightPane.countPane.secondaryCount = items.filter(
          item => item.type !== MultiSelectItems.iconHeader,
        ).length;
      }
      return rightPane.searchPane.onSearchFunction(rightPane, search, items);
    }),
  );
};

// Search functions
export const multiSelectSearchDefault = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  return items.filter(item => {
    return (
      item?.label?.toLowerCase()?.includes((searchKey || '').toLowerCase()) ||
      item?.subLabel?.toLowerCase()?.includes((searchKey || '').toLowerCase())
    );
  });
};

export const singleSelectRadioSearchDefault = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  return items.filter(item => {
    return (
      item?.label?.toLowerCase()?.includes((searchKey || '').toLowerCase()) ||
      item?.subLabel?.toLowerCase()?.includes((searchKey || '').toLowerCase())
    );
  });
};

export const countableItemSearchDefault = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  return items.filter(item => {
    if (item?.title === rightPane?.value) {
      rightPane.selectedItem = item;
    }
    return (
      item?.title?.toUpperCase().includes((searchKey || '').toUpperCase()) ||
      item?.subtitle?.includes(searchKey || '')
    );
  });
};

export const radioBtnInventorySearchDefault = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  return items?.filter((item: RadioBtnInventory) => {
    if (item?.inventoryId === rightPane?.value) {
      rightPane.selectedItem = item?.inventoryId;
    }

    if (!searchKey) {
      return true;
    }

    const keyWords = [];

    (searchKey || '')
      ?.toUpperCase()
      .split('-')
      .forEach(nameSegment => keyWords.push(...nameSegment.split(' ')));

    return keyWords.every(valueCheck => {
      return !!item.searchArray.find(key => {
        return !!key.includes(valueCheck);
      });
    });
  });
};

export const productSearchDefault = (
  rightPane: RightPaneInfo,
  searchKey: string,
  items: any[],
) => {
  const productsToReturn = items.filter(product => {
    if (product?.productNumber === rightPane?.value) {
      rightPane.selectedItem = product?.productNumber;
    }
    return (
      product?.productDescription
        ?.toUpperCase()
        ?.includes((searchKey || '').toUpperCase()) ||
      product?.productNumber
        ?.toString()
        ?.toUpperCase()
        ?.includes((searchKey || '').toUpperCase())
    );
  });
  return productsToReturn;
};

// Pane helper functions
export const inventoryRadioMatch = (inv1, inv2) => {
  return (
    inv1?.inventoryName === inv2?.inventoryName &&
    inv1?.departmentName === inv2?.departmentName &&
    areDatesEqual(inv1?.inventoryDate, inv2?.inventoryDate)
  );
};

// On input interaction functions
export const onMultiInputDefault = (item: InputLabelItems, value: any) => {
  item.inputValue = !!item.inputcleanup ? item.inputcleanup(value) : value;
  return item.inputValue;
};

export const onMultiRangeDefault = (
  item: InputLabelItems,
  value: any,
  isRangeOne: boolean,
) => {
  if (isRangeOne) {
    item.rangeValueOne = !!item.inputcleanup ? item.inputcleanup(value) : value;
  } else {
    item.rangeValueTwo = !!item.inputcleanup ? item.inputcleanup(value) : value;
  }
  return { one: item.rangeValueOne, two: item.rangeValueTwo };
};

export const handleInputOrRangeValueSave = (
  vm: DualPaneViewModel,
  pane: RightPaneInfo,
  value: any,
  index: number,
) => {
  if (!pane.value) {
    pane.value = new Map<number, any>();
  }
  pane.value.set(index, value);
  vm.leftView.content = vm.leftView.content.map(item => {
    if (item.selected) {
      return { ...item, subLabel: value, value: pane.value };
    } else {
      return item;
    }
  });
};

// Input cleanup
export const trimSpaceTo30 = (value: any) => {
  return trimSpaceForInput(value.toString().substring(0, 30));
};

export const rangeCleanupZeroToHundred = (value: any) => {
  if (!value && value !== 0) {
    return value;
  }

  const scrubAlphaRegex = new RegExp('[^\\d.-]', 'g');
  let regexValue = Math.round(
    Number(value.replace(scrubAlphaRegex, '')),
  ).valueOf();
  if (regexValue > 100) {
    return 100;
  }
  if (regexValue < 1) {
    return 1;
  }
  return regexValue;
};

export const onSmallChangeCurrencyCleanp = (value: any) => {
  return currencyCleanup(value, 7);
};

export const onLargeChangeCurrencyCleanp = (value: any) => {
  return currencyCleanup(value, 10);
};
