import { Injectable } from '@angular/core';
import { Tracking } from '@panamax/app-state';
import {
  TrackingProductAdded,
  TrackingProductSubbedOut,
} from '@search/models/add-to-order-tracking.model';
import { UsfProductCardModeEnum } from '@shared/constants/usf-product-card-mode.enum';
import { ProductAnalyticsService } from '@usf/ngrx-product';
import { SearchRequest, TypeaheadTrackEvent } from '@usf/product-types';
import { EndecaSearchPagesTypes } from 'src/app/shared/constants/endeca-search-pages-types';
import { QuantityUpdate } from 'src/app/shared/models/quantity-update';
import { OrderItemService } from 'src/app/shared/services/order-item.service';
import {
  BROWSE_CATALOG,
  SEARCH_FOR_SUBS,
  SEARCH_RESULT,
} from '../shared/constants/search-config';
import { SearchResultViewModel } from '../shared/models/search-view.model';
import { ProductAlternativeTypeEnum } from '@product-detail/models/product-alternative';

@Injectable({
  providedIn: 'root',
})
export class SearchAnalyticsService {
  constructor(
    private analyticsService: ProductAnalyticsService,
    private orderItemService: OrderItemService,
  ) {}

  trackCatalogPageLoad() {
    this.analyticsService.trackSearchCatalogPageLoad();
  }

  trackScoopClick() {
    this.analyticsService.trackClickScoopBanner();
  }

  trackWhileSuppliesLastClick() {
    this.analyticsService.trackClickWhileSuppliesLastBanner();
  }

  trackBannerDealsDisplayed() {
    this.analyticsService.trackBannerDealsDisplayed();
  }

  trackBannerDealsView() {
    this.analyticsService.trackBannerDealsView();
  }

  trackBannerDealsClick() {
    this.analyticsService.trackBannerDealsClick();
  }

  trackSearchSubmit(searchRequest: SearchRequest, searchType: string) {
    this.analyticsService.trackSearchSubmit(searchRequest, searchType);
  }

  trackSearchResultClick(product: SearchResultViewModel) {
    this.analyticsService.trackSearchResultClick(
      product.productNumber,
      product.summary.divisionNumber,
      product.trackingAttributes,
      product.resultRank,
    );
  }

  trackAndChangeQuantity(
    quantityUpdate: QuantityUpdate,
    product: SearchResultViewModel,
    pageType: string,
    totalNumberOfRecords: number,
    cardMode?: UsfProductCardModeEnum,
    replacementProduct?: SearchResultViewModel,
  ) {
    const isAddingAlternativeProduct =
      !!product?.alternative?.product &&
      quantityUpdate?.productNumber ==
        product.alternative.product?.productNumber;
    const isAddingOriginalProduct =
      quantityUpdate?.productNumber === replacementProduct?.productNumber;
    const isReplacement =
      (UsfProductCardModeEnum.replacementSearch === cardMode &&
        !isAddingOriginalProduct) ||
      isAddingAlternativeProduct;

    const products = isReplacement
      ? this.fillProductsReplacingToTrack(
          replacementProduct?.productNumber === product.productNumber
            ? (product?.alternative.product as SearchResultViewModel)
            : product,
          !!replacementProduct ? replacementProduct : product,
          quantityUpdate,
          cardMode,
        )
      : this.fillProductToTrack(product, quantityUpdate, pageType);

    const trackingProductsNotSelected = this.buildTrackingProductsNotSelected(
      replacementProduct,
      quantityUpdate,
      cardMode,
    );
    const mergeTrackingData = [].concat(products, trackingProductsNotSelected);

    const trackingData: Tracking = {
      analytics: {
        data: {
          products: mergeTrackingData,
        },
      },
    };

    if (pageType == EndecaSearchPagesTypes.search || !pageType) {
      trackingData.analytics.data.results = {
        resultRank: product.resultRank,
        numberOfResults: totalNumberOfRecords,
      };
    }

    this.orderItemService.changeQuantity(
      quantityUpdate?.productNumber,
      quantityUpdate?.quantity,
      quantityUpdate?.isEaches,
      trackingData,
      cardMode === UsfProductCardModeEnum.exceptionSearch,
    );
  }

  buildTrackingProductsNotSelected(
    replacementProduct: SearchResultViewModel,
    quantityUpdate: QuantityUpdate,
    cardMode?: UsfProductCardModeEnum,
  ) {
    const productAlternative = replacementProduct?.alternative;
    const isNotAddingDisplayedSub =
      !!productAlternative?.product &&
      quantityUpdate?.productNumber !==
        productAlternative?.product?.productNumber;

    if (
      cardMode === UsfProductCardModeEnum.replacementSearch &&
      isNotAddingDisplayedSub
    ) {
      return [
        {
          divisionApn:
            productAlternative?.product?.summary?.divisionNumber +
            '-' +
            productAlternative?.product?.productNumber,
          attributes: productAlternative?.product?.trackingAttributes,
          subRejected: '1',
          substitutionParentApn:
            replacementProduct?.summary?.divisionNumber +
            '-' +
            replacementProduct?.productNumber,
          substitutePriority: !!productAlternative?.substituteInfo
            ? productAlternative?.substituteInfo?.substitutePriority
            : 1,
          substituteType: !!productAlternative?.substituteInfo
            ? productAlternative?.substituteInfo?.substituteType
            : productAlternative?.type,
        },
      ];
    } else {
      return [];
    }
  }
  trackTypeAheadOptions(typeAheadTrackingData: TypeaheadTrackEvent) {
    this.analyticsService.trackTypeAheadSearch(typeAheadTrackingData);
  }

  private fillProductsReplacingToTrack(
    productToAdd: SearchResultViewModel,
    replacedProduct: SearchResultViewModel,
    quantityUpdate: QuantityUpdate,
    cardMode: UsfProductCardModeEnum,
  ) {
    const trackingDetail: TrackingProductAdded = this.createTrackingDetail(
      productToAdd,
      replacedProduct,
      quantityUpdate,
      cardMode,
    );

    const trackingSubbedOutDetail: TrackingProductSubbedOut = this.createTrackingSubbedOutDetail(
      replacedProduct,
      cardMode,
    );
    return [trackingDetail, trackingSubbedOutDetail];
  }

  private fillProductToTrack(
    product: SearchResultViewModel,
    quantityUpdate: QuantityUpdate,
    pageType: string,
  ) {
    let trackingDetail: {
      [key: string]: string | number | object;
    } = {
      quantityUnits: quantityUpdate?.isEaches
        ? product?.summary?.eachUom
        : product?.summary?.salesUom,

      divisionApn: `${product?.summary?.divisionNumber}-${product?.summary?.productNumber}`,
      attributes: product?.trackingAttributes,
      addMethod:
        !!pageType && pageType != EndecaSearchPagesTypes.search
          ? BROWSE_CATALOG
          : SEARCH_RESULT,
    };
    if (
      quantityUpdate?.productNumber == product?.productNumber &&
      !!product?.alternative?.product
    ) {
      trackingDetail = { ...trackingDetail, orderedOriginal: '1' };
      return [trackingDetail];
    }

    return [trackingDetail];
  }

  private createTrackingSubbedOutDetail(
    replacedProduct: SearchResultViewModel,
    cardMode?: UsfProductCardModeEnum,
  ): TrackingProductSubbedOut {
    const tracking = {
      divisionApn:
        replacedProduct?.summary?.divisionNumber +
        '-' +
        replacedProduct?.productNumber,
      attributes: replacedProduct?.trackingAttributes,
      subOut: '1',
    } as TrackingProductSubbedOut;

    // order alternative from default search
    if (
      cardMode === UsfProductCardModeEnum.defaultSearch &&
      replacedProduct?.alternative?.type ===
        ProductAlternativeTypeEnum.substitute
    ) {
      (tracking['substituteType'] = !!replacedProduct?.alternative
        ?.substituteInfo
        ? replacedProduct?.alternative?.substituteInfo?.substituteType
        : replacedProduct?.alternative?.type),
        (tracking['substitutePriority'] = replacedProduct?.resultRank);
    }
    return tracking;
  }

  private createTrackingDetail(
    productToAdd: SearchResultViewModel,
    replacedProduct: SearchResultViewModel,
    quantityUpdate: QuantityUpdate,
    cardMode: UsfProductCardModeEnum,
  ): TrackingProductAdded {
    return {
      divisionApn: `${productToAdd.summary?.divisionNumber}-${quantityUpdate.productNumber}`,
      quantityUnits: quantityUpdate.isEaches
        ? productToAdd.summary?.eachUom
        : productToAdd.summary?.salesUom,
      addMethod:
        UsfProductCardModeEnum.replacementSearch === cardMode
          ? SEARCH_FOR_SUBS
          : SEARCH_RESULT,
      attributes: productToAdd.trackingAttributes,
      subIn: '1',
      substitutionParentApn:
        replacedProduct?.summary?.divisionNumber +
        '-' +
        replacedProduct?.productNumber,
      substituteType: SEARCH_FOR_SUBS,
    };
  }
}
