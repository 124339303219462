<ion-header
  class="ion-no-border add_to_inventory_header"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="(platformService?.isTouchExperience$() | async) === false"
        id="close-modal-btn"
        data-cy="close-add-to-inventory-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon
          slot="icon-only"
          name="close-outline"
          data-cy="add-to-inventory-close-icon"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label class="label_text" data-cy="add-to-inventory-text">
      {{ 'i18n.inventory.addToInventoryTitle' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="add-to-inventory_content"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-desktop'
  "
  tabletTouchMove
>
  <ion-grid>
    <ion-row>
      <!-- ADD TO Inventory OPTIONS -->
      <ion-col size="5" class="add-to-inventory-options">
        <div class="add-to-inventory-options-header">
          <ion-label data-cy="add-to-inventory-info-label">
            {{ 'i18n.inventory.addInstructions' | translate }}
          </ion-label>
        </div>
        <ion-item-divider></ion-item-divider>
        <!-- Choose Inventory -->
        <ion-item
          [class.selected]="
            selectedAddToinventoryOption === addToInventory.chooseInventory
          "
          detail
          (click)="
            changeSelectedAddToinventoryOption(addToInventory.chooseInventory)
          "
          data-cy="click-change-selected-inventory-item"
        >
          <ion-label data-cy="choose-inventory-label">
            <!-- update -->
            {{ 'i18n.search.chooseInventory' | translate }}
            <span data-cy="selected-inventory-name-text">
              {{ selectedInventory?.inventoryName }} -
              {{ selectedInventory?.inventoryDate | date: 'M/d/yyyy' }}
            </span>
          </ion-label>
        </ion-item>
        <!-- Choose Group -->
        <ion-item
          [class.selected]="
            selectedAddToinventoryOption === addToInventory.chooseGroup
          "
          detail
          (click)="
            changeSelectedAddToinventoryOption(addToInventory.chooseGroup)
          "
          data-cy="click-change-selected-group-item"
        >
          <ion-label data-cy="choose-group-label">
            {{ 'i18n.lists.chooseGroup' | translate }}
            <span data-cy="choose-group-text">
              {{ group?.groupName ? group?.groupName : '' }}
            </span>
          </ion-label>
        </ion-item>
        <!-- Choose Position -->
        <ion-item
          [class.selected]="
            selectedAddToinventoryOption === addToInventory.choosePosition
          "
          detail
          (click)="
            changeSelectedAddToinventoryOption(addToInventory.choosePosition)
          "
          data-cy="click-change-selected-position-item"
        >
          <ion-label data-cy="choose-position-label">
            {{ 'i18n.lists.choosePosition' | translate }}
            <span data-cy="choose-position-text">
              {{
                (groupPosition === addToInventory.top
                  ? 'i18n.inventory.moveToTop'
                  : 'i18n.inventory.moveToBottom'
                ) | translate
              }}
            </span>
          </ion-label>
        </ion-item>
      </ion-col>

      <!-- ADD TO Inventory INPUT -->
      <ion-col offset="1.5" size="5.5" class="add-to-inventory-input">
        <!-- Choose Inventory Options -->
        <div
          *ngIf="
            selectedAddToinventoryOption === addToInventory.chooseInventory
          "
        >
          <ion-row class="inventory-sub-header">
            <ion-col size="10">
              <p class="select-inv-title">
                {{ 'i18n.inventory.selectAnInventory' | translate }}
              </p>
            </ion-col>
            <ion-col size="2">
              <p class="product-text">
                {{ 'i18n.inventory.products' | translate }}
              </p>
            </ion-col>
          </ion-row>
          <ion-searchbar
            id="Inventory-searchbar"
            data-cy="Inventory-searchbar"
            class="search"
            mode="md"
            placeholder="{{ 'i18n.inventory.searchForInventory' | translate }}"
            [(ngModel)]="searchKey"
            (ionClear)="resetSearchInventory()"
            (ionInput)="searchInventory()"
          >
          </ion-searchbar>
          <div
            *ngIf="filteredInventories?.length > 0"
            class="add-to-inventory-input-option"
          >
            <ng-container
              *ngFor="let inventory of filteredInventories; let i = index"
              [attr.data-index]="i"
            >
              <div
                (click)="onChooseInventory(inventory)"
                data-cy="inventory-add-product"
                class="inventory-section"
                [class.is-selected]="inventory === selectedInventory"
              >
                <div
                  data-cy="inventory-checkbox-section"
                  class="inventory-checkbox-section"
                >
                  <ion-checkbox
                    data-cy="inventory-checkbox"
                    mode="ios"
                    [checked]="inventory === selectedInventory"
                  ></ion-checkbox>
                </div>
                <ion-item data-cy="inventory-info-section" lines="none">
                  <ion-label
                    data-cy="inventory-name-text"
                    class="inventory-bold-text"
                  >
                    {{ inventory?.inventoryName }} -
                    {{ inventory?.inventoryDate | date: 'M/d/yyyy' }}
                    <p class="inventory-reg-text">
                      {{ 'i18n.inventory.lastUpdated' | translate }}&nbsp;{{
                        inventory?.ecomUpdateDtm | date: 'M/d/yyyy'
                      }}
                    </p>
                  </ion-label>
                  <ion-badge
                    data-cy="inventory-num-products-text"
                    mode="ios"
                    slot="end"
                  >
                    {{ getCount(inventory) }}
                  </ion-badge>
                </ion-item>
              </div>
            </ng-container>
          </div>
          <div
            *ngIf="filteredInventories?.length === 0"
            class="add-to-inventory-input-no-inventory"
          >
            <span data-cy="no-inventory-show-text">
              {{ 'i18n.inventory.noInventoryToShow' | translate }}
            </span>
          </div>
        </div>
        <!-- Choose Group Options -->
        <div
          *ngIf="selectedAddToinventoryOption === addToInventory.chooseGroup"
        >
          <div class="inventory-sub-header">
            <p class="select-inv-title">
              {{ 'i18n.inventory.selectGroup' | translate }}
            </p>
          </div>
          <ion-searchbar
            id="group-searchbar"
            data-cy="group-searchbar"
            class="search"
            mode="md"
            placeholder="{{ 'i18n.lists.searchGroup' | translate }}"
            [(ngModel)]="searchKey"
            (ionClear)="resetSearchGroup()"
            (ionInput)="searchGroup()"
          >
          </ion-searchbar>
          <div
            *ngIf="filteredGroups?.length > 0"
            class="add-to-inventory-input-option"
          >
            <ion-radio-group [(ngModel)]="group">
              <ion-item-divider></ion-item-divider>
              <ion-item *ngFor="let group of filteredGroups">
                <ion-label
                  id="group-name"
                  [attr.data-cy]="'group-name' + group?.groupName + 'name'"
                >
                  {{ group?.groupName }}
                </ion-label>
                <ion-radio
                  aria-labelledby="group-name"
                  mode="md"
                  slot="end"
                  [value]="group"
                  [attr.data-cy]="'group-name' + group?.groupName + 'radio'"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>
          <div
            *ngIf="filteredGroups?.length === 0"
            class="add-to-inventory-input-no-inventory"
          >
            <span data-cy="no-inventory-show-text">
              {{ 'i18n.inventory.noInventoryToShow' | translate }}
            </span>
          </div>
        </div>
        <!-- Choose Position Options -->
        <div
          *ngIf="selectedAddToinventoryOption === addToInventory.choosePosition"
        >
          <div class="inventory-sub-header">
            <p class="select-header-text">
              {{ 'i18n.inventory.selectPosition' | translate }}
            </p>
          </div>
          <div class="add-to-inventory-input-option">
            <ion-radio-group [(ngModel)]="groupPosition">
              <ion-item-divider></ion-item-divider>
              <ion-item>
                <ion-label id="move-to-top" data-cy="top-of-group-label">
                  {{ 'i18n.inventory.moveToTop' | translate }}
                </ion-label>
                <ion-radio
                  aria-labelledby="move-to-top"
                  mode="md"
                  slot="end"
                  value="TOP"
                  data-cy="top-of-group-radio"
                ></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label id="move-to-bottom" data-cy="bottom-of-group-label">
                  {{ 'i18n.inventory.moveToBottom' | translate }}
                </ion-label>
                <ion-radio
                  aria-labelledby="move-to-bottom"
                  mode="md"
                  slot="end"
                  value="BOTTOM"
                  data-cy="bottom-of-group-radio"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$() | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="add-to-inventory-cancel-button"
        class="usf-outline-green-dsktp-tblt-modal-button"
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="submit-modal-btn"
        data-cy="submit-add-to-inventory-modal"
        [ngClass]="
          !isValid()
            ? 'usf-fill-disabled-dsktp-tblt-modal-button'
            : 'usf-fill-green-dsktp-tblt-modal-button'
        "
        (click)="onSubmit()"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
