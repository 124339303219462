import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImportFile } from '@app/lists/model/import-file-model';
import { File, FilePicker } from '@robingenz/capacitor-file-picker';
import { BAD_PRODUCT_NUMBER } from '@shared/constants/lists-constants';
import { b64toBlob } from '@shared/helpers/file.helpers';
import { CSV_LIMITED_EXTENSIONS } from '@shared/services/file/model/file.model';
import { DocumentImportService } from '@shared/services/import-documents/document-import.service';
import { ImportCsvRow } from '@usf/list-types';

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss'],
})
export class ImportFileComponent {
  badData: boolean;
  processingData: boolean;
  nameToSend = '';
  @Input() file: ImportFile;
  @Input() isInternal: boolean;
  @Output() fileEvent: EventEmitter<ImportFile> = new EventEmitter();
  @Output() downloadTemplateEvent = new EventEmitter();
  constructor(private documentImportService: DocumentImportService) {}

  async onDeleteFile() {
    this.fileEvent.emit(undefined);
  }

  onAddFile = async () => {
    this.startReadData();
    try {
      const selectedFiles = await FilePicker.pickFiles({
        types: CSV_LIMITED_EXTENSIONS,
        multiple: false,
      });
      const file =
        selectedFiles.files.length > 0 ? selectedFiles.files[0] : undefined;
      if (file !== undefined) {
        await this.verifyDownloadedFile(file);
      }
    } catch (ignore) {
      this.processingData = false;
    }
  };

  buildCsvCallBack = async (ev: any) => {
    const csvData: string = ev.currentTarget.result;
    const checkArray = csvData.split(',');
    let validFile = false;
    if (checkArray.length > 5) {
      for (let i = 0; i < 5; i++) {
        if (checkArray[i]?.toLowerCase().includes('product number')) {
          validFile = true;
        }
      }
    }

    if (validFile) {
      let csvArray = (await this.documentImportService.breakDownCSV(
        csvData,
      )) as string[][];

      const objectsforList = this.documentImportService.trimCSVArraytoJustDesiredColumns(
        ['product number'],
        csvArray,
        ['group name', 'product note'],
      );

      if (objectsforList.length === 0) {
        validFile = false;
      }

      if (validFile) {
        const csvRows: ImportCsvRow[] = await this.convertStringsToImportRows(
          objectsforList,
        );
        if (csvRows.length === 0) {
          validFile = false;
        }

        if (validFile) {
          this.goodDataFound(csvRows);
        }
      }
    }

    if (!validFile) {
      this.badDataFound();
    }
  };

  verifyDownloadedFile = async (file: File) => {
    this.nameToSend = file.name;
    const blob = b64toBlob(file.data);
    const fileReader = new FileReader();
    fileReader.onload = this.buildCsvCallBack;
    fileReader.readAsText(blob);
  };

  startReadData = () => {
    this.nameToSend = '';
    this.badData = false;
    this.processingData = true;
  };

  badDataFound = () => {
    this.badData = true;
    this.processingData = false;
    this.fileEvent.emit(undefined);
  };

  goodDataFound = (rows: ImportCsvRow[]) => {
    this.processingData = false;
    this.fileEvent.emit({ name: this.nameToSend, rows });
  };

  // empty strig comes back as 0
  isNumber(value: string) {
    const numberForCheck = Number(value);
    return !Number.isNaN(numberForCheck) && numberForCheck !== 0;
  }

  convertStringsToImportRows = async (objectsforList: string[][]) => {
    let foundProductNummber = false;
    return objectsforList
      .filter(obj => {
        // filter out rows that do not have productNumbers, once we hit first valid productNumber then stop filtering.
        if (!foundProductNummber) {
          foundProductNummber = this.isNumber(obj['product number']);
          return foundProductNummber;
        }
        // filter out empty rows;
        return !(
          (obj['group name'] === undefined || obj['group name'] === '') &&
          (obj['product note'] === undefined || obj['product note'] === '') &&
          (obj['product number'] === undefined ||
            Number(obj['product number']) === 0)
        );
      })
      .map(obj => {
        let importRow = {} as ImportCsvRow;
        if (obj['group name']) importRow.groupName = obj['group name'];

        if (obj['product note']) importRow.productNote = obj['product note'];

        if (obj['row']) importRow.rowNumber = obj['row'];

        importRow.productNumber = this.isNumber(obj['product number'])
          ? Number(obj['product number'])
          : BAD_PRODUCT_NUMBER;

        return importRow;
      });
  };

  async downloadTemplate() {
    this.downloadTemplateEvent.emit();
  }
}
