import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PlatformEnum } from '@panamax/app-state';
import { BasicListItem } from '@shared/models/dual-pane-model';

@Component({
  selector: 'app-basic-list',
  templateUrl: './basic-list.component.html',
  styleUrls: ['./basic-list.component.scss', '../../dual-pane-shared.scss'],
})
export class BasicListComponent {
  @Input() dataCyTag = 'dual-pane';
  @Input() dataItems: BasicListItem[] = [];
  @Input() title: string;
  @Input() platform: PlatformEnum;
  platformRef = PlatformEnum;

  @Output() itemSelectedEvent = new EventEmitter<BasicListItem>();

  constructor() {}

  selectItem = (index: number) => {
    this.itemSelectedEvent.emit(this.dataItems[index]);
  };
}
