import { SeperatedColumn } from '@shared/models/seperated-columns';
import { ProductPropertiesEnum } from '@usf/product-types';
import { CONTRACT_PILLS_ENUM } from '@product-detail/models/contract-pills.enum';
import { CurrencyService } from '@shared/services/currency.service';
import { ProductRow } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { Product } from '@shared/models/product.model';
import { ProductStatusPill } from '@product-detail/components/product-detail-print/models/product-status-pill';

export const addValueToColumns = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  value: any,
) => {
  if (!columnMap.has(columnName)) {
    columnMap.set(columnName, {
      columnName: columnName,
      columnValues: [value],
      totals: '',
    });
  } else {
    columnMap.get(columnName).columnValues.push(value);
  }
  return columnMap;
};

export const scrubDocumentValue = (value: any) => {
  return value
    .toString()
    .replaceAll('"', '')
    .replaceAll('&', 'and')
    .replaceAll('<', ' ')
    .replaceAll('>', ' ');
};

export const getDocumentString = (value: any, limitTo?: number) => {
  if (
    limitTo !== undefined &&
    value !== undefined &&
    value !== null &&
    value.toString().length > limitTo
  ) {
    return value
      ? `\"${scrubDocumentValue(value).substring(0, limitTo)}\"`
      : '""';
  } else {
    return value ? `\"${scrubDocumentValue(value)}\"` : '""';
  }
};

export const getDocumentRaw = (value: any) => {
  return value ? `${value}` : '""';
};

export const getDocumentStatus = (product: ProductRow) => {
  if (!product.summary?.productDescLong && !product.summary?.productDescTxtl) {
    return '"Not Available"';
  }

  if (product.inventory?.productStatus === '9') {
    return '"Discontinued"';
  }

  if (
    product.inventory?.productStatus === '8' &&
    product?.summary?.properties?.has(ProductPropertiesEnum.dwo)
  ) {
    return '"Discontinued when out"';
  }

  if (
    product.summary?.properties?.has(ProductPropertiesEnum.jit) &&
    !(
      product?.summary?.properties?.has(ProductPropertiesEnum.ces) ||
      product?.summary?.properties?.has(ProductPropertiesEnum.direct) ||
      product?.summary?.properties?.has(ProductPropertiesEnum.specialOrder)
    )
  ) {
    return '"Early Cutoff"';
  }

  if (product.inventory?.productStatus === '1') {
    if (product.summary?.properties?.has(ProductPropertiesEnum.direct)) {
      return '"DIRECT"';
    }

    if (product.summary?.properties?.has(ProductPropertiesEnum.ces)) {
      return '"CES"';
    }

    if (product?.inventory?.isSpecialOrder) {
      return '"Call To Order"';
    }

    return '"Special Order"';
  }
  return '';
};

export const getDocumentStatusImage = (product: ProductRow) => {
  if (!product.summary?.productDescLong && !product.summary?.productDescTxtl) {
    return 'images/pdf/NotAvailable.png';
  }

  if (product.inventory?.productStatus === '9') {
    return 'images/pdf/Discontinued.png';
  }

  if (
    product.inventory?.productStatus === '8' &&
    product?.summary?.properties?.has(ProductPropertiesEnum.dwo)
  ) {
    return 'images/pdf/DWO.png';
  }

  if (
    product.summary?.properties?.has(ProductPropertiesEnum.jit) &&
    !(
      product?.summary?.properties?.has(ProductPropertiesEnum.ces) ||
      product?.summary?.properties?.has(ProductPropertiesEnum.direct) ||
      product?.summary?.properties?.has(ProductPropertiesEnum.specialOrder)
    )
  ) {
    return 'images/pdf/EarlyCutoff.png';
  }

  if (product.inventory?.productStatus === '1') {
    if (product.summary?.properties?.has(ProductPropertiesEnum.direct)) {
      return 'images/pdf/DIRECT.png';
    }

    if (product.summary?.properties?.has(ProductPropertiesEnum.ces)) {
      return 'images/pdf/CES.png';
    }

    if (product?.inventory?.isSpecialOrder) {
      return 'images/pdf/CallToOrder.png';
    }

    return 'images/pdf/SpecialOrder.png';
  }

  return '';
};

export const getProductStatusPills = (
  product: Product,
): ProductStatusPill[] => {
  let pills: ProductStatusPill[] = [];
  if (!product.summary?.productDescLong && !product.summary?.productDescTxtl) {
    pills.push({ path: '../assets/images/pdf/NotAvailable.png' });
  }

  if (product.inventory?.productStatus === '9') {
    pills.push({ path: '../assets/images/pdf/Discontinued.png' });
  }

  if (
    product.inventory?.productStatus === '8' &&
    product?.summary?.properties?.has(ProductPropertiesEnum.dwo)
  ) {
    pills.push({ path: '../assets/images/pdf/DWO.png' });
  }

  if (product.summary?.properties?.has(ProductPropertiesEnum.direct)) {
    pills.push({ path: '../assets/images/pdf/DIRECT.png' });
  }

  if (product.summary?.properties?.has(ProductPropertiesEnum.onShoppingList)) {
    pills.push({ path: '../assets/images/pdf/OnMyLists.png' });
  }

  if (product.summary?.properties?.has(ProductPropertiesEnum.onMasterList)) {
    pills.push({ path: '../assets/images/pdf/OnML.png' });
  }

  if (
    product.summary?.properties?.has(ProductPropertiesEnum.contract) &&
    product.contract?.version === 2
  ) {
    pills.push({
      path: getContractV2ImagePath(product),
      type: 'url',
    });
  }

  const typePill = getDocumentTypeImage(product);
  if (!!typePill && typePill.length !== 0) {
    pills.push({ path: '../assets/' + typePill });
  }

  return pills;
};

export const getContractV2ImagePath = (product: ProductRow) => {
  return `https://www.usfoods.com/content/dam/usf/UX/library/icons/moxe/${product.contract?.iconId}.svg`;
};

export const getDocumentTypeText = (product: ProductRow) => {
  if (product.summary?.properties?.has(ProductPropertiesEnum.contract)) {
    switch (product.contract?.iconDescription?.toUpperCase()) {
      case CONTRACT_PILLS_ENUM.orangeVizient:
        return '"vma-contract-orange"';
      case CONTRACT_PILLS_ENUM.greenVizient:
        return '"vma-contract-green"';
      case CONTRACT_PILLS_ENUM.NEW:
        return '"new-green"';
      case CONTRACT_PILLS_ENUM.CMA:
        return '"cma-red"';
      case CONTRACT_PILLS_ENUM.CMAR:
        return '"cmar-red"';
      case CONTRACT_PILLS_ENUM.GBUY:
        return '"gbuy-teal"';
      case CONTRACT_PILLS_ENUM.TERM:
        return '"term-yellow"';
      case CONTRACT_PILLS_ENUM.NEWR:
        return '"newr-gren"';
      case CONTRACT_PILLS_ENUM.CMP:
        return '"cmp-sky-blue"';
      case CONTRACT_PILLS_ENUM.HMS:
        return '"hms-host"';
      case CONTRACT_PILLS_ENUM.DOLL:
        if (
          product.contract?.clientConcept?.toUpperCase() ===
          CONTRACT_PILLS_ENUM.LEGENDS
        ) {
          return '"Legends"';
        } else if (
          product.contract?.clientConcept?.toUpperCase() ===
          CONTRACT_PILLS_ENUM.FIRST_WATCH
        ) {
          return '"fwrg-contract"';
        } else if (
          product.contract?.clientConcept?.toUpperCase() ===
            CONTRACT_PILLS_ENUM.BJS ||
          product.contract?.clientConcept?.toUpperCase() ===
            CONTRACT_PILLS_ENUM.BJSC
        ) {
          return '"Contracted"';
        } else {
          return '""';
        }
      case CONTRACT_PILLS_ENUM.DLR2:
        if (
          product.contract?.clientConcept?.toUpperCase() ===
            CONTRACT_PILLS_ENUM.BJS ||
          product.contract?.clientConcept?.toUpperCase() ===
            CONTRACT_PILLS_ENUM.BJSC
        ) {
          return '"Contracted"';
        } else {
          return '""';
        }
      default:
        return '""';
    }
  }
  if (product.summary?.properties?.has(ProductPropertiesEnum.scoop)) {
    return '"scoop"';
  }
  if (product.summary?.properties?.has(ProductPropertiesEnum.locallySourced)) {
    return '"locally-sourced"';
  }
  return '""';
};

export const getDocumentTypeImageForList = (product: ProductRow) => {
  if (
    product.summary?.properties?.has(ProductPropertiesEnum.contract) &&
    product.contract?.version === 2
  ) {
    return getContractV2ImagePath(product);
  }
  return getDocumentTypeImage(product);
};

export const getDocumentTypeImage = (product: ProductRow) => {
  if (
    product.summary?.properties?.has(ProductPropertiesEnum.contract) &&
    product.contract?.version !== 2
  ) {
    switch (product.contract?.iconDescription?.toUpperCase()) {
      case CONTRACT_PILLS_ENUM.orangeVizient:
        return 'images/pdf/VMAContractOrange.png';
      case CONTRACT_PILLS_ENUM.greenVizient:
        return 'images/pdf/VMAContractGreen.png';
      case CONTRACT_PILLS_ENUM.NEW:
        return 'images/pdf/NEWGreen.png';
      case CONTRACT_PILLS_ENUM.CMA:
        return 'images/pdf/CMARed.png';
      case CONTRACT_PILLS_ENUM.CMAR:
        return 'images/pdf/CMARRed.png';
      case CONTRACT_PILLS_ENUM.GBUY:
        return 'images/pdf/GBUYBlue.png';
      case CONTRACT_PILLS_ENUM.TERM:
        return 'images/pdf/TERMYellow.png';
      case CONTRACT_PILLS_ENUM.NEWR:
        return 'images/pdf/NEWRGreen.png';
      case CONTRACT_PILLS_ENUM.CMP:
        return 'images/pdf/CMP.png';
      case CONTRACT_PILLS_ENUM.HMS:
        return 'images/pdf/HMSHost-PDFs.png';
      case CONTRACT_PILLS_ENUM.DOLL:
        if (
          product.contract?.clientConcept?.toUpperCase() ===
          CONTRACT_PILLS_ENUM.LEGENDS
        ) {
          return 'images/pdf/LegendsContract.png';
        } else if (
          product.contract?.clientConcept?.toUpperCase() ===
          CONTRACT_PILLS_ENUM.FIRST_WATCH
        ) {
          return 'images/pdf/FWRGContract_PDFs.png';
        } else if (
          product.contract?.clientConcept?.toUpperCase() ===
            CONTRACT_PILLS_ENUM.BJS ||
          product.contract?.clientConcept?.toUpperCase() ===
            CONTRACT_PILLS_ENUM.BJSC
        ) {
          return 'images/pdf/BJs-Contracted-PDFs.png';
        } else {
          return '';
        }
      case CONTRACT_PILLS_ENUM.DLR2:
        if (
          product.contract?.clientConcept?.toUpperCase() ===
            CONTRACT_PILLS_ENUM.BJS ||
          product.contract?.clientConcept?.toUpperCase() ===
            CONTRACT_PILLS_ENUM.BJSC
        ) {
          return 'images/pdf/BJs-Contracted-PDFs.png';
        } else {
          return '';
        }
      default:
        return '';
    }
  }
  if (product.summary?.properties?.has(ProductPropertiesEnum.scoop)) {
    return 'images/pdf/scoop.png';
  }
  if (product.summary?.properties?.has(ProductPropertiesEnum.locallySourced)) {
    return 'images/pdf/LocallySourced.png';
  }
  return '';
};

export const getDocumentApiUpdatable = (value: any) => {
  return value?.currentValue ? `\"${value.currentValue}\"` : '""';
};

export const getDocumentCurrency = (value: any) => {
  return value && value > 0
    ? `\"${CurrencyService.toUSDString(value)}\"`
    : '"No Price"';
};

export const getDocumentCurrencyWithZero = (value: any) => {
  return value && value > 0
    ? `\"${CurrencyService.toUSDString(value)}\"`
    : '"$0.00"';
};

export const getRawDocumentCurrency = (value: any) => {
  return `\"${CurrencyService.toUSDString(value)}\"`;
};

export const getDocumentMultipleCurrency = (
  currency: number[],
  labels: string[],
) => {
  let result = '';
  for (let index = 0; index < currency.length; index++) {
    if (result !== '') {
      result += '\n';
    }
    const price = currency[index];
    const uom = labels[index];
    result += `${CurrencyService.toUSDString(price)}/${uom}`;
  }
  return result;
};

export const getRemotePillAsset = async (
  url: string,
): Promise<string | null> => {
  try {
    const res = await fetch(url);

    if (!res.ok) {
      return null;
    }

    const svg = await res.text();

    // IN ORDER TO BE CORRECTLY RENDERED IN THE PDF WE NEED TO FIRST CONVERT THE SVG ASSET TO PNG

    const svgElement = new DOMParser().parseFromString(svg, 'image/svg+xml')
      .documentElement;
    const canvas = document.createElement('canvas');
    canvas.width = parseFloat(svgElement.getAttribute('width') || '0');
    canvas.height = parseFloat(svgElement.getAttribute('height') || '0');

    const img = new Image();

    return new Promise((resolve, reject) => {
      img.onload = function () {
        let ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL('image/png'));
        } else {
          reject('Failed to get canvas context to load remote pill asset');
        }
      };

      img.onerror = function () {
        reject('Failed to load remote pill asset');
      };

      img.src = 'data:image/svg+xml;base64,' + btoa(svg);
    });
  } catch (error) {
    console.error('Failed to load remote pill asset:', error);
    return null;
  }
};
