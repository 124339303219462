import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, first, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import {
  OrderItem,
  OrderStatus,
  OrderStoreService,
  PARTNER_ACTIONS,
  VISUAL_PUNCHTHRU_ACTIONS,
  nextDeliverySelectors,
} from '@usf/ngrx-order';
import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';
import { PATHS } from '@shared/constants/paths';
import { v4 as uuidv4 } from 'uuid';
import { VisualPunchThruService } from '@order/services/visual-punchthru.service';
import { combineLatest, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { UsfTokenStorageService } from '@panamax/app-state';
import { LoadingSpinnerService } from '@shared/services/loading-spinner/loading-spinner.service';
import { ProductService } from '@shared/services/product/product.service';
import { LoadingState } from '@usf/ngrx-product';

@Injectable({
  providedIn: 'root',
})
export class VisualPunchThruEffects {
  loadVptOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VISUAL_PUNCHTHRU_ACTIONS.loadVptOrder),
        switchMap(action =>
          this.tokenStorageService.getPunchthruSession().pipe(
            filter(session => !!session),
            first(),
            tap(session => {
              if (session['orderNumber']) {
                this.orderStoreService.getVptOrderByOrderNumber(
                  session['orderNumber'],
                );
              }
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  getVptOrderByOrderNumberSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VISUAL_PUNCHTHRU_ACTIONS.getVptOrderByOrderNumberSuccess),
        mergeMap(action => {
          const productNumbers: number[] = this.getProductNumbers(
            action.order.orderItems,
          );
          this.productService.loadProducts(productNumbers);
          return combineLatest([
            of(action.order),
            this.productService.selectLoadingStateOfProducts(productNumbers),
            of(productNumbers),
          ]).pipe(
            filter(
              ([order, productLoadingState, productNumbers]) =>
                productLoadingState === LoadingState.loaded ||
                productLoadingState === LoadingState.error,
            ),
            take(1),
            tap(([o, loadingState, pn]) => {
              if (
                loadingState === LoadingState.error ||
                o?.orderItems?.length === 0
              ) {
                return this.visualPunchThruService.handleVptSubmissionFailure();
              }
            }),
          );
        }),
        mergeMap(([order, productLoadingState, productNumbers]) => {
          return combineLatest([
            of(order),
            this.store
              .select(nextDeliverySelectors.selectNextDeliveryDetails)
              .pipe(
                filter(ndd => !!ndd && !ndd.loading),
                take(1),
              ),
            this.productService.getProducts(productNumbers),
            of(productLoadingState),
          ]).pipe(take(1));
        }),
        tap(([order, nextDeliveryDate, productMap, productLoadingState]) => {
          if (
            productLoadingState === LoadingState.error ||
            order?.orderItems?.length === 0 ||
            !nextDeliveryDate?.deliveryDate
          ) {
            return this.visualPunchThruService.handleVptSubmissionFailure();
          } else {
            return this.visualPunchThruService.finalizeAndSubmitVptOrder(
              order,
              productMap,
              nextDeliveryDate.deliveryDate,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  clientSubmitVptOrderSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VISUAL_PUNCHTHRU_ACTIONS.submitVptOrderSuccess),
      switchMap(resp => {
        const actions: any[] = [];
        if (
          resp?.order?.orderHeader &&
          (resp?.order?.orderHeader.orderStatus === OrderStatus.SUBMITTING ||
            resp?.order?.orderHeader.orderStatus ===
              OrderStatus.EDITING_SUBMITTED) &&
          !resp?.order?.orderHeader.errorDetails
        ) {
          const orderId = `${resp?.order?.orderHeader.customerNumber}:${resp?.order?.orderHeader.departmentNumber}:${resp?.order?.orderHeader.orderId}`;
          this.navigationHelperService.vptRouteToOrderConfirmation(orderId);
        } else if (
          resp?.order?.orderHeader?.errorDetails?.errNumber === 10007
        ) {
          this.navigationHelperService.routeTo(PATHS.MY_ORDERS_VIEW);
        }
        this.loadingSpinnerService.dismissSpinnerModal();

        return actions;
      }),
    ),
  );
  logGetPartnerDataFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PARTNER_ACTIONS.getPartnerDataFail),
        tap(error => {
          this.navigationHelperService.routeTo(PATHS.ERROR);
        }),
      ),
    { dispatch: false },
  );

  submitVptOrderFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VISUAL_PUNCHTHRU_ACTIONS.submitVptOrderFail),
        tap(_ => {
          this.visualPunchThruService.handleVptSubmissionFailure();
        }),
      ),
    { dispatch: false },
  );

  getVptOrderByOrderNumberFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VISUAL_PUNCHTHRU_ACTIONS.getVptOrderByOrderNumberFail),
        tap(_ => {
          this.visualPunchThruService.handleVptSubmissionFailure();
        }),
      ),
    { dispatch: false },
  );

  uuid() {
    return uuidv4();
  }

  getProductNumbers(orderItems: OrderItem[]): number[] {
    return orderItems.map(oi => oi.productNumber);
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private productService: ProductService,
    private visualPunchThruService: VisualPunchThruService,
    private navigationHelperService: NavigationHelperService,
    private tokenStorageService: UsfTokenStorageService,
    private orderStoreService: OrderStoreService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) {}
}
