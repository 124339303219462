<app-message-bar
  [isMobile]="false"
  [shouldDisplay]="shouldDisplayMessageBar"
  [message]="messageBarText"
></app-message-bar>
<ng-container
  *ngIf="
    (orderService.restrictNavigationForCurrentOrder$() | async) !== true;
    else navigationRestricted
  "
>
  <ion-grid *ngIf="viewModel$ | async as viewModel" [fixed]="true">
    <ion-row class="header-row">
      <ion-col size-xs="12" size-sm="2" size-md="1.5" size-lg="1.5" size-xl="1">
        <a
          *ngIf="!viewModel?.manageCustomizationsLogo"
          (click)="routeTo(PATHS.HOME)"
          data-cy="desktop-header-logo-home-link"
        >
          <img class="usf-logo" src="assets/images/usfoods-logo-color.svg" />
        </a>
        <app-logo *ngIf="viewModel?.manageCustomizationsLogo"> </app-logo>
      </ion-col>
      <ion-col
        class="ion-text-end"
        offset-md="0.25"
        offset-lg="0.25"
        offset-xl="0.25"
        size-xs="12"
        size-sm="10"
        size-md="10.25"
        size-lg="10.25"
        size-xl="10.75"
      >
        <ion-row class="header-top-row ion-align-items-center">
          <div class="header-left-section" *ngIf="!isGuestUser">
            <ng-container
              *ngTemplateOutlet="customerSelectorButton"
            ></ng-container>
          </div>
          <div class="header-left-section" *ngIf="isGuestUser">
            <ng-container
              *ngTemplateOutlet="zipCodeChangeSelectorButton"
            ></ng-container>
          </div>
          <div class="header-right-section">
            <app-usf-search-typeahead></app-usf-search-typeahead>
            <app-toggle-kibo-search
              *appShowSplit="[toogleKiboSearchFeatureFlag]"
            ></app-toggle-kibo-search>
            <div class="icon-wrapper" *ngIf="!isGuestUser">
              <div
                style="position: relative"
                data-cy="navigate-to-notifications-header-link"
                (click)="routeTo(PATHS.NOTIFICATIONS)"
              >
                <ion-icon class="header-icon" name="notifications"></ion-icon>
                <ion-badge
                  *ngIf="unreadNotifications > 0 && unreadNotifications < 10"
                  class="notifications-badge-header-single-digit"
                  data-cy="notifications-single-count-badge"
                >
                  {{ unreadNotifications }}
                </ion-badge>
                <ion-badge
                  *ngIf="unreadNotifications >= 10"
                  class="notifications-badge-header-multiple-digit"
                  data-cy="notifications-multiple-count-badge"
                >
                  {{ unreadNotifications > 99 ? '99+' : unreadNotifications }}
                </ion-badge>
              </div>
              <div (click)="openUserPopover($event)">
                <ion-icon
                  [ngClass]="
                    activeButton === 4 && isClicked ? 'highlight-icon' : ''
                  "
                  class="header-icon"
                  name="person-circle-outline"
                ></ion-icon>
              </div>
            </div>
          </div>
        </ion-row>
        <hr />
        <ion-row class="header-bottom-row ion-align-items-center">
          <div class="header-left-section">
            <ion-button
              class="header-buttons"
              fill="clear"
              size="small"
              translate
              data-cy="search-catalog-header-button"
              (click)="
                openMoxeLinksPopover(
                  $event,
                  viewModel?.productAndDealsLink?.menuOptions,
                  1
                )
              "
              [ngClass]="
                activeButton === 1 && isClicked ? 'highlight-button' : ''
              "
            >
              {{ 'i18n.header.productsDeals' }}
              <ion-icon
                class="arrow-icon"
                src="assets/icon/ios-arrow-down.svg"
                slot="end"
              ></ion-icon>
            </ion-button>

            <ion-button
              class="header-buttons"
              fill="clear"
              size="small"
              translate
              data-cy="open-business-header-button"
              (click)="
                openMoxeLinksPopover(
                  $event,
                  viewModel?.myBusinessLink?.menuOptions,
                  2
                )
              "
              [ngClass]="
                activeButton === 2 && isClicked ? 'highlight-button' : ''
              "
            >
              {{ 'i18n.header.myBusiness' }}
              <ion-icon
                class="arrow-icon"
                src="assets/icon/ios-arrow-down.svg"
                slot="end"
              ></ion-icon>
            </ion-button>
            <ion-button
              *ngIf="!isGuestUser"
              class="header-buttons"
              fill="clear"
              size="small"
              translate
              data-cy="open-exclusives-header-button"
              (click)="
                openMoxeLinksPopover(
                  $event,
                  viewModel?.myOrdersLink?.menuOptions,
                  3
                )
              "
              [ngClass]="
                activeButton === 3 && isClicked ? 'highlight-button' : ''
              "
            >
              {{ 'i18n.header.myOrders' }}
              <ion-icon
                class="arrow-icon"
                src="assets/icon/ios-arrow-down.svg"
                slot="end"
              ></ion-icon>
            </ion-button>
            <ion-button
              *ngIf="!isGuestUser"
              class="header-buttons"
              fill="clear"
              size="small"
              shape="round"
              translate
              (click)="routeTo(PATHS.LISTS)"
              data-cy="navigate-to-lists-header-link"
            >
              {{ 'i18n.header.myLists' }}
            </ion-button>
          </div>
          <div class="header-right-section">
            <ion-button
              *ngIf="isGuestUser"
              class="usf-fill-green-button become-a-customer-header-button"
              data-cy="become-a-customer-header-button"
            >
              <span>{{ 'i18n.header.becomeACustomer' | translate }}</span>
            </ion-button>
            <ion-button
              *ngIf="isGuestUser"
              class="usf-outline-green-button"
              data-cy="guest-login-header-button"
            >
              <span>{{ 'i18n.header.logIn' | translate }}</span>
            </ion-button>
            <ion-button
              *ngIf="!isGuestUser"
              class="usf-outline-green-button"
              type="submit"
              (click)="
                viewModel?.orderItemTallies?.totalItems > 0
                  ? routeTo(PATHS.ORDER)
                  : createNewOrder()
              "
              data-cy="create-new-order-header-button"
            >
              <ng-container *ngIf="viewModel?.cartSyncing; else cartSynced">
                <ion-spinner
                  class="cart-badge-header-spinner"
                  color="warning"
                  name="circular"
                ></ion-spinner
              ></ng-container>
              <ng-template #cartSynced>
                <ion-icon name="cart" class="cart-icon"></ion-icon>
              </ng-template>
              <span *ngIf="viewModel?.orderItemTallies?.totalItems === 0">{{
                'i18n.home.createOrder' | translate
              }}</span>
              <span *ngIf="viewModel?.orderItemTallies?.totalItems > 0">
                {{ 'i18n.common.cart' | translate }}
                {{ totalPrice$ | async | currency }}
                ({{
                  viewModel?.orderItemTallies?.totalItems > 999
                    ? '999+'
                    : viewModel?.orderItemTallies?.totalItems
                }})
              </span>
            </ion-button>
          </div>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-container>

<ng-template #navigationRestricted>
  <ng-container *ngIf="userCustomerInfo$ | async as userCustomerInfo">
    <div class="nav-restricted-header">
      <ion-grid>
        <ng-container *ngIf="viewModel$ | async as viewModel">
          <div class="nav-restricted-inner">
            <img
              *ngIf="
                !viewModel?.manageCustomizationsLogo ||
                (orderService.restrictNavigationForCurrentOrder$() | async) ===
                  true
              "
              src="assets/images/usfoods-logo-color.svg"
              class="usf-logo-disabled"
            />
            <app-logo
              *ngIf="
                viewModel?.manageCustomizationsLogo &&
                (orderService.restrictNavigationForCurrentOrder$() | async) !==
                  true
              "
            ></app-logo>
            <div
              class="nav-restricted-customer"
              data-cy="nav-restricted-customer-label"
            >
              {{ userCustomerInfo.selectedCustomer?.customerName | titlecase }}
              ({{ userCustomerInfo.selectedCustomer?.customerNumber }})
              {{
                userCustomerInfo.selectedDepartment
                  ? ((userCustomerInfo$ | async)?.selectedDepartment
                      ?.departmentName | titlecase) +
                    ' (' +
                    userCustomerInfo.selectedDepartment?.departmentNumber +
                    ')'
                  : ''
              }}
            </div>
          </div>
        </ng-container>
      </ion-grid>
    </div>
  </ng-container>
</ng-template>

<ng-template #customerSelectorButton>
  <ng-container *ngIf="userCustomerInfo$ | async as userCustomerInfo">
    <ion-button
      [ngClass]="
        !userCustomerInfo.customers?.length ||
        (userCustomerInfo.customers?.length === 1 &&
          userCustomerInfo.customers[0].departments?.length < 2 &&
          !isSuperUser) ||
        isAlerts
          ? 'usf-outline-disabled-button'
          : 'usf-outline-green-button'
      "
      data-cy="open-customer-selection-header-popover"
      expand="block"
      type="submit"
      (click)="openCustomerSelection($event)"
    >
      <ng-container *ngIf="(customerSwitchStatus$ | async) !== 'pending'">
        {{ userCustomerInfo.selectedCustomer?.customerName | titlecase }}
        ({{ userCustomerInfo.selectedCustomer?.customerNumber }})
        {{
          userCustomerInfo.selectedDepartment
            ? (userCustomerInfo.selectedDepartment?.departmentName
                | titlecase) +
              ' (' +
              userCustomerInfo.selectedCustomerState?.departmentNumber +
              ')'
            : ''
        }}
      </ng-container>
      <ng-container *ngIf="(customerSwitchStatus$ | async) === 'pending'">
        {{ 'i18n.header.loading' | translate
        }}<ion-spinner name="circles"></ion-spinner>
      </ng-container>
      <ion-icon
        *ngIf="
          !isSuperUser &&
          (userCustomerInfo.customers?.length > 1 ||
            (userCustomerInfo.customers?.length === 1 &&
              userCustomerInfo.customers[0].departments?.length > 1))
        "
        name="chevron-down-outline"
      ></ion-icon>
    </ion-button>
  </ng-container>
</ng-template>

<ng-template #zipCodeChangeSelectorButton>
  <ng-container>
    <ion-button
      class="usf-outline-green-button zip-code-change-button"
      data-cy="open-zip-code-change-header-popover"
      expand="block"
      type="submit"
      (click)="openZipCodeChangePopover($event)"
    >
      <ng-container>
        <!--Hardcoded Data-->
        Guest (Zip Code: 10012)
      </ng-container>
      <ion-icon
        src="assets/icon/pencil-green.svg"
        class="guest-pencil-icon"
      ></ion-icon>
    </ion-button>
  </ng-container>
</ng-template>

<ion-modal
  *ngIf="isSuperUser"
  class="super-user-customers-modal-desktop"
  [isOpen]="customerService.isSuperUserCustomersModalOpen$ | async"
  (ionModalDidDismiss)="customerService.setIsSuperUserCustomersModalOpen(false)"
>
  <ng-template>
    <app-super-user-customers-modal
      class="ion-page"
      [platform]="platformService.platformType"
      (isSuperUserCustomersModalOpenEmitter)="
        customerService.setIsSuperUserCustomersModalOpen(false)
      "
      (superUserCustomerSelectedEmitter)="setSuperUserSelectedCustomer($event)"
    >
    </app-super-user-customers-modal>
  </ng-template>
</ion-modal>
