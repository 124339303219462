<ion-header class="ion-no-border ion-header-modal-mobile">
  <ion-toolbar>
    <ion-buttons *ngIf="selectedOption !== optionRef.noSelection" slot="start">
      <ion-back-button
        data-cy="import-list-modal-back-button"
        text=""
        (click)="goBack()"
      ></ion-back-button>
    </ion-buttons>
    <ion-label
      data-cy="import-list-modal-title"
      *ngIf="selectedOption === optionRef.noSelection"
    >
      {{ 'i18n.lists.importListModal.title' | translate }}
    </ion-label>
    <ion-label
      data-cy="import-list-modal-title-file"
      *ngIf="selectedOption === optionRef.listFile"
    >
      {{ 'i18n.lists.importListModal.chooseFile' | translate }}
    </ion-label>
    <ion-label
      data-cy="import-list-modal-title-name"
      *ngIf="selectedOption === optionRef.listName"
    >
      {{ 'i18n.lists.importListModal.chooseName' | translate }}
    </ion-label>
    <ion-label
      data-cy="import-list-modal-title-type"
      *ngIf="selectedOption === optionRef.listStatus"
    >
      {{ 'i18n.lists.importListModal.chooseType' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-modal-mobile">
  <ion-grid>
    <ion-row>
      <!-- Copy List Options -->
      <ion-col
        size="12"
        class="import-list-options"
        *ngIf="selectedOption === optionRef.noSelection"
      >
        <ion-item detail (click)="goForward(optionRef.listFile)">
          <ion-label class="import-list-option" data-cy="">
            {{ 'i18n.lists.importListModal.chooseFile' | translate }}
            <div class="sub-header" *ngIf="file !== undefined">
              {{ file.name }}
            </div>
            <div class="sub-header" *ngIf="file === undefined">
              {{ 'i18n.lists.importListModal.chooseFileSubHeader' | translate }}
            </div>
          </ion-label>
        </ion-item>
        <ion-item detail (click)="goForward(optionRef.listName)">
          <ion-label class="import-list-option" data-cy="">
            {{ 'i18n.lists.importListModal.chooseName' | translate }}
            <div class="sub-header" *ngIf="newListName && newListName !== ' '">
              {{ newListName }}
            </div>
            <div class="sub-header" *ngIf="!newListName || newListName === ' '">
              {{ 'i18n.lists.importListModal.chooseNameSubHeader' | translate }}
            </div>
          </ion-label>
        </ion-item>
        <ion-item
          detail
          (click)="goForward(optionRef.listStatus)"
          *ngIf="listData.userKind === UserKinds.Internal"
        >
          <ion-label class="import-list-option" data-cy="">
            {{ 'i18n.lists.importListModal.chooseType' | translate }}
            <div class="sub-header">{{ listStatus }}</div>
          </ion-label>
        </ion-item>
        <!-- Copy List Selections-->
      </ion-col>
      <!-- List File -->
      <ion-col
        size="12"
        class="import-list-input"
        *ngIf="selectedOption === optionRef.listFile"
      >
        <app-import-file
          [file]="file"
          (fileEvent)="updateFile($event)"
          (downloadTemplateEvent)="getListTemplate()"
          [isInternal]="listData.userKind === UserKinds.Internal"
        >
        </app-import-file>
      </ion-col>

      <!-- newListName -->
      <ion-col
        size="12"
        class="import-list-input"
        *ngIf="selectedOption === optionRef.listName"
      >
        <ion-item
          class="import-list-name"
          [class.list-name-exists]="listNameExists"
        >
          <ion-input
            label="{{ 'i18n.lists.newListName' | translate }}"
            label-placement="floating"
            id="usf-line-input-style"
            data-cy="list-name-input"
            maxlength="30"
            [(ngModel)]="newListName"
            (ngModelChange)="getListNameExists()"
            value="{{ newListName }}"
            [placeholder]="'i18n.lists.importListModal.enter' | translate"
            [class.duplicate-name]="listNameExists"
            trimSpace
          ></ion-input>
        </ion-item>
        <p *ngIf="listNameExists" class="duplicate-name-error">
          {{ 'i18n.lists.importListModal.unique' | translate }}
        </p>
      </ion-col>

      <!-- ListType -->
      <ion-col
        size="12"
        class="import-list-input import-list-type"
        *ngIf="selectedOption === optionRef.listStatus"
      >
        <ion-radio-group [(ngModel)]="listStatus" data-cy="list-type-radio">
          <ion-item>
            <ion-label id="public-list" data-cy="public-list-type-label">
              {{ 'i18n.lists.public' | translate }}
            </ion-label>
            <ion-radio
              aria-labelledby="public-list"
              mode="md"
              slot="end"
              value="{{ 'Public' }}"
              data-cy="public-list-type-radio"
            ></ion-radio>
          </ion-item>
          <ion-item *ngIf="listData.userKind === UserKinds.Internal">
            <ion-label id="internal-list" data-cy="internal-list-type-label">
              {{ 'i18n.lists.internal' | translate }}
            </ion-label>
            <ion-radio
              aria-labelledby="internal-list"
              mode="md"
              slot="end"
              value="{{ 'Internal' }}"
              data-cy="internal-list-type-radio"
            ></ion-radio>
          </ion-item>
          <ion-item *ngIf="listData.userKind === UserKinds.Internal">
            <ion-label id="private-list" data-cy="private-list-type-label">
              {{ 'i18n.lists.private' | translate }}
            </ion-label>
            <ion-radio
              aria-labelledby="private-list"
              mode="md"
              slot="end"
              value="{{ 'Private' }}"
              data-cy="private-list-type-radio"
            ></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="import-list-cancel-button"
        class="usf-outline-green-mobile-modal-button"
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        *ngIf="selectedOption === optionRef.noSelection"
        data-cy="import-list-modal-next-button"
        [ngClass]="
          file !== undefined &&
          newListName &&
          newListName !== ' ' &&
          listStatus &&
          !listNameExists
            ? 'usf-fill-green-mobile-modal-button'
            : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="submit()"
      >
        {{ 'i18n.common.create' | translate }}
      </ion-button>
      <ion-button
        *ngIf="selectedOption !== optionRef.noSelection"
        data-cy="import-list-modal-next-button"
        [ngClass]="
          (selectedOption === optionRef.listFile && file !== undefined) ||
          (selectedOption === optionRef.listName &&
            newListName &&
            newListName !== ' ' &&
            !listNameExists) ||
          selectedOption === optionRef.listStatus
            ? 'usf-fill-green-mobile-modal-button'
            : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="goBack()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
