<div
  #productCard
  [id]="recentlyViewedRecToken"
  class="main-container"
  [ngClass]="productCardClass"
>
  <div
    class="top-drawer"
    *ngIf="
      !isGuestUser &&
      (productDetails?.drawerMsg?.message ||
        productDetails?.productCardWarningMsg?.primaryText)
    "
    [ngClass]="
      !productDetails?.drawerMsg?.isWarning &&
      !productDetails?.productCardWarningMsg?.display
        ? 'normal-drawer'
        : 'warning-drawer'
    "
  >
    <ion-icon name="{{ productDetails?.drawerMsg?.iconName }}"></ion-icon>
    <p class="top-banner-content">
      <b
        *ngIf="
          productDetails?.drawerMsg?.isWarning ||
            productDetails?.productCardWarningMsg?.display;
          else normalDrawer
        "
        >{{ productDetails?.productCardWarningMsg?.primaryText | translate }}</b
      >
      <span
        *ngIf="productDetails?.productCardWarningMsg?.secondaryText"
        data-cy="product-card-top-drawer-subtext-1"
      >
        &nbsp;{{
          productDetails?.productCardWarningMsg?.secondaryText | translate
        }}
      </span>
      <span
        *ngIf="productDetails?.productCardWarningMsg?.secondaryTextDate"
        data-cy="product-card-top-drawer-subtext-2"
      >
        &nbsp;{{
          productDetails?.productCardWarningMsg?.secondaryTextDate | translate
        }}
      </span>
      <span
        *ngIf="!!productDetails?.productCardWarningMsg?.boldedText"
        data-cy="product-card-top-drawer-boldtext-1"
      >
        <b>{{
          productDetails?.productCardWarningMsg?.boldedText | translate
        }}</b>
      </span>
    </p>
  </div>
  <ng-template #normalDrawer>
    {{ productDetails?.drawerMsg?.message }}
  </ng-template>
  <div class="body-container">
    <div class="img-container">
      <ng-container
        *ngTemplateOutlet="detailImage; context: { $implicit: imageContent }"
      >
      </ng-container>
      <div class="floating-actions-container" *ngIf="isActionable">
        <button>
          <ion-icon name="menu-outline"></ion-icon>
          <ng-content></ng-content>
        </button>

        <button
          (click)="closeCardHandler(productDetails)"
          [disabled]="cardsNumber === 2"
        >
          <ion-icon name="close-outline"></ion-icon>
        </button>
      </div>
    </div>
    <div class="bottom-content-container">
      <ng-container [ngTemplateOutlet]="detailCardTitle"></ng-container>
      <div class="subtitle">
        <span class="sub-content swiper-no-swiping">
          #{{ productDetails?.productNumber }}
        </span>
        <span
          class="divider"
          [class.hidden]="!productDetails?.customerProductNumber"
        ></span>
        <!--        TODO HOOK GL value-->
        <span
          *ngIf="productDetails?.customerProductNumber"
          class="sub-content cp-number"
        >
          {{ productDetails?.customerProductNumber }}
        </span>
        <span
          class="divider"
          [class.hidden]="!productDetails?.summary?.salesPackSize"
        ></span>
        <span
          *ngIf="productDetails?.summary?.salesPackSize"
          class="sub-content"
        >
          {{ productDetails?.summary?.salesPackSize }}
        </span>
      </div>
      <div
        data-cy="quantity-boxes-section"
        class="quantity-boxes-container"
        *ngIf="
          !isGuestUser &&
            ((platformService.isTouchExperience$() | async) === false ||
              useQuantityButtons === true);
          else addToOrderButton
        "
      >
        <div class="left-quantity-section">
          <app-quantity-input
            *ngIf="mode !== ProductCompareEnum.exceptionSubCompare"
            [productNumber]="productDetails?.summary?.productNumber"
            [isDisabled]="
              !productDetails?.orderable ||
              productDetails?.summary?.properties?.has(
                propertiesEnum.specialOrder
              )
            "
            [isEaches]="false"
            [platform]="platformService.platformType"
            [quantity]="(orderItem$ | async)?.unitsOrdered"
            (quantityUpdateEmitter)="changeQuantityHandler($event)"
          ></app-quantity-input>
          <ng-container
            *ngIf="
              !isGuestUser &&
              !productDetails?.summary?.properties?.has(
                propertiesEnum.specialOrder
              )
            "
          >
            <app-price-display
              class="price-text"
              [disabled]="
                productDetails?.selectedCustomer?.ogPrintPriceInd === 'N'
              "
              [price]="productDetails?.pricing"
              [displayCases]="true"
              [catchWeightFlag]="productDetails?.summary?.catchWeightFlag"
              [priceUom]="productDetails?.summary?.priceUom"
            ></app-price-display>
          </ng-container>
          <span
            class="price-text"
            *ngIf="
              !isGuestUser &&
              productDetails?.summary?.properties?.has(
                propertiesEnum.specialOrder
              )
            "
          >
            {{ 'i18n.lists.specialOrderProductMessage' | translate }}
          </span>
        </div>
        <div
          *ngIf="productDetails?.summary?.breakable"
          class="right-quantity-section"
        >
          <app-quantity-input
            *ngIf="mode !== ProductCompareEnum.exceptionSubCompare"
            [productNumber]="productDetails?.summary?.productNumber"
            [isDisabled]="
              !productDetails?.orderable ||
              productDetails?.summary?.properties?.has(
                propertiesEnum.specialOrder
              )
            "
            [isEaches]="true"
            [platform]="platformService.platformType"
            [quantity]="(orderItem$ | async)?.eachesOrdered"
            (quantityUpdateEmitter)="changeQuantityHandler($event)"
          ></app-quantity-input>
          <ng-container
            *ngIf="
              !isGuestUser &&
              !productDetails?.summary?.properties?.has(
                propertiesEnum.specialOrder
              )
            "
          >
            <app-price-display
              class="price-text"
              [disabled]="
                productDetails?.selectedCustomer?.ogPrintPriceInd === 'N'
              "
              [price]="productDetails?.pricing"
              [displayCases]="false"
              [eachUom]="productDetails?.summary?.eachUom"
            ></app-price-display>
          </ng-container>
          <span
            class="price-text"
            *ngIf="
              !isGuestUser &&
              productDetails?.summary?.properties?.has(
                propertiesEnum.specialOrder
              )
            "
          >
            {{ 'i18n.lists.specialOrderProductMessage' | translate }}
          </span>
        </div>
      </div>
      <div class="action-buttons">
        <ion-button
          *ngIf="
            (mode === ProductCompareEnum.replacementCompare ||
              mode === ProductCompareEnum.replacementListsCompare) &&
            productDetails?.productNumber !== originalItemNumber
          "
          class="usf-outline-green-button"
          (click)="acceptReplace()"
        >
          {{ 'i18n.search.searchCompare.acceptReplacement' | translate }}
        </ion-button>
        <ion-button
          *ngIf="
            (mode === ProductCompareEnum.betterBuyCompare ||
              mode === ProductCompareEnum.betterBuyListsCompare) &&
            productDetails?.productNumber !== originalItemNumber
          "
          class="usf-outline-green-button"
          (click)="acceptSwap()"
        >
          {{ 'i18n.search.searchCompare.acceptSwap' | translate }}
        </ion-button>
        <ion-button
          *ngIf="
            (mode === ProductCompareEnum.orderSubCompare ||
              mode === ProductCompareEnum.exceptionSubCompare) &&
            productDetails?.productNumber !== originalItemNumber
          "
          class="usf-outline-green-button"
          (click)="selectSubstitute()"
        >
          {{ 'i18n.search.searchCompare.selectSubstitute' | translate }}
        </ion-button>
        <ion-button
          data-cy="pc-add-to-list-btn"
          *ngIf="
            !isGuestUser &&
            (mode === ProductCompareEnum.listsDetailCompare ||
              mode === ProductCompareEnum.reviewOrder ||
              mode === ProductCompareEnum.default)
          "
          class="usf-outline-green-button"
          (click)="openAddToListModal()"
        >
          <div class="pc-icon-button">
            <ion-icon name="add-outline"></ion-icon>
            {{ 'i18n.search.addToList' | translate }}
          </div>
        </ion-button>
        <div *ngIf="shouldShowButtonSpacer" class="button-spacer"></div>
      </div>
      <div *ngIf="showPills === true">
        <div class="pill-spacer"></div>
        <div
          *ngIf="productDetails?.productTags?.length === 0"
          class="pill-spacer"
        ></div>
        <app-usf-product-tag-list
          [tagList]="productDetails?.productTags"
          [infoIconEnabled]="false"
          [allTagsClickable]="true"
          [product]="productDetails"
          [maxTags]="
            platformService?.platformType ===
            platformService?.platformEnum?.tablet
              ? 5
              : 2
          "
        >
        </app-usf-product-tag-list>
      </div>
    </div>
  </div>
</div>

<ng-template #addToOrderButton>
  <ng-container *ngIf="!isGuestUser">
    <ion-button
      id="pc-add-to-order-{{ productDetails?.productNumber }}"
      [ngClass]="{
        'usf-fill-disabled-button': !productDetails?.orderable,
        'usf-fill-green-button': productDetails?.orderable
      }"
      [disabled]="
        !productDetails?.orderable ||
        productDetails?.summary?.properties?.has(propertiesEnum.specialOrder)
      "
      *ngIf="mode !== ProductCompareEnum.exceptionSubCompare"
      (click)="setAddToOrderOpen(true)"
    >
      <div class="pc-icon-button">
        <ion-icon name="cart-outline"></ion-icon>
        {{ 'i18n.search.addToOrder' | translate }}
      </div>
    </ion-button>
    <ion-modal
      [isOpen]="isAddToOrderOpen"
      [initialBreakpoint]="1"
      [breakpoints]="[0, 1]"
      class="ion-modal-touch auto-height"
      (didDismiss)="setAddToOrderOpen(false)"
    >
      <ng-template>
        <app-product-detail-add-to-order-modal
          [productDetails]="productDetails"
          [orderItem]="orderItem$ | async"
          (quantityUpdateEmitter)="changeQuantityHandler($event)"
        ></app-product-detail-add-to-order-modal>
      </ng-template>
    </ion-modal>
  </ng-container>
</ng-template>

<ng-template #detailCardTitle>
  <a
    *ngIf="!navigationDisabled"
    class="title"
    [routerLink]="['/products', productDetails?.productNumber]"
    [queryParams]="{ lnksrc: linkSource, merchRank: carouselPosition, parentApn }"
    (click)="onNavigateToPDP()"
    >{{
      productDetails?.summary?.productDescTxtl | sentenceCase: 'ALL' | translate
    }}</a
  >
  <span *ngIf="navigationDisabled" class="title">{{
    productDetails?.summary?.productDescTxtl | sentenceCase: 'ALL' | translate
  }}</span>
</ng-template>

<ng-template #imageContent>
  <img
    *ngIf="
      (panAppState.online$ | async) === true && !!productDetails?.imageThumbnail
    "
    [attr.data-cy]="'product-' + productDetails?.productNumber + '-image'"
    src="{{ productDetails?.imageThumbnail }}"
    (error)="
      productDetails.imageThumbnail = getAternativeThumbnail(
        productDetails?.imageThumbnail
      )
    "
  />
  <ion-icon
    *ngIf="
      (panAppState.online$ | async) === false || !productDetails?.imageThumbnail
    "
    data-cy="product-card-no-image-icon"
    class="no-image"
    name="restaurant"
  >
  </ion-icon>
</ng-template>

<ng-template #detailImage let-image>
  <a
    *ngIf="!navigationDisabled; else image"
    [routerLink]="['/products', productDetails?.productNumber]"
    [queryParams]="{ lnksrc: linkSource, merchRank: carouselPosition, parentApn }"
    (click)="onNavigateToPDP()"
  >
    <ng-container *ngTemplateOutlet="image"></ng-container>
  </a>
</ng-template>
