import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-customer-pill-popover',
  templateUrl: './customer-pill-popover.component.html',
  styleUrls: ['./customer-pill-popover.component.scss'],
})
export class CustomerPillPopoverComponent {
  @Input() pillDescription = '';

  constructor() {}
}
