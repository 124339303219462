import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';

export const PROFILE_ANALYTICS_ACTIONS = {
  trackPageLoad: createAction(
    '[NgRxClient Analytics] - Track Profile Page Load',
    props<{
      tracking: Tracking;
    }>(),
  ),

  trackUPCLink: createAction(
    '[NgRxClient Analytics] - Outbound link click to UPC',
    props<{
      tracking: Tracking;
    }>(),
  ),

  logoutAllSessions: createAction(
    '[NgRxClient Analytics] - Logout All Sessions',
    props<{
      tracking: Tracking;
    }>(),
  ),

  logoutAllSessionsSuccess: createAction(
    '[NgRxClient Analytics] - Logout All Sessions Success',
    props<{
      tracking: Tracking;
    }>(),
  ),

  clearAppDataTracking: createAction(
    '[NgRxClient Analytics] - Track Clear App Data',
    props<{
      tracking: Tracking;
    }>(),
  ),

  clearAppData: createAction(
    '[NgRxClient Tracing] - Clear App Data Clickstream',
    props<{
      tracking: Tracking;
    }>(),
  ),

  clearAppDataSuccess: createAction(
    '[NgRxClient Tracing] - Clear App Data Clickstream Success',
    props<{
      tracking: Tracking;
    }>(),
  ),

  trackInviteNewUserModalOpen: createAction(
    '[NgRxClient Analytics] - Track Invite New User Modal Open',
    props<{
      tracking: Tracking;
    }>(),
  ),

  trackInviteNewUserModalSubmit: createAction(
    '[NgRxClient Analytics] - Track Invite New User Modal Submit',
    props<{
      tracking: Tracking;
    }>(),
  ),
};
