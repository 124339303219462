import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectWMTTileData } from '@order/selectors/wmt-tile-view-model-selector';
import { DeliveryDetailsState, Order, WMT_ACTIONS } from '@usf/ngrx-order';
import { Observable } from 'rxjs';
import { getDeliveryDetails } from '../../selectors/wmt.selectors';
import { timezoneByCode } from '@shared/constants/timezone-constants';
import dayjs from 'dayjs';
import utc from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment';
import { TimezoneDatePipe } from '@shared/pipes/timezone-date.pipe';

dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
  providedIn: 'root',
})
export class WMTService {
  constructor(
    private store: Store,
    readonly timeZoneDatePipe: TimezoneDatePipe,
  ) {}

  loadDeliveryDetails() {
    this.store.dispatch(WMT_ACTIONS.getDeliveryDetails());
  }

  getDeliveryDetails$(): Observable<DeliveryDetailsState> {
    return this.store.select(getDeliveryDetails);
  }

  getDeliveryDetailsFromOrders$(): Observable<{ wmtTileOrderData: Order }> {
    return this.store.select(selectWMTTileData);
  }

  getDeliveryDate(
    deliveryDate: Date | string,
    isDelivered?: boolean,
    isMobile?: boolean,
  ): string {
    const eta = new Date(deliveryDate);
    const day = this.addLeadingZero(eta.getUTCDate());
    const month = this.addLeadingZero(eta.getUTCMonth() + 1);
    let year = '' + eta.getFullYear();
    let format = `${month}/${day}/${year}`;
    if (isMobile) {
      year = year.slice(-2);
      format = `${month}/${day}`;
      if (isDelivered) {
        format += `,\xa0`;
      }
    }
    return format;
  }

  formatDeliveryDate(deliveryDate: Date | string, isMobile?: boolean): string {
    const eta = new Date(deliveryDate);
    let formattedDate = null;
    if (isMobile) {
      formattedDate = moment(eta).format('MM/DD');
    } else {
      formattedDate = moment(eta).format('MM/DD/YYYY');
    }
    return formattedDate;
  }

  getDeliveryDateWmtTile(
    deliveryDate: Date | string,
    isDelivered?: boolean,
    isMobile?: boolean,
    customerTimeZone?: string,
  ): string {
    try {
      // Order is for today
      const customerTZ =
        timezoneByCode.get(customerTimeZone)?.location || 'America/Chicago';
      const todaysDate = dayjs(new Date())?.tz(
        timezoneByCode.get(customerTZ)?.location,
      );
      let orderIsForToday = false;
      orderIsForToday = dayjs(deliveryDate).isSame(todaysDate, 'day');
      const tzCode = this.translateTZCode(customerTimeZone);
      const deliveryDtm = new Date(
        new Date(deliveryDate).toLocaleString('en-US', {
          timeZone: tzCode,
        }),
      );
      let deliveryDtmFinal = this.formatDeliveryDate(deliveryDtm, isMobile);
      return deliveryDtmFinal;
    } catch (error) {
      console.log('[WmtService] Exception occurred', error);
      return null;
    }
  }

  getDeliveryTime(
    deliveryDtm?: Date | string,
    etaMin?: Date | string,
    etaMax?: Date | string,
    isDelivered?: boolean,
    fromWmt?: boolean,
    customerTimeZone?: string,
  ): string {
    if (!customerTimeZone) customerTimeZone = 'CT';
    const tzCode = this.translateTZCode(customerTimeZone);
    if (etaMin && etaMax) {
      if (isDelivered) {
        let timeString = this.timeZoneDatePipe.transform(
          deliveryDtm,
          'h:mm a',
          customerTimeZone,
        );
        return timeString;
      } else {
        let minEtaString = this.timeZoneDatePipe.transform(
          etaMin,
          'h:mm a',
          customerTimeZone,
        );
        let maxEtaString = this.timeZoneDatePipe.transform(
          etaMax,
          'h:mm a',
          customerTimeZone,
        );
        return `${minEtaString} - ${maxEtaString}`;
      }
    } else if (!!deliveryDtm) {
      let deliveryDateDt: Date;
      if (fromWmt) {
        deliveryDateDt = new Date(
          new Date(deliveryDtm.toString().slice(0, -1)).toLocaleString(
            'en-US',
            {
              timeZone: tzCode,
            },
          ),
        );
      } else {
        deliveryDateDt = new Date(
          new Date(deliveryDtm).toLocaleString('en-US', {
            timeZone: tzCode,
          }),
        );
      }
      if (isDelivered) {
        let timeString = this.getTimeFormatted(deliveryDateDt);
        return timeString;
      } else {
        deliveryDateDt = new Date(
          new Date(deliveryDateDt).toLocaleString('en-US', {
            timeZone: tzCode,
          }),
        );
        let minEta = new Date(new Date(deliveryDateDt.getTime() - 10 * 60000));
        let minEtaString = this.getTimeFormatted(minEta);
        let maxEta = new Date(new Date(deliveryDateDt.getTime() + 10 * 60000));
        let maxEtaString = this.getTimeFormatted(maxEta);
        return `${minEtaString} - ${maxEtaString}`;
      }
    }
  }

  private getTimeFormatted(date: Date): string {
    let hours = date.getHours();
    let mins = date.getMinutes();
    let format = hours >= 12 && hours < 24 ? ' pm' : ' am';
    if (hours > 12) hours = hours - 12;
    if (hours === 0) hours = 12;
    return `${hours}:${this.addLeadingZero(mins)}${format}`;
  }

  private addLeadingZero(number: number) {
    return number > 9 ? number : '0' + number;
  }

  translateTZCode(tzCode: string): string {
    switch (tzCode) {
      case 'ET':
      case 'EST':
      case 'EDT':
        return 'America/New_York';
      case 'CST':
      case 'CDT':
      case 'CT':
        return 'America/Chicago';
      case 'MT':
      case 'MST':
      case 'MDT':
        return 'America/Denver';
      case 'PT':
      case 'PST':
      case 'PDT':
        return 'America/Los_Angeles';
      case 'AST':
      case 'AKST':
      case 'AKDT':
      case 'AKT':
        return 'America/Anchorage';
      case 'HAT':
      case 'HAST':
      case 'HADT':
      case 'HT':
        return 'Pacific/Honolulu';
      default:
        return 'America/Chicago';
    }
  }
}
