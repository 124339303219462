import { SeperatedColumn } from '@shared/models/seperated-columns';
import { ListColumns } from './document-helper-models-and-enums';
import { desiredColumn } from '@shared/models/desired-column';
/**
 *
 * @param seperatedColumns Contains List Data
 * @param numberOfRows The Number of Data Rows
 * @param seperator
 * @returns
 */
export const stringifyCSV = (
  seperatedColumns: SeperatedColumn[],
  numberOfRows: number,
  seperator: any,
): string => {
  // Variable to hold the seperated columns as one string
  let stringified = '';
  // Create the Header Row
  const headerRow = seperatedColumns
    .map(pMap => pMap.columnName)
    .join(seperator);
  // Add the header row to return string
  stringified += headerRow + '\r\n';
  // Add every row of data to return string
  for (let i = 0; i < numberOfRows; i++) {
    const line = seperatedColumns
      .map(pm => (pm.columnValues ? pm.columnValues[i] : pm.singleValue))
      .join(seperator);
    stringified += line + '\r\n';
  }
  return stringified;
};

export const getCSVColumns = (
  includePoductPrices: boolean,
  includePoductNotes: boolean,
  includeProductType: boolean,
  includeProductStatus: boolean,
  isRecentPurchase: boolean,
): desiredColumn[] => {
  const seperatedColumns = [];
  if (!isRecentPurchase) {
    seperatedColumns.push({
      columnName: 'Line Number',
      columnType: ListColumns.lineNumber,
    });
    seperatedColumns.push({
      columnName: 'Group Name',
      columnType: ListColumns.groupName,
    });
  }

  seperatedColumns.push({
    columnName: 'Product Number',
    columnType: ListColumns.productNumber,
  });
  if (includeProductStatus)
    seperatedColumns.push({
      columnName: 'Product Status',
      columnType: ListColumns.productStatus,
    });
  seperatedColumns.push({
    columnName: 'Product Description',
    columnType: ListColumns.productDescription,
  });
  seperatedColumns.push({
    columnName: 'Product Brand',
    columnType: ListColumns.productBrand,
  });
  seperatedColumns.push({
    columnName: 'Product Package Size',
    columnType: ListColumns.productPackageSize,
  });
  seperatedColumns.push({
    columnName: 'Customer Product Number',
    columnType: ListColumns.customerProductNumber,
  });
  if (includePoductPrices)
    seperatedColumns.push({
      columnName: 'Product Price',
      columnType: ListColumns.productPriceWithZero,
    });
  seperatedColumns.push({
    columnName: 'Product UOM',
    columnType: ListColumns.productUom,
  });
  if (includeProductType)
    seperatedColumns.push({
      columnName: 'Product Type',
      columnType: ListColumns.productType,
    });
  if (includePoductNotes)
    seperatedColumns.push({
      columnName: 'Product Note',
      columnType: ListColumns.productNote,
    });

  seperatedColumns.push({
    columnName: 'USF Class Description',
    columnType: ListColumns.usFoodsClassDescription,
  });

  seperatedColumns.push({
    columnName: 'Storage Description',
    columnType: ListColumns.storageDescription,
  });
  return seperatedColumns;
};
