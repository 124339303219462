<div id="ellipsis-options-popover">
  <ion-list>
    <ion-item
      lines="none"
      *ngFor="let button of buttons; let i = index"
      (click)="button?.handler()"
      id="button-{{ i }}"
      class="ellipsis-popover-button"
      [class.non-button]="button.nonButton"
    >
      <ion-label *ngIf="button.cssClass" [class]="button.cssClass">{{
        button.text | translate
      }}</ion-label>
      <ion-label *ngIf="!button.cssClass">{{
        button.text | translate
      }}</ion-label>
    </ion-item>
  </ion-list>
</div>
