import { SeperatedColumn } from '@shared/models/seperated-columns';
import { ListColumns } from './document-helper-models-and-enums';
import { desiredColumn } from '@shared/models/desired-column';
/**
 *
 * @param seperatedColumns Contains List Data
 * @returns
 */
export const stringifyXML = (seperatedColumns: SeperatedColumn[]): string => {
  let stringified = '<?xml version="1.0" encoding="UTF-8"?>\r\n<Product>';

  // Add every row of data to return string that is a product
  for (let i = 0; i < seperatedColumns[0].columnValues.length; i++) {
    let line = '<Order ';
    seperatedColumns.forEach(value => {
      if (value.columnValues[i] === undefined) {
        line += value.columnName + '="" ';
      } else {
        line += value.columnName + '=' + value.columnValues[i] + ' ';
      }
    });
    stringified += line.substring(0, line.length - 1) + '/>';
  }
  return stringified + '\r\n</Product>';
};

export const getXMLColumns = (
  includePoductPrices: boolean,
  includePoductNotes: boolean,
  includeProductType: boolean,
  includeProductStatus: boolean,
  isRecentPurchase: boolean,
): desiredColumn[] => {
  const seperatedColumns = [];
  seperatedColumns.push({
    columnName: 'Brand',
    columnType: ListColumns.productBrand,
  });
  seperatedColumns.push({
    columnName: 'CaseQuantity',
    columnType: ListColumns.caseCount,
  });
  seperatedColumns.push({
    columnName: 'CustomerProductNumber',
    columnType: ListColumns.customerProductNumber,
  });
  seperatedColumns.push({
    columnName: 'Description',
    columnType: ListColumns.productDescription,
  });
  if (includePoductNotes)
    seperatedColumns.push({
      columnName: 'ProductNote',
      columnType: ListColumns.productNote,
    });
  seperatedColumns.push({
    columnName: 'EachQuantity',
    columnType: ListColumns.eachCount,
  });
  if (!isRecentPurchase)
    seperatedColumns.push({
      columnName: 'GroupName',
      columnType: ListColumns.groupName,
    });
  seperatedColumns.push({
    columnName: 'PackSize',
    columnType: ListColumns.productPackageSize,
  });
  if (includePoductPrices)
    seperatedColumns.push({
      columnName: 'Price',
      columnType: ListColumns.productPrice,
    });
  seperatedColumns.push({
    columnName: 'ProductNumber',
    columnType: ListColumns.productNumber,
  });
  if (includeProductType)
    seperatedColumns.push({
      columnName: 'ProductType',
      columnType: ListColumns.productType,
    });
  seperatedColumns.push({
    columnName: 'UOM',
    columnType: ListColumns.productUom,
  });
  return seperatedColumns;
};
