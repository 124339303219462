import { Component, Input, OnInit } from '@angular/core';
import { ImportFile } from '@app/lists/model/import-file-model';
import { IMPORT_LIST_OPTION } from '@app/lists/model/import-list-enum';
import { ListsPageData } from '@app/lists/model/lists-page.model';
import { ListsService } from '@app/lists/services/lists.service';
import { IonNav, ModalController, ViewWillEnter } from '@ionic/angular';
import { PlatformEnum } from '@panamax/app-state';
import { UserKinds } from '@usf/user-types/user';

@Component({
  template: '',
})
export class ImportListModalBaseComponent implements ViewWillEnter {
  @Input() platform: PlatformEnum;
  @Input() listData?: ListsPageData;
  readonly UserKinds = UserKinds;
  platformRef = PlatformEnum;
  selectedOption: IMPORT_LIST_OPTION;
  listStatus = 'Public';
  optionRef = IMPORT_LIST_OPTION;
  newListName: string;
  listNameExists: boolean;
  file: ImportFile;

  constructor(
    protected nav: IonNav,
    private modalController: ModalController,
    private listsService: ListsService,
  ) {}

  ionViewWillEnter(): void {
    if (this.nav.rootParams?.platform)
      this.platform = this.nav.rootParams?.platform;
    if (this.nav.rootParams?.selectedOption)
      this.selectedOption = this.nav.rootParams?.selectedOption;
    if (this.nav.rootParams?.listData)
      this.listData = this.nav.rootParams?.listData;
    if (this.nav.rootParams?.listNameExists)
      this.listNameExists = this.nav.rootParams?.listNameExists;
    if (this.nav.rootParams?.listStatus)
      this.listStatus = this.nav.rootParams?.listStatus;
    if (this.nav.rootParams?.file) this.file = this.nav.rootParams?.file;
    if (this.nav.rootParams?.newListName)
      this.newListName = this.nav.rootParams?.newListName;
  }

  async submit() {
    this.getListNameExists();
    if (!this.listNameExists) {
      this.listsService.importList(
        this.newListName.trim(),
        this.file.rows,
        this.listStatus.toUpperCase(),
      );
      await this.modalController.dismiss();
    }
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }

  async getListTemplate() {
    this.listsService.getListTemplate();
  }

  onSelect(option: IMPORT_LIST_OPTION) {
    this.selectedOption = option;
  }

  updateFile = async (file: ImportFile) => {
    this.file = file;
  };

  getListNameExists() {
    this.listNameExists = this.listsService.listNameExists(
      this.listData,
      this.newListName.trim(),
    );
  }
}
