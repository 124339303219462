import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { MessageTypeEnum } from 'src/app/ngrx-message/constants/messageTypeEnum';
import { MessageStoreService } from 'src/app/ngrx-message/services/message/message-store.service';
import { OrderService } from 'src/app/order/services/order.service';
import { PanAppState, Tracking } from '@panamax/app-state';
import { Subscription, combineLatest } from 'rxjs';
import { NetworkStatus } from '../models/network-status.model';
import { Message } from 'src/app/ngrx-message/models/client/message';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { OrderHeader, OrderItem } from '@usf/ngrx-order';
import { Product } from '../models/product.model';
import { OrderViewModel } from 'src/app/order/models/order-view-model';
import { selectAddToOrderModel } from '@order/selectors/add-to-order/add-to-order.selectors';

@Injectable({
  providedIn: 'root',
})
export class OrderItemService {
  networkStatus: NetworkStatus['isOnline'];
  networkStatusSub: Subscription;

  constructor(
    private store: Store,
    private orderService: OrderService,
    private messageStoreService: MessageStoreService,
    private panAppState: PanAppState,
  ) {
    this.networkStatusSub = this.panAppState.online$.subscribe(
      status => (this.networkStatus = status),
    );
  }

  changeQuantity(
    productNumber: number,
    newQuantity: number,
    each: boolean,
    trackingData?: Tracking,
    disableAddToOrderModal?: boolean,
    getDirectShippingSavings?: boolean,
  ): void {
    if (each) {
      this.changeMultiQuantity(
        productNumber,
        undefined,
        newQuantity,
        trackingData,
        !!disableAddToOrderModal,
        !!getDirectShippingSavings,
      );
    } else {
      this.changeMultiQuantity(
        productNumber,
        newQuantity,
        undefined,
        trackingData,
        !!disableAddToOrderModal,
        !!getDirectShippingSavings,
      );
    }
  }

  changeMultiQuantity(
    productNumber: number,
    newCaseQuantity: number,
    newEachQuantity: number,
    trackingData?: Tracking,
    disableAddToOrderModal?: boolean,
    getDirectShippingSavings?: boolean,
  ): void {
    if (!this.networkStatus) {
      this.sendOfflineWarning();
      return;
    }

    combineLatest([
      this.store.select(selectAddToOrderModel),
      this.orderService.loadOrderViewModel$(),
    ])
      .pipe(take(1))
      .subscribe(([addToOrderVM, vm]) => {
        const isValidEachQt =
          !isNaN(newEachQuantity) &&
          newEachQuantity >= 0 &&
          newEachQuantity <= 999;
        const isValidCaseQt =
          !isNaN(newCaseQuantity) &&
          newCaseQuantity >= 0 &&
          newCaseQuantity <= 999;
        const noOrderAndQty =
          !vm?.orderHeader?.orderId?.length &&
          (isValidCaseQt || isValidEachQt) &&
          !!addToOrderVM?.length;

        let orderItemEntity = {
          ...vm.orderItemState.entities[productNumber],
        };

        if (!orderItemEntity.hasOwnProperty('productNumber')) {
          // Create New OrderItemEntity
          orderItemEntity = {
            eachesOrdered: {
              currentValue: 0,
              previousValue: 0,
              loading: false,
              error: null,
            },
            sequence: 1,
            unitsOrdered: {
              currentValue: 0,
              previousValue: 0,
              loading: false,
              error: null,
            },
            productNumber,
          } as OrderItem;
        }
        if (isValidEachQt) {
          if (
            (orderItemEntity.eachesOrdered.currentValue || 0) ===
            newEachQuantity
          ) {
            return;
          }
          orderItemEntity.eachesOrdered = {
            ...orderItemEntity.eachesOrdered,
            previousValue:
              vm.orderItemState.entities[productNumber]?.eachesOrdered
                ?.currentValue,
            currentValue: newEachQuantity,
            loading: true,
            error: null,
          };

          if (!isValidCaseQt) {
            orderItemEntity.unitsOrdered = {
              ...orderItemEntity.unitsOrdered,
              previousValue:
                vm.orderItemState.entities[productNumber]?.unitsOrdered
                  ?.currentValue,
              currentValue:
                vm.orderItemState.entities[productNumber]?.unitsOrdered
                  ?.currentValue,
              loading: false,
              error: null,
            };
          }
        }
        if (isValidCaseQt) {
          orderItemEntity.unitsOrdered.currentValue;
          newCaseQuantity;
          if (
            (orderItemEntity.unitsOrdered.currentValue || 0) === newCaseQuantity
          ) {
            return;
          }

          orderItemEntity.unitsOrdered = {
            ...orderItemEntity.unitsOrdered,
            previousValue: orderItemEntity.unitsOrdered.currentValue,
            currentValue: newCaseQuantity,
            loading: true,
            error: null,
          };

          if (!isValidEachQt) {
            orderItemEntity.eachesOrdered = {
              ...orderItemEntity.eachesOrdered,
              previousValue: orderItemEntity.eachesOrdered.currentValue,
              currentValue: orderItemEntity.eachesOrdered.currentValue,
              loading: false,
              error: null,
            };
          }
        }

        const orderHeader: OrderHeader = vm.orderHeader;
        const orderItems: OrderItem[] = [];
        for (const id of vm.orderItemState?.ids) {
          orderItems.push(vm.orderItemState.entities[id]);
        }

        const pNum = orderItemEntity.productNumber;
        const productInfo = vm.productsMap?.get(pNum);
        const isSpecialVendor = !!productInfo?.summary?.properties?.has(
          ProductPropertiesEnum.specialVendor,
        );

        const isModifyingCases =
          isValidCaseQt &&
          newCaseQuantity !==
            vm.orderItemState.entities[productNumber]?.unitsOrdered
              ?.currentValue;
        const updateOrderItem = {
          ...orderItemEntity,
          quantityAccepted:
            vm.orderHeader.tandemOrderNumber &&
            !orderItemEntity.submittedQuantityAccepted &&
            isModifyingCases
              ? false
              : orderItemEntity.quantityAccepted,
          purchasedFromVendor: productInfo?.inventory?.purchaseFromVendor,
          vendorLeadTime: productInfo?.inventory?.vendorLeadTime,
          specialVendor:
            !!productInfo?.inventory?.productStatus &&
            !(
              ['0', '2', '3', '4', '8', '9'].includes(
                productInfo?.inventory?.productStatus,
              ) ||
              (productInfo?.inventory?.productStatus === '1' &&
                !isSpecialVendor)
            ),
        };
        this.orderService.changeQuantity(
          orderHeader,
          orderItems,
          updateOrderItem,
          noOrderAndQty,
          trackingData,
          !!disableAddToOrderModal,
        );
        if (getDirectShippingSavings) {
          this.orderService.getDirectShippingSavings(
            { orderHeader, orderItems },
            updateOrderItem,
            false,
            !!disableAddToOrderModal,
          );
        }
      });
  }

  updateOrderItemSellerPrice(
    productNumber: number,
    unitPrice: number,
    unitPriceOverride: number,
  ) {
    let vm: OrderViewModel;
    this.orderService
      .loadOrderViewModel$()
      .pipe(take(1))
      .subscribe(viewModel => {
        vm = viewModel;
        if (!vm.orderItemState.entities[productNumber]) {
          return;
        }

        let orderItemEntity = {
          ...vm.orderItemState.entities[productNumber],
        };

        orderItemEntity.dirtyFlag = true;

        const orderHeader: OrderHeader = { ...vm.orderHeader };
        const orderItems: OrderItem[] = [];
        for (const id of vm.orderItemState.ids) {
          orderItems.push(vm.orderItemState.entities[id]);
        }
        const updateOrderItem = {
          ...orderItemEntity,
          unitPrice,
          unitPriceOverride,
        };
        orderHeader.priceChangeFlag = true;
        this.orderService.updateOrder(orderHeader, orderItems, updateOrderItem);
      });
  }

  /**
   *
   * @param subOutProduct Product being subbed out
   * @param subInProduct Product being subbed in
   */
  substituteProducts(subOutProduct: Product, subInProduct: Product): void {
    if (!!subOutProduct?.productNumber && !!subInProduct?.productNumber) {
      this.orderService
        .loadOrderViewModel$()
        .pipe(take(1))
        .subscribe((vm: OrderViewModel) => {
          const subOutOrderItem =
            vm.orderItemState.entities[subOutProduct.productNumber];

          if (!!subOutOrderItem?.productNumber) {
            // Add quantities to subIn product
            if (subOutOrderItem?.unitsOrdered) {
              this.changeQuantity(
                subInProduct.productNumber,
                subOutOrderItem.unitsOrdered.currentValue,
                false,
              );
            }
            if (subOutOrderItem?.eachesOrdered) {
              this.changeQuantity(
                subInProduct.productNumber,
                subOutOrderItem.eachesOrdered.currentValue,
                true,
              );
            }
            // Zero out quantities to subOut product
            if (
              subOutOrderItem?.unitsOrdered?.currentValue ||
              subOutOrderItem?.eachesOrdered?.currentValue
            ) {
              this.changeQuantity(subOutOrderItem.productNumber, 0, false);
              this.changeQuantity(subOutOrderItem.productNumber, 0, true);
            }
          }
        });
    }
  }

  private sendOfflineWarning() {
    this.messageStoreService.upsertMessage({
      stack: null,
      watermark: new Date().toUTCString(),
      read: false,
      type: MessageTypeEnum.warning,
      display:
        'You are offline. Please connect to the internet before updating your order.',
    } as Message);
  }
}
