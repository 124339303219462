/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[tabletTouchMove]',
})
export class TabletTouchMoveDirective {
  constructor() {}

  // Handle the touchmove event and stop propagation
  @HostListener('touchmove', ['$event'])
  innerModalScrolled(event: any) {
    event.stopPropagation();
  }
}
