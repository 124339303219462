import { Component, OnInit } from '@angular/core';
import { ImportListModalBaseComponent } from '../import-list-modal-base.component';
import { IonNav, ModalController } from '@ionic/angular';
import { ListsService } from '@app/lists/services/lists.service';
import { IMPORT_LIST_OPTION } from '@app/lists/model/import-list-enum';

@Component({
  selector: 'app-import-list-modal-mobile',
  templateUrl: './import-list-modal-mobile.component.html',
  styleUrls: ['./import-list-modal-mobile.component.scss'],
})
export class ImportListModalMobileComponent extends ImportListModalBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    listsService: ListsService,
  ) {
    super(nav, modalController, listsService);
    this.selectedOption = IMPORT_LIST_OPTION.noSelection;
  }

  goBack() {
    this.nav.getPrevious().then(vc => {
      vc.params.platform = this.platform;
      (vc.params.platform = this.platform),
        (vc.params.selectedOption = IMPORT_LIST_OPTION.noSelection),
        (vc.params.listData = this.listData),
        (vc.params.listNameExists = this.listNameExists),
        (vc.params.file = this.file),
        (vc.params.newListName = this.newListName);
      vc.params.listStatus = this.listStatus;
      this.nav.pop();
    });
  }

  goForward(option: IMPORT_LIST_OPTION) {
    this.nav.push(ImportListModalMobileComponent, {
      platform: this.platform,
      selectedOption: option,
      listData: this.listData,
      listNameExists: this.listNameExists,
      listStatus: this.listStatus,
      file: this.file,
      newListName: this.newListName,
    });
  }
}
