<ion-header
  class="ion-no-border simple-action-modal_header"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="
        (platformService?.isTouchExperience$() | async) === false &&
        platformService?.platformType === platformService?.platformEnum?.desktop
      "
      slot="end"
    >
      <ion-button
        id="close-modal-button"
        data-cy="simple-action-close-modal-icon"
        (click)="dismissModal(false)"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  data-cy="modal-prompt-area-text"
  class="simple-action-modal_content"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <div
    class="content"
    [ngClass]="
      (platformService?.isTouchExperience$() | async)
        ? platformService?.platformType ===
          platformService?.platformEnum?.tablet
          ? 'ion-content-modal-tablet'
          : 'ion-content-modal-mobile'
        : 'ion-content-modal-desktop'
    "
  >
    <ion-icon
      *ngIf="withIcon"
      data-cy="simple-action-modal-no-image-icon"
      [ngClass]="[iconClass]"
      src="../../../../../assets/images/alert-yellow.svg"
      color="Warning"
    ></ion-icon>

    <div>
      <h3 data-cy="simple-action-title-text" [ngClass]="[titleClass]">
        {{ title }}
      </h3>

      <h4
        *ngIf="!!subtitle"
        data-cy="simple-action-subtitle-text"
        [ngClass]="[subtitleClass]"
      >
        {{ subtitle }}
      </h4>

      <p data-cy="simple-action-text" [ngClass]="[contentClass]">
        {{ content }}
      </p>

      <p class="note" data-cy="modal-note-text-area" *ngIf="note">
        {{ 'i18n.common.note' | translate }}&nbsp;
        <span>{{ note }}</span>
      </p>
    </div>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$() | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="no-modal-btn"
        data-cy="simple-action-modal-left-button"
        [ngClass]="[
          leftButtonClass,
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        ]"
        (click)="dismissModal(false)"
      >
        {{ leftButtonText | translate }}
      </ion-button>
      <ion-button
        id="yes-modal-btn"
        data-cy="simple-action-modal-right-button"
        [ngClass]="[
          rightButtonClass,
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        ]"
        (click)="dismissModal(true)"
      >
        {{ rightButtonText | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
