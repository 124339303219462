import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import { Order } from '@usf/ngrx-order';

export const ORDER_TRACING_ACTIONS = {
  traceResolveButtonClickedOnHomepage: createAction(
    '[NGRXTrace - Order] Resolve Button Clicked on Home Page',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceGoToCartClickedOnHomepage: createAction(
    '[NGRXTrace - Order] Go To Cart Clicked on Home Page',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCreateNewOrderFromClient: createAction(
    '[NGRXTrace - Order] Create New Order Button Click',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCreateNewOrder: createAction(
    '[NGRXTrace - Order] Create New Order',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCreateNewOrderSuccess: createAction(
    '[NGRXTrace - Order] Create New Order Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCreateNewOrderFail: createAction(
    '[NGRXTrace - Order] Create New Order Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitIpOrderSuccessWithExceptions: createAction(
    '[NGRXTrace - Order] Track Submit Order API Response Failure',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitEditOrderSuccessWithExceptions: createAction(
    '[NGRXTrace - Order] Track Submit Edit Order API Response Failure',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceAddToOrderSuccessWithExceptions: createAction(
    '[NGRXTrace - Order] Track Add to Order Failure',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceAddToOrderFailure: createAction(
    '[NGRXTrace - Order] Add to Order Failure',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceGetOrderDetailClick: createAction(
    '[NGRXTrace - Order] Get Order Detail Button Click',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceGetOrderDetail: createAction(
    '[NGRXTrace - Order] Get Order Detail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceGetOrderDetailSuccess: createAction(
    '[NGRXTrace - Order] Get Order Detail Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceGetOrderDetailFail: createAction(
    '[NGRXTrace - Order] Get Order Detail Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceOrderPageLoad: createAction(
    '[NGRXTrace - Order] Review Order Page Load',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitOrderStart: createAction(
    '[NGRXTrace - Order] Track Submit Order Start',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitOrderApiResponse: createAction(
    '[NGRXTrace - Order] Track Submit Order API Response',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitOrderApiResponseSuccess: createAction(
    '[NGRXTrace - Order] Track Submit Order API Response Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitEditedOrderApiResponse: createAction(
    '[NGRXTrace - Order] Track Edit Submitted Order API Response',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitEditedOrderApiResponseSuccess: createAction(
    '[NGRXTrace - Order] Track Edit Submitted Order API Response Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitOrderApiResponseFail: createAction(
    '[NGRXTrace - Order] Track Submit Order API Response Failure',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCancelIPOrder: createAction(
    '[NGRXTrace - Order] Cancel IP Order',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCancelIPOrderSuccess: createAction(
    '[NGRXTrace - Order] Cancel IP Order Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCancelIPOrderFail: createAction(
    '[NGRXTrace - Order] Cancel In Progress Order API Response Failure',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCancelSubmittedOrder: createAction(
    '[NGRXTrace - Order] Cancel Submitted Order',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCancelSubmittedOrderSuccess: createAction(
    '[NGRXTrace - Order] Cancel Submitted Order Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCancelSubmittedOrderFail: createAction(
    '[NGRXTrace - Order] Cancel Submitted Order API Response Failure',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceDyfBannerClick: createAction(
    '[NGRXTrace - Order] DYF Click',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceInspiredPicksBannerClick: createAction(
    '[NGRXTrace - Order] Inspired Picks Click',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceInspiredPicksCall: createAction(
    '[NGRXTrace - Order] Inspired Picks Call',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceInspiredPicksCallSuccess: createAction(
    '[NGRXTrace - Order] Inspired Picks Call Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceInspiredPicksCallFail: createAction(
    '[NGRXTrace - Order] Inspired Picks Call Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceDirectShippingSavingsCall: createAction(
    '[NGRXTrace - Order] Direct Shipping Savings Call',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceDirectShippingSavingsCallSuccess: createAction(
    '[NGRXTrace - Order] Direct Shipping Savings Call Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceDirectShippingSavingsCallFail: createAction(
    '[NGRXTrace - Order] Direct Shipping Savings Call Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  nextDeliveryDateCall: createAction(
    '[NGRXTrace - Order] Next Delivery Date Call',
    props<{
      tracking: Tracking;
    }>(),
  ),
  nextDeliveryDateCallSuccess: createAction(
    '[NGRXTrace - Order] Next Delivery Date Call Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  nextDeliveryDateCallFail: createAction(
    '[NGRXTrace - Order] Next Delivery Date Call Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceGetRecentOrdersCall: createAction(
    '[NGRXTrace - Order] Recent Orders Call',
    props<{
      tracking: Tracking;
    }>(),
  ),

  traceGetRecentOrdersCallSuccess: createAction(
    '[NGRXTrace - Order] Recent Orders Call Success',
    props<{
      tracking: Tracking;
    }>(),
  ),

  traceGetRecentOrdersCallFailure: createAction(
    '[NGRXTrace - Order] Recent Orders Call Failure',
    props<{
      tracking: Tracking;
    }>(),
  ),

  traceOrderCardClickedOnMyOrders: createAction(
    '[NGRXTrace - Order] Order Card Clicked on My Orders',
    props<{
      tracking: Tracking;
    }>(),
  ),

  traceOrderCardClickedOnOrderStatus: createAction(
    '[NGRXTrace - Order] Order Card Clicked on Order Status',
    props<{
      tracking: Tracking;
    }>(),
  ),

  traceEditOrderButtonClickedOnMyOrders: createAction(
    '[NGRXTrace - Order] Edit Order Button Clicked on My Orders',
    props<{
      tracking: Tracking;
    }>(),
  ),

  traceEditOrderButtonClickedOnOrderStatus: createAction(
    '[NGRXTrace - Order] Edit Order Button Clicked on Order Status',
    props<{
      tracking: Tracking;
    }>(),
  ),

  traceGetCustomerOrdersCall: createAction(
    '[NGRXTrace - Order] Customer Orders Call',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceGetCustomerOrdersCallSuccess: createAction(
    '[NGRXTrace - Order] Customer Orders Call Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceGetCustomerOrdersCallFail: createAction(
    '[NGRXTrace - Order] Customer Orders Call Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),

  traceAddToOrder: createAction(
    '[NGRXTrace - Order] Track Add To Order',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceAddToOrderSuccess: createAction(
    '[NGRXTrace - Order] Track Add To Order Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceAddToOrderFail: createAction(
    '[NGRXTrace - Order] Track Add To Order Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceOrderCprOverrideCall: createAction(
    '[NGRXTrace - Order] Order CPR Override Call',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceOrderCprOverrideCallSuccess: createAction(
    '[NGRXTrace - Order] Order CPR Override Call Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceOrderCprOverrideCallFail: createAction(
    '[NGRXTrace - Order] Order CPR Override Call Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceOrderCprManualOverrideCall: createAction(
    '[NGRXTrace - Order] Order CPR Manual Override Call',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceOrderCprManualOverrideCallSuccess: createAction(
    '[NGRXTrace - Order] Order CPR Manual Override Call Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceOrderCprManualOverrideCallFail: createAction(
    '[NGRXTrace - Order] Order CPR Manual Override Call Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceAddToExistingOrderModal: createAction(
    '[NGRXTrace - Order] Add To Existing Order Modal',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceAddToOrderModalCreate: createAction(
    '[NGRXTrace - Order] Add To Order Modal Create',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceAddToOrderModalCancel: createAction(
    '[NGRXTrace - Order] Add To Order Modal Cancel',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackSubmitOrderApiResponseSuccess: createAction(
    '[NGRXTrace - Order] Track Submit Order API Response Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackSubmitOrderWebsocket: createAction(
    '[NGRXTrace - Order] Track Submit Order Websocket Response',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceModifyLinkClickOnHomePageTile: createAction(
    '[NGRXTrace - Order] Edit Order Button Clicked on Home Page',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceOrderCardClickedOnPastOrders: createAction(
    '[NGRXTrace - Order] Order Card Clicked on Past Orders',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCancelCopyOrderModal: createAction(
    '[NGRXTrace - Order] Close Clicked on Copy Order Modal',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCopyOrderClickedOnModal: createAction(
    '[NGRXTrace - Order] Copy Order Clicked on Modal',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCancelSubmittedOrderWebsocketSuccess: createAction(
    '[NGRXTrace - Order] Cancel Submitted Order Websocket Response Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceCancelSubmittedOrderWebsocketFail: createAction(
    '[NGRXTrace - Order] Cancel Submitted Order Websocket Response Failure',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceTransmitOrderFromClient: createAction(
    '[NGRXTrace - Order] Track Order Transmit',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceQEProductSearch: createAction(
    '[NGRXTrace - Order] Track QE Product Search',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceQECustomerProductSearch: createAction(
    '[NGRXTrace - Order] Track QE Customer Product Search',
    props<{ tracking: Tracking }>(),
  ),
  traceConfirmUQEButtonClickedOnOrderPage: createAction(
    '[NGRXTrace - Order] Track UQE Confirmed',
    props<{
      tracking: Tracking;
    }>(),
  ),
  endOrderSubmissionTrace: createAction(
    '[NGRXTrace - Order] End Submit Order Trace',
    props<{ order: Order; isWebsocket: boolean }>(),
  ),

  traceGetVptOrderCall: createAction(
    '[NGRXTrace - Order] Get VPT Order Call',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceGetVptOrderCallSuccess: createAction(
    '[NGRXTrace - Order] Get VPT Order Call Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceGetVptOrderCallFail: createAction(
    '[NGRXTrace - Order] Get VPT Order Call Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),

  traceSubmitVptOrderStart: createAction(
    '[NGRXTrace - Order] Track Submit VPT Order Start',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitVptOrderWebsocketResponse: createAction(
    '[NGRXTrace - Order] Track Submit VPT Order Websocket Response',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitVptOrderApiResponseSuccess: createAction(
    '[NGRXTrace - Order] Track Submit VPT Order API Response Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitVptOrderApiResponseFail: createAction(
    '[NGRXTrace - Order] Track Submit VPT Order API Response Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  endVptOrderSubmissionTrace: createAction(
    '[NGRXTrace - Order] End Submit VPT Order Trace',
    props<{ order: Order; isWebsocket: boolean }>(),
  ),
};
