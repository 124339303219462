export enum ListColumns {
  customerProductNumber,
  caseCount,
  eachAndCasePrice,
  eachCount,
  groupName,
  lineNumber,
  onHand,
  productBrand,
  productDescription,
  productNote,
  productNumber,
  productPackageSize,
  productPrice,
  productPriceWithZero,
  productStatus,
  productType,
  productUom,
  storageDescription,
  usFoodsClassDescription,
  imageStatus,
  imageType,
  netWeight,
  catchWeightFlag,
  breakable,
}

export enum InventoryColumns {
  lineNumber,
  glCode,
  groupName,
  productNumber,
  productDescription,
  productStatus,
  productType,
  productBrand,
  productPackageSize,
  unitDescription,
  unitByCase,
  caseCount,
  eachCount,
  casePrice,
  productCost,
  productUom,
  extendedCost,
  imageType,
  imageStatus,
  vendor,
  unitPrice,
  freehandCount,
  firstCasePrice,
  firstEachPrice,
  secondCasePrice,
  secondEachPrice,
  casePriceChange,
  casePricePercentChange,
  inventoryOnePrices,
  inventoryTwoPrices,
  casePriceUom,
  unitPriceUom,
  casePriceWithoutUom,
  unitPriceWithoutUom,
}

export enum PaymentActivityColumns {
  customerName,
  customerNumber,
  documentDate,
  primaryTransactionNumber,
  primaryTransactionType,
  secondaryTransactionNumber,
  secondaryTransactionType,
  referenceNumber,
  paymentMethod,
  paidAmount,
  paymentDate,
  access,
}

export enum AllInvoicesColumns {
  customerName,
  customerNumber,
  primaryTransactionType,
  primaryTransactionNumber,
  secondaryTransactionType,
  secondaryTransactionNumber,
  customerPO,
  dateIssued,
  amount,
  amountDue,
  dueDate,
  status,
}

export interface ImageIconPair {
  icon: string;
  image: HTMLImageElement;
}

export interface ProductRowsAndNeededImages {
  productRowsByGroup: string[][][];
  imageKeys: Set<string>;
}
