import { createSelector } from '@ngrx/store';
import { PlatformEnum } from '@panamax/app-state';
import { Customer } from '@usf/customer-types';
import {
  IMasterListState,
  selectMasterListGroupState,
  selectMasterListsState,
  IMasterListGroupState,
  MasterListState,
  IMasterListItemProductState,
  selectMasterListItemState,
  selectMasterListSearchSortFilterOptions,
  ListSearchSortFilterOptions,
  masterListItemsLoaded,
  MasterListGroupState,
  MasterListItemProductState,
} from '@usf/ngrx-list';
import {
  orderHeaderSelectors,
  OrderHeaderState,
  orderItemSelectors,
  OrderItemState,
} from '@usf/ngrx-order';
import { Product } from '@usf/product-types';
import { selectedCustomer } from '../../../../ngrx-customer/store';
import {
  appStateForProductsSelector,
  productWithAlternativesSelector,
} from '../../../../shared/selectors/product.selectors';
import { MasterListViewModel } from '../model/master-list-view.model';
import {
  createMasterListGroupKey,
  MasterListSelectorHelpers,
} from './helpers/master-list.selectors.helpers';
import { selectAllFeaturesEnabled } from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { MasterListGroupItems } from '../model/master-list-group-items.model';
import { IMslProductState, selectMslProductState } from '@usf/ngrx-product';
import { AppStateForProduct } from '../../../../shared/models/app-state-for-product';
import {
  getListSearchSortFilterOptions,
  getSortAndFilterCount,
} from '../../../shared/list-detail-management/selectors/helpers/list-detail-management.selectors.helper';
import { selectUserPreferences } from '../../../../user/store';
import { Preferences } from '@usf/user-types/user-preference';
import {
  inventoryGroupScaffold,
  inventoryItemScaffold,
  inventoryScaffold,
} from '../../../../inventory/models/inventory-view.model';

export const selectMasterListHeader = (listId: number) =>
  createSelector(
    selectMasterListsState,
    (masterListDict: IMasterListState) => masterListDict.entities[listId],
  );

export const selectMasterListGroup = (listId: number, listGroupId: number) =>
  createSelector(
    selectMasterListGroupState,
    (masterListGroupState: IMasterListGroupState) =>
      masterListGroupState.entities[
        createMasterListGroupKey(listId, listGroupId)
      ],
  );

export const selectSortAndFiltersForMasterList = (listId: number) =>
  createSelector(
    selectMasterListSearchSortFilterOptions,
    selectUserPreferences,
    (
      listSearchSortFilterOptions: ListSearchSortFilterOptions,
      userPreferences: Preferences,
    ) => {
      const listKey = 'ML-' + listId;
      let preferencesForThisList;

      if (userPreferences?.preferences?.sortAndFilterApplied) {
        preferencesForThisList =
          userPreferences?.preferences?.sortAndFilterApplied[listKey];
      }

      return getListSearchSortFilterOptions(
        listSearchSortFilterOptions,
        preferencesForThisList,
      );
    },
  );

export const selectMasterListViewModel = (
  listId: number,
  platformType: PlatformEnum,
) =>
  createSelector(
    selectedCustomer,
    selectListWithGroupAndItemState(listId),
    productWithAlternativesSelector,
    orderItemSelectors.selectOrderItemState,
    orderHeaderSelectors.selectOrderContextState,
    selectSortAndFiltersForMasterList(listId),
    selectAllFeaturesEnabled([FEATURES.split_global_download_print]),
    (
      selectedCustomer: Customer,
      masterListWithGroupAndItemState: {
        masterList: MasterListState;
        masterListGroupState: IMasterListGroupState;
        masterListItemState: IMasterListItemProductState;
      },
      products: Map<number, Product>,
      orderItemState: OrderItemState,
      orderHeaderState: OrderHeaderState,
      listSearchSortFilterOptions: ListSearchSortFilterOptions,
      downloadPrintFlag: boolean,
    ): MasterListViewModel => {
      const {
        items,
        secondariesExist,
        showingAllSecondaries,
        itemHeights,
        productCardIds,
        productClasses,
        groupNames,
      } = MasterListSelectorHelpers.createMasterListViewModel(
        masterListWithGroupAndItemState.masterList,
        masterListWithGroupAndItemState.masterListGroupState,
        masterListWithGroupAndItemState.masterListItemState,
        products,
        platformType,
        listSearchSortFilterOptions,
      );
      const showAlternateProducts =
        secondariesExist && selectedCustomer.restrictToOG !== 'M';
      const viewModel: MasterListViewModel = {
        orderItemTallies: orderItemState?.tallies,
        orderHeader: orderHeaderState?.entities[orderHeaderState?.ids[0]],
        list: masterListWithGroupAndItemState.masterList,
        selectedCustomer: selectedCustomer,
        items,
        secondariesExist,
        showingAllSecondaries,
        itemHeights,
        productCardIds,
        productClasses,
        groupNames,
        sortAndFilterCount: getSortAndFilterCount(listSearchSortFilterOptions),
        showEllipsis:
          (downloadPrintFlag &&
            masterListWithGroupAndItemState?.masterList?.primaryCount > 0) ||
          showAlternateProducts,
        downloadPrintFlag,
        listSearchSortFilterOptions,
      } as MasterListViewModel;
      return viewModel;
    },
  );

export const selectMasterListItemsOnList = (listId: number) =>
  createSelector(selectMasterListItemState, masterListItemState => {
    var returnArray = [];
    masterListItemState.ids.forEach(id => {
      const masterListItem = masterListItemState.entities[id];
      if (masterListItem.listId === listId) {
        returnArray.push(masterListItem);
      }
    });
    return returnArray;
  });

export const isMasterListAndProductDataLoaded = (listId: string) =>
  createSelector(
    selectMasterListItemsOnList(Number(listId)),
    productWithAlternativesSelector,
    masterListItemsLoaded(),
    (itemState, summaryState, mlItemsLoaded) => {
      if (!mlItemsLoaded) {
        return false;
      }

      let isLoaded = true;
      itemState.forEach(item => {
        if (!summaryState.get(item?.productNumber)) {
          isLoaded = false;
        }
      });

      return isLoaded;
    },
  );

export const isMasterListPricingDataLoaded = (listId: string) =>
  createSelector(
    selectMasterListItemsOnList(Number(listId)),
    productWithAlternativesSelector,
    masterListItemsLoaded(),
    (itemState, summaryState, mlItemsLoaded) => {
      if (!mlItemsLoaded) {
        return false;
      }

      let isLoaded = true;
      itemState.forEach(item => {
        const product = summaryState.get(item?.productNumber);
        if (
          !product ||
          product?.pricing?.loading ||
          !product?.pricing?.productNumber
        ) {
          isLoaded = false;
        }
      });

      return isLoaded;
    },
  );

export const selectListWithGroupAndItemState = (listId: number) =>
  createSelector(
    selectMasterListHeader(listId),
    selectMasterListGroupState,
    selectMasterListItemState,
    (
      masterList: MasterListState,
      masterListGroupState: IMasterListGroupState,
      masterListItemState: IMasterListItemProductState,
    ): MasterListGroupItems => {
      return {
        masterList,
        masterListGroupState,
        masterListItemState,
      };
    },
  );

export const selectMasterListStatesAreLoaded = () =>
  createSelector(
    selectMasterListsState,
    selectMasterListGroupState,
    selectMasterListItemState,
    (
      masterListState: IMasterListState,
      masterListGroupState: IMasterListGroupState,
      masterListItemState: IMasterListItemProductState,
    ): boolean => {
      return (
        masterListState.loaded &&
        masterListGroupState.loaded &&
        masterListItemState.loaded
      );
    },
  );

export const mslProductLoadCheck = createSelector(
  selectMslProductState,
  appStateForProductsSelector,
  (
    mslProductState: IMslProductState,
    appStateForProduct: AppStateForProduct,
  ): boolean => {
    return appStateForProduct?.isMslRestricted ? mslProductState?.loaded : true;
  },
);

export const selectMasterListInventoryScaffold = (listId: number) =>
  createSelector(
    selectMasterListHeader(listId),
    selectMasterListGroupState,
    selectMasterListItemState,
    (
      list: MasterListState,
      groups: IMasterListGroupState,
      itemsState: IMasterListItemProductState,
    ): inventoryScaffold => {
      const inventoryGroups: inventoryGroupScaffold[] = [];
      const productsToLoad: Set<number> = new Set();
      list?.listGroupIds?.forEach(listGroupKey => {
        const groupKey = [list.listId, listGroupKey].join('-');
        const group = groups.entities[groupKey];
        if (group && group.groupName) {
          const inventoryGroupItems: inventoryItemScaffold[] = [];

          if (group?.listItemIds && group?.listItemIds.length > 0) {
            group?.listItemIds?.forEach(listItemId => {
              const itemKey = [
                group.listId,
                group.listGroupId,
                listItemId,
              ].join('-');
              const itemProductKeys =
                itemsState.itemKeyProductKeysDict[itemKey];
              if (itemProductKeys && itemProductKeys.length > 0) {
                const item: MasterListItemProductState =
                  itemsState.entities[itemProductKeys[0]];
                inventoryGroupItems.push({
                  productNumber: item.productNumber,
                  nonUSF: false,
                } as inventoryItemScaffold);
                productsToLoad.add(item.productNumber);
              }
            });
          }
          const groupWithItems: inventoryGroupScaffold = {
            groupName: group.groupName,
            items: inventoryGroupItems,
          };
          inventoryGroups.push(groupWithItems);
        }
      });

      return {
        inventoryGroups,
        productsToLoad: Array.from(productsToLoad),
      } as inventoryScaffold;
    },
  );
