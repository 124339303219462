import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListAnalyticsHelperService } from '@app/lists/services/list-analytics-helper.service';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import { Message } from '@app/ngrx-message/models/client/message';
import { MessageStoreService } from '@app/ngrx-message/services/message/message-store.service';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { downloadPath } from '@shared/constants/document';
import { SentenceCasePipe } from '@shared/pipes/sentence-case.pipe';
import {
  DownloadListRequest,
  DownloadRequestFileType,
  DownloadRequestListType,
} from '@usf/list-types';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  CONTENT_TYPE_CSV,
  CONTENT_TYPE_CSV_ANDROID,
  CONTENT_TYPE_PDF,
  CONTENT_TYPE_XLSX,
  CONTENT_TYPE_XML,
} from '../../constants/files';
import { FileHandlerBaseService } from '../file-handler/file-handler-base.service';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadPrintBaseService extends FileHandlerBaseService {
  baseURL = '';

  constructor(
    public http: HttpClient,
    toastService: ToastService,
    translateService: TranslateService,
    public platform: Platform,
    public datePipe: DatePipe,
    public loadingSpinnerService: LoadingSpinnerService,
    private listAnalyticsHelperService: ListAnalyticsHelperService,
    sentenceCasePipe: SentenceCasePipe,
    private messageStoreService: MessageStoreService,
  ) {
    super(
      toastService,
      translateService,
      platform,
      datePipe,
      sentenceCasePipe,
      loadingSpinnerService,
    );
    this.baseURL = environment.listApiUrl;
  }

  public getDownloadUrl = () => {
    return this.baseURL + downloadPath;
  };

  public getBody = (
    listId: number,
    hidePrice: boolean,
    listType: DownloadRequestListType,
    fileType: DownloadRequestFileType,
  ): DownloadListRequest => {
    return {
      listId,
      hidePrice,
      listType,
      fileType,
    };
  };

  public getData = (
    listId: number,
    hidePrice: boolean,
    listType: DownloadRequestListType,
    fileType: DownloadRequestFileType,
    errorMessageKey: string,
  ): Observable<any> => {
    const url = this.getDownloadUrl();
    const body = this.getBody(listId, hidePrice, listType, fileType);
    const requestOptions: Object = {
      headers: {},
      params: undefined,
      responseType: 'text',
    };

    // Returning back a b64 encoded string within an observable
    return this.http.post<string>(url, body, requestOptions).pipe(
      catchError(err => {
        this.loadingSpinnerService.dismissSpinnerModal();
        this.showErrorToast(errorMessageKey);
        return throwError(err);
      }),
    );
  };

  public buildDefaultFileName(listName: string, customerNumber: number) {
    let defaultFileName = [];
    defaultFileName.push(
      listName,
      '_',
      customerNumber,
      '_',
      this.datePipe.transform(new Date(), 'MMddyyyy', 'GMT'),
    );
    return defaultFileName.join('');
  }

  public trackDownloadPrintAnalytics(
    listId: number,
    listType: string,
    listState: string,
    entryPoint: string,
  ) {
    this.listAnalyticsHelperService.downloadPrintList(
      listId,
      listType,
      listState,
      entryPoint,
    );
  }

  public async saveDocument(
    resp: Blob | string,
    fileName: string,
    fileType: DownloadRequestFileType,
    isDownload: boolean,
  ) {
    let fileNameWithExtension = fileName + '.' + fileType.toLowerCase();
    let contentTypeString = '';
    switch (fileType) {
      case 'CSV':
        contentTypeString = this.platform.is('android')
          ? CONTENT_TYPE_CSV_ANDROID
          : CONTENT_TYPE_CSV;
        break;
      case 'XLSX':
        contentTypeString = CONTENT_TYPE_XLSX;
        break;
      case 'XML':
        contentTypeString = CONTENT_TYPE_XML;
        break;
      case 'PDF':
        contentTypeString = CONTENT_TYPE_PDF;
        break;

      case 'Price File':
        contentTypeString = this.platform.is('android')
          ? CONTENT_TYPE_CSV_ANDROID
          : CONTENT_TYPE_CSV;
        fileNameWithExtension = fileName + '.csv'; // price file should be .csv file type
        break;

      case 'Price File ASCII':
        contentTypeString = this.platform.is('android')
          ? CONTENT_TYPE_CSV_ANDROID
          : CONTENT_TYPE_CSV;
        fileNameWithExtension = fileName + '.NEW'; // ascii price file should be .NEW file type
        break;
      default:
    }
    const utf8 = CONTENT_TYPE_XLSX == contentTypeString ? false : true;

    try {
      // platform check is for a dialog preference
      if (isDownload) {
        await this.downloadFile(
          resp,
          fileNameWithExtension,
          contentTypeString,
          this.platform.is('android'),
          utf8,
        );
      } else {
        await this.printFile(
          resp,
          fileNameWithExtension,
          contentTypeString,
          this.platform.is('android'),
        );
      }
    } catch (error) {
      throw error;
    }
    this.cleanUp();
  }

  public showErrorToast(errorMessageKey: string, errorCode?: string) {
    this.messageStoreService.upsertMessage({
      type: MessageTypeEnum.error,
      display: this.translateService.instant(errorMessageKey, { errorCode }),
      toast: true,
      sticky: true,
    } as Message);
  }
}
