import { PanAppStateLibConfig } from '@panamax/app-state';
import {
  azureNativeConfig,
  azureWebConfig,
  environment,
  premierWebConfig,
} from 'src/environments/environment';
import { buildNumber } from 'src/buildNumber';
import { PATHS } from './paths';

let traceContextMap = {};
traceContextMap[environment.authApiUrl] = 'login';
traceContextMap[environment.productApiUrl + '/search'] = 'search';
traceContextMap[environment.listApiUrl + '/lists'] = 'listMaintenance';
traceContextMap[environment.listApiUrl + '/listGroups'] = 'listMaintenance';
traceContextMap[environment.listApiUrl + '/listItems'] = 'listMaintenance';
traceContextMap[environment.listApiUrl + '/listItems/copyItems'] =
  'listMaintenance';
traceContextMap[environment.listApiUrl + '/externalVendor'] = 'listMaintenance';
traceContextMap[environment.listApiUrl + '/externalProduct'] =
  'listMaintenance';
traceContextMap[environment.listApiUrl + '/requestCredit'] = 'requestCredit';
traceContextMap[environment.orderApiUrl + '/orders'] = 'order';
traceContextMap[environment.orderApiUrl + '/submitIpOrder'] = 'order';
traceContextMap[environment.orderApiUrl + '/updateSubmittedOrder'] = 'order';
traceContextMap[environment.orderSubmissionApiUrl + '/submitIpOrder'] = 'order';
traceContextMap[environment.orderSubmissionApiUrl + '/updateSubmittedOrder'] =
  'order';
traceContextMap[environment.promotionApiUrl + '/promotion'] = 'deals';
traceContextMap[environment.pricingApiUrl + '/pricing'] = 'price';
traceContextMap[environment.pricingApiUrl + '/seller-pricing'] = 'sellerPrice';
traceContextMap[environment.inventoryApiUrl + '/inventory'] = 'inventory';

export const PAN_APP_STATE_LIB_CONFIG: PanAppStateLibConfig = {
  usabillaSettings: {
    scriptSrc: environment.usabillaScriptSrc,
    scriptId: environment.usabillaScriptId,
  },
  webAnalyticsSettings: {
    scriptSrc: environment.adobeScriptSrc,
    scriptId: environment.adobeScriptId,
  },
  openTelemetrySettings: {
    appName: environment.appName,
    environment: environment.envName,
    url: environment.openTelemetryUrl,
    concurrencyLimit: environment.otelConcurrencyLimit,
    traceContextMap,
  },
  featureFlagSettings: {
    authorizationKey: environment.flags.splitIOAuthorizationKey,
  },
  logRocketSettings: {
    logRocketScriptSrc: environment.logRocketScriptSrc,
    logRocketInit: environment.logRocketInit,
    logRocketBaseHref: environment.logRocketBaseHref,
  },
  tokenServiceSettings: {
    authApiTokenUrl: environment.authApiUrl,
    authApiLogoutUrl: environment.azureLogoutAllSessions,
    b2cDefaultPolicyUrl: environment.azureSellerCustomerLogin,
    b2cImpersonationPolicyUrl: environment.impersonationLogin,
    b2cInvitationLoginPolicyUrl: environment.invitationLogin,
    b2cAutomationPolicyUrl: environment.automationLogin,
    b2cCustomPremierPolicyUrl: environment.azurePremierLogin,
    ionicAuthMobileOptions: azureNativeConfig,
    ionicAuthWebOptions: azureWebConfig,
    ionicAuthPremierWebOptions: premierWebConfig,
    loginErrorPath: PATHS.LOGIN_ERROR,
    scheduledMaintenancePath: PATHS.SCHEDULED_MAINTENANCE,
    jwtPadding: environment.jwtPadding,
  },
  monetateServiceSettings: {
    monetateApiUrl: environment.productApiUrl,
    personalizationEndpoint: environment.kibo.personalizationEndpoint,
  },
  minimumVersionsConfigApiUrl: environment.minimumVersionsConfigApiUrl,
  stateVersion: environment.stateVersion,
  buildNumber: buildNumber,
};
