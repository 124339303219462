import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IonNav, ModalController, ViewWillEnter } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { InventoryWorksheetService } from '../../services/inventory-worksheet.service';
import {
  ChangeUnitsOptions,
  InventoryActions,
} from '@inventory/constants/inventory-constants';
import {
  InventoryProductViewModel,
  InventoryViewModel,
} from '@inventory/models/inventory-view.model';
import {
  BASE_INVENTORY_UNITS,
  InventoryGroupHeader,
  InventoryGroupItemPair,
} from '@usf/ngrx-inventory';
import { TranslateService } from '@ngx-translate/core';
import {
  InventoryItemUpdate,
  InventoryUOMDropdownEvent,
} from '@inventory/models/edit-inventory.model';
import { Button } from '@shared/models/ellipsis-option.model';
import { FormControl, Validators } from '@angular/forms';

@Component({
  template: '',
})
export class InventoryActionModalBaseComponent implements ViewWillEnter {
  @Input() selectedAction: InventoryActions;
  @Input() inventory: InventoryViewModel;

  @Output()
  updateUOMEvent: EventEmitter<InventoryUOMDropdownEvent> = new EventEmitter();

  readonly InventoryActions = InventoryActions;
  selectedGroup: InventoryGroupHeader;
  sortedGroups: InventoryGroupHeader[] = [];
  currentGroupText: string;
  selectedUOM: string = ChangeUnitsOptions.default;
  customUOM: string;
  newUOM: string;
  newConversionFactor: number;
  customUnitInput = new FormControl('', [
    Validators.pattern(new RegExp(/^([a-zA-Z0-9]+ ?)*$/)),
    Validators.maxLength(8),
  ]);
  existingLabels = BASE_INVENTORY_UNITS.slice();

  constructor(
    protected ionNav: IonNav,
    private modalController: ModalController,
    private translateService: TranslateService,
    readonly platformService: PlatformService,
    protected inventoryWorksheetService: InventoryWorksheetService,
  ) {}

  ionViewWillEnter(): void {
    this.populateModal();
  }

  populateModal() {
    const groupNames = this.inventory?.inventoryOptions?.selections.map(
      selection => {
        return selection.groupName;
      },
    );
    if (groupNames !== undefined) {
      this.currentGroupText = this.buildSelectedGroupText(groupNames);
      this.sortedGroups = this.sortGroups(groupNames);
    }
  }

  sortGroups(groupNames: string[]) {
    //sort alphabetically and remove unassigned group
    let groupHeaders = [];
    this.inventory?.groupHeaders.forEach(groupHeader => {
      groupHeaders.push({
        ...groupHeader,
      });
    });
    let sortedGroups = groupHeaders.sort((a, b) =>
      a.groupName.localeCompare(b.groupName),
    );
    //if products are from unassigned group, we must remove it from group list
    sortedGroups = sortedGroups?.filter(group => {
      return group.groupName !== 'Unassigned Group';
    });
    if (new Set(groupNames).size === 1) {
      //if products were selected and they're not from unassigned group
      //we should show unassigned group first and preselect and remove current group
      if (groupNames[0] !== 'Unassigned Group') {
        const unassignedGroup = groupHeaders.find(group => {
          return group.groupName == 'Unassigned Group';
        });
        sortedGroups = sortedGroups.filter(group => {
          return group.groupName !== groupNames[0];
        });
        if (unassignedGroup !== undefined) {
          this.selectedGroup = unassignedGroup;
          sortedGroups.unshift(unassignedGroup);
        }
      }
    } else {
      const unassignedGroup = groupHeaders.find(group => {
        return group.groupName === 'Unassigned Group';
      });
      if (unassignedGroup !== undefined) {
        this.selectedGroup = unassignedGroup;
        sortedGroups.unshift(unassignedGroup);
      }
    }
    return sortedGroups;
  }

  buildSelectedGroupText(groupNames: string[]) {
    if (new Set(groupNames).size === 1) {
      return (this.currentGroupText = groupNames[0]);
    } else {
      return (this.currentGroupText = this.translateService.instant(
        'i18n.inventory.inventoryWorksheet.multipleGroups',
      ));
    }
  }

  onSelectGroup(event: any) {
    this.selectedGroup = event?.target?.value;
  }

  dispatchAction(selectedAction: string) {
    let targetGroupAndIndex: InventoryGroupItemPair;
    if (
      selectedAction !== InventoryActions.delete &&
      selectedAction !== InventoryActions.changeUnits
    ) {
      targetGroupAndIndex = {
        inventoryItemIndex: 0,
        groupIndex: this.selectedGroup.groupAbsoluteIndex,
      };
    }
    let inventoryItemIdAndGroupPairs = this.buildInventoryGroupItemsPairs();

    switch (selectedAction) {
      case InventoryActions.copyToGroup:
        this.copyItemsToGroup(
          targetGroupAndIndex,
          inventoryItemIdAndGroupPairs,
        );
        break;
      case InventoryActions.moveToGroup:
        this.moveItemsToGroup(
          targetGroupAndIndex,
          inventoryItemIdAndGroupPairs,
        );
        break;
      case InventoryActions.changeUnits:
        this.changeUnits();
        break;
      case InventoryActions.delete:
        this.deleteProducts(inventoryItemIdAndGroupPairs);
        break;
    }
  }

  changeUnits() {
    const itemsToUpdate: InventoryItemUpdate[] = [];
    this.inventory.inventoryOptions.selections.forEach(item => {
      itemsToUpdate.push({
        inventoryItemId: item.inventoryItemId,
        inventoryId: item.inventoryId,
        groupName: item.groupName,
      });
    });
    this.inventoryWorksheetService.updateInventoryItemUOMAndConversion(
      itemsToUpdate,
      this.newUOM === this.customUOM ? 'Custom' : this.newUOM,
      this.newConversionFactor,
      this.inventory.inventoryId,
      this.newUOM === this.customUOM ? this.newUOM : '',
    );
    this.dismissModal();
  }

  deleteProducts(inventoryItemIdAndGroupPairs: InventoryGroupItemPair[]) {
    this.inventoryWorksheetService.deleteProduct(
      inventoryItemIdAndGroupPairs,
      this.inventory,
    );
    this.dismissModal();
  }

  moveItemsToGroup(
    targetGroupAndIndex: InventoryGroupItemPair,
    inventoryItemIdAndGroupPairs: InventoryGroupItemPair[],
  ) {
    this.inventoryWorksheetService.moveInventoryItemsToNewGroup(
      inventoryItemIdAndGroupPairs,
      this.inventory.inventoryId,
      targetGroupAndIndex,
      true,
    );
    this.dismissModal();
  }

  copyItemsToGroup(
    targetGroupAndIndex: InventoryGroupItemPair,
    inventoryItemIdAndGroupPairs: InventoryGroupItemPair[],
  ) {
    this.inventoryWorksheetService.copyInventoryItemsIntoGroup(
      inventoryItemIdAndGroupPairs,
      this.inventory.inventoryId,
      targetGroupAndIndex,
    );
    this.dismissModal();
  }

  buildInventoryGroupItemsPairs(): InventoryGroupItemPair[] {
    let inventoryItemIdAndGroupPairs: InventoryGroupItemPair[] = [];
    //remap group headers to a index pair and have group name as key
    let groupMap = new Map<string, number>();
    this.inventory?.groupHeaders?.forEach(groupHeader => {
      groupMap.set(groupHeader.groupName, groupHeader.groupAbsoluteIndex);
    });
    if (this.inventory?.items) {
      let mutableInvItems = [];
      this.inventory?.items.forEach(invItem => {
        mutableInvItems.push(invItem);
      });
      //filter items by selected group name and item number
      let inventoryItemsToDispatch = [];
      mutableInvItems.forEach((invItem: InventoryProductViewModel) => {
        this.inventory.inventoryOptions.selections.forEach(selection => {
          if (
            selection.groupName === invItem?.groupName &&
            selection.inventoryItemId === invItem?.productNumber
          ) {
            inventoryItemsToDispatch.push(invItem);
          }
        });
      });
      //build inventoryItemIdAndGroupPairs with item and group absolute index
      inventoryItemsToDispatch?.forEach(itemToDispatch => {
        inventoryItemIdAndGroupPairs.push({
          inventoryItemIndex: itemToDispatch.itemAbsoluteIndex,
          groupIndex: groupMap.get(itemToDispatch.groupName),
        });
      });
    }
    return inventoryItemIdAndGroupPairs;
  }

  async dismissModal() {
    await this.modalController?.dismiss();
  }

  async selectInventoryItemUOM(event: Event) {
    let buttons: Button[];
    buttons = [
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.uom.select',
        ),
        handler: () => this.setUOMForInventoryItems(ChangeUnitsOptions.default),
      },
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.uom.each',
        ),
        handler: () => this.setUOMForInventoryItems(ChangeUnitsOptions.each),
      },
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.uom.pound',
        ),
        handler: () => this.setUOMForInventoryItems(ChangeUnitsOptions.pound),
      },
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.uom.ounce',
        ),
        handler: () => this.setUOMForInventoryItems(ChangeUnitsOptions.ounce),
      },
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.uom.grams',
        ),
        handler: () => this.setUOMForInventoryItems(ChangeUnitsOptions.grams),
      },
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.uom.gallon',
        ),
        handler: () => this.setUOMForInventoryItems(ChangeUnitsOptions.gallon),
      },
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.uom.batch',
        ),
        handler: () => this.setUOMForInventoryItems(ChangeUnitsOptions.batch),
      },
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.uom.piece',
        ),
        handler: () => this.setUOMForInventoryItems(ChangeUnitsOptions.piece),
      },
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.uom.addCustom',
        ),
        handler: () => this.setUOMForInventoryItems(ChangeUnitsOptions.custom),
      },
    ];

    this.platformService.isTouch.value
      ? this.inventoryWorksheetService.openSelectionsTouch(buttons)
      : this.inventoryWorksheetService.openSelectionsDesktop(event, buttons);
  }

  setUOMForInventoryItems(unit: string) {
    this.selectedUOM = unit;
    if (unit === ChangeUnitsOptions.default) {
      this.newUOM = undefined;
    } else {
      this.newUOM = unit;
    }
    this.inventoryWorksheetService.dismissSelection();
  }

  setConversionFactor(event$: any) {
    this.newConversionFactor = event$?.currentTarget?.value;
  }

  setCustomUOM(event$: any) {
    this.customUOM = event$?.currentTarget?.value;
    this.newUOM = this.customUOM;
  }
}
