import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import {
  Directory,
  Encoding,
  Filesystem,
  WriteFileResult,
} from '@capacitor/filesystem';
import {
  DeviceNotAvailableError,
  VersionError,
} from '../sharesheet/sharesheet.service';
import { Share, ShareOptions } from '@capacitor/share';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { ToastService } from '../toast/toast.service';
import { CONTENT_TYPE_PDF } from './../../constants/files';
import { SentenceCasePipe } from './../../pipes/sentence-case.pipe';
import { PLATFORM_CAPACITOR_TYPE } from '@shared/constants/platform-capacitor';

export class OpenFileError extends Error {}
export class SaveFileError extends Error {}
export class PrintFileError extends Error {}
export class ShareFileError extends Error {}

@Injectable({
  providedIn: 'root',
})
export class FileHandlerBaseService {
  downloadPrintSubs: Subscription = new Subscription();

  constructor(
    private toastService: ToastService,
    public translateService: TranslateService,
    public platform: Platform,
    public datePipe: DatePipe,
    public sentenceCasePipe: SentenceCasePipe,
    public loadingSpinnerService: LoadingSpinnerService,
  ) {}

  /**
   *  Saves file to disk and opens it if on mobile or tablet.
   *  Opening the file on tablet and mobile is usually a better experience as we have to
   *  save files in the Data directory on Android, which can be hard to find for users.
   */
  async downloadFile(
    fileData: Blob | string,
    fileName: string,
    contentType: string,
    openWithDialog: boolean = true,
    utf8 = true,
  ) {
    const file = await this.saveFile(fileData, fileName, contentType, utf8);

    if (Capacitor.isNativePlatform()) {
      return await this.openFileNative(file, contentType, openWithDialog);
    }
  }

  async printFile(
    fileData: Blob | string,
    fileName: string,
    contentType: string,
    openWithDialog: boolean = true,
  ) {
    const file = await this.saveFile(fileData, fileName, contentType);

    if (Capacitor.isNativePlatform()) {
      return await this.openFileNative(file, contentType, openWithDialog);
    } else {
      return this.printWeb(fileData, contentType);
    }
  }

  async shareFileNative(
    fileData: Blob,
    fileName: string,
    fileExtension: string,
    shareOptions: ShareOptions,
    utf8 = false,
  ) {
    const file = await this.saveFile(
      fileData,
      fileName + fileExtension,
      CONTENT_TYPE_PDF,
      utf8,
      Directory.Cache,
    );

    if (Capacitor.isNativePlatform()) {
      if (!Capacitor.isPluginAvailable('Share')) {
        return Promise.reject(new VersionError());
      }
      Share.share({ ...shareOptions, url: file.uri })
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject(new ShareFileError());
        });
    } else {
      return Promise.reject(new DeviceNotAvailableError());
    }
  }

  async openFileNative(
    writeFileResult: WriteFileResult,
    contentType: string,
    openWithDialog: boolean = true,
  ) {
    try {
      return await FileOpener.open({
        filePath: writeFileResult.uri,
        contentType: contentType,
        openWithDefault: !openWithDialog,
      });
    } catch (e) {
      throw new OpenFileError();
    }
  }

  async saveFile(
    fileData: Blob | string,
    fileName: string,
    fileType: string = CONTENT_TYPE_PDF,
    utf8 = true,
    directory?: Directory,
  ) {
    try {
      const blob =
        typeof fileData === 'string'
          ? new Blob([fileData], { type: fileType })
          : fileData;

      const isIOS = Capacitor.getPlatform() === PLATFORM_CAPACITOR_TYPE.IOS;
      const isAndroid =
        Capacitor.getPlatform() === PLATFORM_CAPACITOR_TYPE.ANDROID;
      const isWeb = !Capacitor.isNativePlatform();

      if (isWeb) {
        this.saveWeb(blob, fileName);
      }

      if (isAndroid) {
        return this.saveAndroid(blob, fileName, directory);
      }

      if (isIOS) {
        return this.saveIOS(blob, fileName, directory, utf8);
      }
    } catch (e) {
      throw new SaveFileError();
    }
  }

  saveWeb(blob: Blob, fileName: string) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async saveIOS(
    blob: Blob,
    fileName: string,
    directory?: Directory,
    utf8 = true,
  ) {
    const file = await Filesystem.writeFile({
      path: fileName,
      data: await (utf8 ? blob.text() : this.blobToBase64(blob)),
      directory: directory ?? Directory.Documents,
      encoding: utf8 ? Encoding.UTF8 : null,
    });
    return file;
  }

  async saveAndroid(blob: Blob, fileName: string, directory?: Directory) {
    const file = await Filesystem.writeFile({
      path: fileName,
      data: await this.blobToBase64(blob),
      directory: directory ?? Directory.Data,
    });
    return file;
  }

  printWeb(fileData: string | Blob, fileType: string = CONTENT_TYPE_PDF) {
    try {
      const blob =
        typeof fileData === 'string'
          ? new Blob([fileData], { type: fileType })
          : fileData;
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    } catch (e) {
      throw new PrintFileError();
    }
  }

  async blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64data = reader.result as string;
        resolve(base64data);
      };
      reader.onerror = error => {
        reject(error);
      };
    });
  }

  public convertBase64ToByteArray(resp: string) {
    const byteCharacters = atob(resp);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
  }

  public showErrorToast(errorMessageKey: string, errorCode?: string) {
    this.toastService.presentToastMsg(
      this.translateService.instant(errorMessageKey) +
        (errorCode
          ? this.translateService.instant('i18n.common.errorCodePart', {
              errorCode,
            })
          : ''),
      'red-toast',
      MessageTypeEnum.error,
      [],
    );
  }

  public cleanUp() {
    this.loadingSpinnerService.dismissSpinnerModal();
    if (this.downloadPrintSubs) {
      this.downloadPrintSubs.unsubscribe();
    }
  }
}
