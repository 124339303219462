import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Product } from '@shared/models/product.model';
import { PlatformEnum, PlatformService, Tracking } from '@panamax/app-state';
import { ModalController } from '@ionic/angular';
import { ModalService } from '@shared/services/modal/modal.service';
import { BehaviorSubject } from 'rxjs';
import Swiper from 'swiper';
import { QuantityUpdate } from '@shared/models/quantity-update';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { UsfProductCardModeEnum } from '@shared/constants/usf-product-card-mode.enum';
import { OrderItemService } from '@shared/services/order-item.service';
import { ProductClickData } from '@shared/models/usf-product-card.model';
import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';

@Component({
  selector: 'app-works-well-with-modal',
  templateUrl: './works-well-with-modal.html',
  styleUrls: ['./works-well-with-modal.scss'],
})
export class WorksWellWithModalComponent implements OnInit {
  WORKS_WELL_WITH_FEATURE_NAME = 'Works Well With';
  linkSource = 'worksWellWith';

  @Input() product: Product;
  @Input() worksWellWithProducts: ProductDetailViewModel[];
  @Input() platform: PlatformEnum;
  @Input() swiperRef$: BehaviorSubject<Swiper>;
  @Input() isLandscapeTablet = false;

  @Output() isOpenEmitter = new EventEmitter<boolean>();
  @Output() dismissEvent: EventEmitter<void> = new EventEmitter();
  @Output() productCardClickEvent = new EventEmitter<string>();
  @Output() initializedProductCardEvent = new EventEmitter<ElementRef>();

  @ViewChild('recentSwiperRef', { static: false }) recentSwiperRef: any;

  parentApn = '';

  readonly PlatformEnum = PlatformEnum;
  readonly UsfProductCardModeEnum = UsfProductCardModeEnum;

  constructor(
    protected modalController: ModalController,
    protected modalService: ModalService,
    private orderItemService: OrderItemService,
    private navigationHelperService: NavigationHelperService,
    readonly platformService: PlatformService,
  ) {}

  ngOnInit(): void {
    this.platform = this.platformService.getPlatformType();
    this.parentApn = `${this.product?.summary?.divisionNumber}-${this.product?.productNumber}`;
  }

  dismiss() {
    this.dismissEvent.emit();
    this.modalController.dismiss();
  }

  setSwiperInstance(swiper: Swiper) {
    this.swiperRef$.next(swiper);
  }

  productDetailCardClick() {
    this.dismiss();
  }

  emitProductCardInitialization(productCardRef: ElementRef) {
    this.initializedProductCardEvent.emit(productCardRef);
  }

  orderQuantityUpdated(quantityUpdate: QuantityUpdate, index: number) {
    const trackingData: Tracking = {
      analytics: {
        data: {
          products: [
            {
              addMethod: this.WORKS_WELL_WITH_FEATURE_NAME,
              merchFeatureName: this.WORKS_WELL_WITH_FEATURE_NAME,
              mappingTo: '1',
            },
            {
              divisionApn: `${this.product?.summary?.divisionNumber}-${this.product?.productNumber}`,
              attributes: this.product?.trackingAttributes,
              mappingFrom: '1',
            },
          ],
          results: {
            resultRank: index + 1,
          },
        },
      },
    };

    this.orderItemService.changeQuantity(
      quantityUpdate.productNumber,
      quantityUpdate.quantity,
      quantityUpdate.isEaches,
      trackingData,
    );
  }

  scrollBlock($event: Event) {
    $event.stopPropagation();
  }

  clickCard(productClickData: ProductClickData) {
    this.navigationHelperService.routeToProductDetailsQueryParams(
      productClickData.productNumber,
      { linksrc: this.linkSource },
    );
    this.dismiss();
  }
}
