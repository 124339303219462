import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerSummary } from '@usf/customer-types/customer';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';
import { ServiceHandlerService } from 'src/app/shared/services/service-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataService<T> {
  constructor(private serviceHandler: ServiceHandlerService) {}

  searchCustomers(searchKey: string): Observable<CustomerSummary[]> {
    const params: HttpParams = new HttpParams().append('name', searchKey);

    return this.serviceHandler
      .get<CustomerSummary[]>(
        `${environment.superUserApiUrl}/customers`,
        params,
      )
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
      );
  }
  get<T>(
    path: string,
    customerNumber?: number,
    divisionNumber?: number,
  ): Observable<T[]> {
    let apiUrl = `${environment.customerApiUrl}/${path}`;
    if (customerNumber && divisionNumber) {
      apiUrl += `?customerNumber=${customerNumber}&divisionNumber=${divisionNumber}`;
    }
    return this.serviceHandler.get<any>(apiUrl).pipe(
      take(1),
      filter(res => !!res),
      catchError(error => throwError(error)),
    );
  }
}
