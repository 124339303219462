import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { PATHS } from '../constants/paths';

@Injectable({
  providedIn: 'root',
})
export class NavigationHelperService {
  constructor(
    private readonly router: Router,
    private readonly window: Window,
    private readonly location: Location,
  ) {}

  routeToProductDetails(productNumber: number) {
    const url = this.window.location.pathname;
    this.router.navigate([url.split('/')[1], 'products', productNumber]);
  }

  routeToProductDetailsQueryParams(productNumber: number, queryParams = {}) {
    const url = window.location.pathname;
    this.router.navigate([url.split('/')[1], 'products', productNumber], {
      queryParams,
    });
  }

  routeToNotificationDetails(notificationId: number) {
    const url = this.window.location.pathname;
    this.router.navigate([
      url.split('/')[1],
      'notifications',
      'notification-details',
      notificationId,
    ]);
  }

  routeToNoteDetails(noteId: number) {
    const url = this.window.location.pathname;
    this.router.navigate([
      url.split('/')[1],
      'notifications',
      'note-details',
      noteId,
    ]);
  }

  routeToSubmittedOrder(orderId: string, replaceUrl = false) {
    const url = this.window.location.pathname;
    this.router.navigate(
      [url.split('/')[1], 'order', 'submitted-order', orderId],
      { replaceUrl },
    );
  }
  vptRouteToOrderConfirmation(orderId: string) {
    const url = this.window.location.pathname;
    this.router.navigate([
      url.split('/')[0],
      'order',
      'order-confirmation',
      orderId,
    ]);
  }

  routeToOrderInsights(orderId: string, replaceUrl = false): void {
    const url = this.window.location.pathname;

    this.router.navigate(
      [url.split('/')[1], 'order', 'order-insights', orderId],
      { replaceUrl },
    );
  }

  /**
   * Generic routing
   *
   * @param path - path to navigate
   * @param queryParams - queryParams for route
   * @param data - general information persisted in history state
   */
  routeTo(path: string, queryParams = {}, data = {}) {
    const url = window.location.pathname;
    this.router.navigate([url.split('/')[1]].concat(path.split('/')), {
      queryParams,
      state: data,
    });
  }

  routeToSearch(queryParams = {}, data = {}) {
    const url = window.location.pathname;
    const timeStamp = Math.floor(Math.random() * 100000);
    this.router.navigate([url.split('/')[1], PATHS.SEARCH], {
      queryParams: {
        ...queryParams,
        ts: timeStamp,
      },
      state: data,
    });
  }

  routeToNewSearch(
    queryParams = {},
    correlationIdentifier?: string,
    replaceUrl = false,
  ) {
    const url = window.location.pathname;
    const correlationId =
      correlationIdentifier ?? `${Math.floor(Math.random() * 100000)}`;
    this.router.navigate([url.split('/')[1], PATHS.NEW_SEARCH], {
      queryParams: {
        ...queryParams,
        correlationId,
      },
      replaceUrl,
    });
  }

  getParamAsObject() {
    const paramObj = {};
    this.getQueryParams().forEach((value, key) => {
      paramObj[key] = value;
    });
    return paramObj;
  }

  routeToNotFoundPage() {
    const url = this.window.location.pathname;
    this.router.navigate([url.split('/')[1], '404']);
  }

  redirectionBack() {
    this.location.back();
  }

  getLocationPath() {
    return this.location.path();
  }

  getQueryParams() {
    return new URLSearchParams(this.window.location.search);
  }

  openDocumentInTabBrowser(url: string) {
    window.open(url, '_blank');
  }

  async openDocumentPopoverCapacitor(url: string) {
    await Browser.open({
      url,
      presentationStyle: 'popover',
    });
  }
}
