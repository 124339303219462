import { Capacitor } from '@capacitor/core';
import jsPDF from 'jspdf';
import autoTable, { CellDef, CellHookData } from 'jspdf-autotable';
import { ChangeUnitsOptions } from '../../../../inventory/constants/inventory-constants';
import {
  CostComparisonReportRow,
  CostComparisonValidateRow,
} from '../../../../inventory/models/cost-comparison-report-row.model';
import { CostReportSortOptions } from '../../../../inventory/models/cost-report-model.model';
import {
  InventoryItem,
  InventoryProductViewModel,
  InventoryViewModel,
} from '../../../../inventory/models/inventory-view.model';
import { ItemTypes } from '../../../../lists/shared/list-detail-management/model/list-detail-management-view.model';
import { PDFColors } from '../../../constants/pdf-colors.enum';
import {
  BODY_FONT,
  HEADER_FONT,
  SUB_HEADER_FONT,
} from '../../../constants/pdf-font';
import {
  addValueToColumns,
  getDocumentMultipleCurrency,
  getDocumentRaw,
  getDocumentStatus,
  getDocumentStatusImage,
  getDocumentString,
  getDocumentTypeImage,
  getDocumentTypeText,
  getRawDocumentCurrency,
} from '../../../helpers/document-creation.helpers';
import { desiredColumn } from '../../../models/desired-column';
import { Product } from '../../../models/product.model';
import { SeperatedColumn } from '../../../models/seperated-columns';
import { InventoryColumns } from './document-helper-models-and-enums';
import {
  getProductDescriptionForSort,
  isNonUsf,
} from './download-inventory-helpers';
import {
  createHeadersForPDF,
  getColumnStyles,
  getImageMap,
} from './general-pdf-helper';

export const getCostComparisonReportRows = (
  firstInventory: InventoryViewModel,
  secondInventory: InventoryViewModel,
  productsMap: Map<number, Product>,
  isPdf: boolean,
): CostComparisonReportRow[] => {
  let costComparisonReportRows: CostComparisonReportRow[] = [];

  const firstInventoryMap = generateValidatorMap(firstInventory.items);
  const secondInventoryMap = generateValidatorMap(secondInventory.items);
  for (let [key, value] of firstInventoryMap) {
    const secondValue = secondInventoryMap.get(key);
    if (secondValue) {
      const costComparisonReportRow = createCostComparisonReportRow(
        value,
        secondValue,
        productsMap,
        isPdf,
      );
      costComparisonReportRows.push(costComparisonReportRow);
    }
  }

  return costComparisonReportRows;
};

export const generateValidatorMap = (
  items: InventoryItem[],
): Map<number, CostComparisonValidateRow> => {
  const map = new Map<number, CostComparisonValidateRow>();
  for (const inventoryItem of items) {
    if (inventoryItem?.itemType === ItemTypes.product) {
      const inventoryProduct = inventoryItem as InventoryProductViewModel;
      const validateRowInMap = map.get(inventoryProduct.productNumber);
      const currentRow = createCostComparisonValidateRow(inventoryProduct);
      if (validateRowInMap) {
        if (
          validateRowInMap.isValidPrice &&
          validateRowInMap.documentPrice !== currentRow.documentPrice
        ) {
          map.set(inventoryProduct.productNumber, {
            ...validateRowInMap,
            isValidPrice: false,
          });
        }
        const rowInMapUnitByCase = validateRowInMap.displayConvFactor
          ? validateRowInMap.displayConvFactor
          : 1;
        const currentRowUnitByCase = currentRow.displayConvFactor
          ? currentRow.displayConvFactor
          : 1;
        if (
          validateRowInMap.isValidUnitsByCase &&
          rowInMapUnitByCase !== currentRowUnitByCase
        ) {
          map.set(inventoryProduct.productNumber, {
            ...validateRowInMap,
            isValidUnitsByCase: false,
          });
        }
      } else {
        map.set(inventoryProduct.productNumber, currentRow);
      }
    }
  }
  return map;
};

export const createCostComparisonValidateRow = (
  inventoryItem: InventoryProductViewModel,
): CostComparisonValidateRow => {
  const documentPrice =
    inventoryItem.priceOverrideInd === 'Y'
      ? inventoryItem.overrideCasePrice
      : inventoryItem.productCatchWeightFlag
      ? inventoryItem.productUnitPrice
      : inventoryItem.productCasePrice;
  return {
    ...inventoryItem,
    documentPrice,
    isValidPrice: true,
    isValidUnitsByCase: true,
  } as CostComparisonValidateRow;
};

export const createCostComparisonReportRow = (
  firstCostComparisonValidateRow: CostComparisonValidateRow,
  secondCostComparisonValidateRow: CostComparisonValidateRow,
  productsMap: Map<number, Product>,
  isPdf: boolean,
): CostComparisonReportRow => {
  const isMobile = Capacitor.isNativePlatform();
  const product = productsMap.get(firstCostComparisonValidateRow.productNumber);
  const productDescription = getProductDescriptionForSort(
    firstCostComparisonValidateRow,
  );
  const isNonUsfProduct = isNonUsf(product?.productNumber);
  const isPdfDesktop = isPdf && !isMobile;
  const productStatus =
    isNonUsfProduct && firstCostComparisonValidateRow.productName
      ? ''
      : isPdfDesktop
      ? getDocumentStatusImage(product)
      : getDocumentStatus(product);
  const productType = isPdfDesktop
    ? getDocumentTypeImage(product)
    : getDocumentTypeText(product);

  let brand =
    productDescription !== '"Not Available"'
      ? firstCostComparisonValidateRow.productBrand ?? ''
      : '';

  const packSize = getDocumentRaw(
    productDescription !== '"Not Available"'
      ? firstCostComparisonValidateRow.productPackSizeLabel
      : '',
  );
  const vendorName = firstCostComparisonValidateRow?.vendorName;
  const vendor = isNonUsfProduct
    ? vendorName
      ? vendorName
      : 'Non-USF'
    : 'USF';
  const unitsByCase = getUnitsByCase(
    firstCostComparisonValidateRow,
    secondCostComparisonValidateRow,
  );
  const {
    firstCasePrice,
    secondCasePrice,
    firstEachPrice,
    secondEachPrice,
    casePriceChange,
    casePricePercentChange,
  } = getPriceValues(
    firstCostComparisonValidateRow,
    secondCostComparisonValidateRow,
    unitsByCase,
  );

  const firstEachUnit = getEachUnit(firstCostComparisonValidateRow);
  const secondEachUnit = getEachUnit(secondCostComparisonValidateRow);
  return {
    glString: firstCostComparisonValidateRow?.glString,
    productNumber: firstCostComparisonValidateRow.productNumber,
    productDescription,
    productStatus,
    productType,
    brand,
    packSize,
    vendor: getDocumentString(vendor),
    unitsByCase,
    firstCasePrice,
    secondCasePrice,
    firstEachPrice,
    secondEachPrice,
    casePriceChange: getDocumentString(getRawDocumentCurrency(casePriceChange)),
    casePricePercentChange: casePricePercentChange + '%',
    firstEachUnit,
    secondEachUnit,
    catchWeightFlag: firstCostComparisonValidateRow.productCatchWeightFlag,
  } as CostComparisonReportRow;
};

export const getEachUnit = (
  costComparisonValidateRow: CostComparisonValidateRow,
) => {
  let eachUnit = costComparisonValidateRow.uom;
  if (eachUnit?.length > 2) {
    if (eachUnit === ChangeUnitsOptions.piece) {
      eachUnit = 'PC';
    } else {
      eachUnit = eachUnit?.substring(0, 2);
    }
  }
  eachUnit = eachUnit?.toUpperCase();
  return eachUnit;
};

export const getPriceValues = (
  firstRow: CostComparisonValidateRow,
  secondRow: CostComparisonValidateRow,
  unitsByCase: string,
) => {
  let firstCasePrice = getCasePrice(firstRow);
  let secondCasePrice = getCasePrice(secondRow);
  let firstEachPrice = getEachPrice(firstRow);
  let secondEachPrice = getEachPrice(secondRow);
  let casePriceChange: number | string = 0;
  let casePricePercentChange: number | string = 0;
  if (
    firstCasePrice === 0 ||
    secondCasePrice === 0 ||
    firstEachPrice === 0 ||
    secondEachPrice === 0 ||
    unitsByCase === '""'
  ) {
    firstCasePrice = 0;
    secondCasePrice = 0;
    firstEachPrice = 0;
    secondEachPrice = 0;
    casePriceChange = 0;
    casePricePercentChange = 0;
    return {
      firstCasePrice,
      secondCasePrice,
      firstEachPrice,
      secondEachPrice,
      casePriceChange,
      casePricePercentChange,
    };
  }
  if (secondCasePrice - firstCasePrice !== 0) {
    casePriceChange = (secondCasePrice - firstCasePrice).toFixed(2);
    casePricePercentChange = (
      (Number(casePriceChange) / firstCasePrice) *
      100
    ).toFixed(2);
  }

  return {
    firstCasePrice,
    secondCasePrice,
    firstEachPrice,
    secondEachPrice,
    casePriceChange,
    casePricePercentChange,
  };
};

export const getCasePrice = (row: CostComparisonValidateRow) => {
  if (row.isValidPrice && row.documentPrice > 0) {
    return row.documentPrice;
  } else {
    return 0;
  }
};

export const getEachPrice = (row: CostComparisonValidateRow) => {
  if (row.productCatchWeightFlag && row.isValidPrice && row.documentPrice > 0) {
    return row.documentPrice;
  } else if (
    !row.productCatchWeightFlag &&
    row.isValidPrice &&
    row.isValidUnitsByCase &&
    row.unitPrice > 0
  ) {
    return row.unitPrice;
  } else {
    return 0;
  }
};

export const getUnitsByCase = (
  firstRow: CostComparisonValidateRow,
  secondRow: CostComparisonValidateRow,
) => {
  const firstUnitsByCase = firstRow.displayConvFactor
    ? firstRow.displayConvFactor
    : 1;
  const secondUnitsByCase = secondRow.displayConvFactor
    ? secondRow.displayConvFactor
    : 1;
  if (
    !firstRow.isValidUnitsByCase ||
    !secondRow.isValidUnitsByCase ||
    firstUnitsByCase !== secondUnitsByCase
  ) {
    return getDocumentString('N/A');
  }
  return getDocumentString(firstUnitsByCase);
};

export const sortCostComparisonRows = (
  costComparisonReportRows: CostComparisonReportRow[],
  selectedSort: string,
): CostComparisonReportRow[] => {
  let sortedReportRows = costComparisonReportRows;
  if (selectedSort === CostReportSortOptions.descriptionAsc) {
    sortedReportRows.sort((a, b) => {
      const descriptionA = a.productDescription?.toLowerCase();
      const descriptionB = b.productDescription?.toLowerCase();
      if (descriptionA === descriptionB) {
        return a.productNumber > b.productNumber ? 1 : -1;
      } else {
        return descriptionA > descriptionB ? 1 : -1;
      }
    });
  } else if (selectedSort === CostReportSortOptions.percentPriceChange) {
    sortedReportRows.sort((a, b) => {
      const priceChangeA = parseFloat(a.casePricePercentChange);
      const priceChangeB = parseFloat(b.casePricePercentChange);
      const descriptionA = a.productDescription?.toLowerCase();
      const descriptionB = b.productDescription?.toLowerCase();
      if (priceChangeA === priceChangeB) {
        if (descriptionA === descriptionB) {
          return a.productNumber > b.productNumber ? 1 : -1;
        } else {
          return descriptionA > descriptionB ? 1 : -1;
        }
      } else {
        return priceChangeA > priceChangeB ? -1 : 1;
      }
    });
  }

  return sortedReportRows;
};

export const createCostComparisonColumns = (
  costComparisonReportRows: CostComparisonReportRow[],
  columnConfig: desiredColumn[],
): SeperatedColumn[] => {
  let columnMap = new Map<string, SeperatedColumn>();
  costComparisonReportRows.forEach((item: CostComparisonReportRow) => {
    columnConfig.forEach(column => {
      columnMap = costComparisonFunctionMap[column.columnType](
        column.columnName,
        columnMap,
        item,
      );
    });
  });

  let separatedColumns: SeperatedColumn[] = [];
  columnMap.forEach(column => {
    separatedColumns.push(column);
  });
  return separatedColumns;
};

export const createCostComparisonProductRowsForPdf = (
  seperatedColumns: SeperatedColumn[],
) => {
  const isMobile = Capacitor.isNativePlatform();
  const productRows: string[][] = [];
  const imageKeys: Set<string> = new Set();
  if (seperatedColumns.length > 0) {
    for (let i = 0; i < seperatedColumns[0].columnValues.length; i++) {
      const productDataArray = [];
      seperatedColumns.forEach(value => {
        if (
          (value.columnName === 'Product Status' ||
            value.columnName === 'Product Type') &&
          !isMobile
        ) {
          productDataArray.push(value.columnValues[i].toString());
          if (
            value.columnValues[i].toString() !== '' &&
            !imageKeys.has(value.columnValues[i].toString())
          ) {
            imageKeys.add(value.columnValues[i].toString());
          }
        } else {
          productDataArray.push(
            value.columnValues[i].toString().replaceAll('"', ''),
          );
        }
      });

      productRows.push(productDataArray);
    }
  }
  return { productRows, imageKeys };
};

export const getCostComparisonCsvColumnsConfig = (
  includeGlCodes: boolean,
): desiredColumn[] => {
  const separatedColumns: desiredColumn[] = [];
  if (includeGlCodes) {
    separatedColumns.push({
      columnName: 'GL Code',
      columnType: InventoryColumns.glCode,
    });
  }
  separatedColumns.push({
    columnName: 'Product #',
    columnType: InventoryColumns.productNumber,
  });
  separatedColumns.push({
    columnName: 'Product Description',
    columnType: InventoryColumns.productDescription,
  });
  separatedColumns.push({
    columnName: 'Product Status',
    columnType: InventoryColumns.productStatus,
  });
  separatedColumns.push({
    columnName: 'Product Type',
    columnType: InventoryColumns.productType,
  });
  separatedColumns.push({
    columnName: 'Brand',
    columnType: InventoryColumns.productBrand,
  });
  separatedColumns.push({
    columnName: 'Pack Size',
    columnType: InventoryColumns.productPackageSize,
  });
  separatedColumns.push({
    columnName: 'Vendor',
    columnType: InventoryColumns.vendor,
  });
  separatedColumns.push({
    columnName: 'Unit By Case',
    columnType: InventoryColumns.unitByCase,
  });
  separatedColumns.push({
    columnName: 'Case Price',
    columnType: InventoryColumns.firstCasePrice,
  });
  separatedColumns.push({
    columnName: 'Each Price',
    columnType: InventoryColumns.firstEachPrice,
  });
  // Leave _ so that a separate column is created even though we want two columns with the same name
  separatedColumns.push({
    columnName: 'Case Price_',
    columnType: InventoryColumns.secondCasePrice,
  });
  separatedColumns.push({
    columnName: 'Each Price_',
    columnType: InventoryColumns.secondEachPrice,
  });
  separatedColumns.push({
    columnName: 'Case Price Change',
    columnType: InventoryColumns.casePriceChange,
  });
  separatedColumns.push({
    columnName: 'Case Price % Change',
    columnType: InventoryColumns.casePricePercentChange,
  });
  return separatedColumns;
};

export const getCostComparisonPdfColumnsConfig = (
  includeGlCodes: boolean,
): desiredColumn[] => {
  const separatedColumns: desiredColumn[] = [];
  if (includeGlCodes) {
    separatedColumns.push({
      columnName: 'GL Code',
      columnType: InventoryColumns.glCode,
      cellWidth: 120,
      halign: 'left',
      cellPadding: 10,
    });
  }
  separatedColumns.push({
    columnName: 'Product #',
    columnType: InventoryColumns.productNumber,
    cellWidth: 80,
  });
  separatedColumns.push({
    columnName: 'Product Description',
    columnType: InventoryColumns.productDescription,
    cellWidth: includeGlCodes ? 235 : undefined,
    halign: 'left',
    cellPadding: 10,
  });
  separatedColumns.push({
    columnName: 'Product Status',
    columnType: InventoryColumns.productStatus,
    cellWidth: 105,
  });
  separatedColumns.push({
    columnName: 'Product Type',
    columnType: InventoryColumns.productType,
    cellWidth: 105,
  });
  separatedColumns.push({
    columnName: 'Brand',
    columnType: InventoryColumns.productBrand,
    cellWidth: 100,
  });
  separatedColumns.push({
    columnName: 'Pack Size',
    columnType: InventoryColumns.productPackageSize,
    cellWidth: 90,
  });
  separatedColumns.push({
    columnName: 'Vendor',
    columnType: InventoryColumns.vendor,
    cellWidth: 85,
  });
  separatedColumns.push({
    columnName: 'Unit By Case',
    columnType: InventoryColumns.unitByCase,
    cellWidth: 70,
  });
  separatedColumns.push({
    columnName: 'Inventory 1\nCase / Each Price',
    columnType: InventoryColumns.inventoryOnePrices,
    cellWidth: 115,
  });
  separatedColumns.push({
    columnName: 'Inventory 2\nCase / Each Price',
    columnType: InventoryColumns.inventoryTwoPrices,
    cellWidth: 115,
  });
  separatedColumns.push({
    columnName: 'Case $\nChange',
    columnType: InventoryColumns.casePriceChange,
    cellWidth: 100,
  });
  separatedColumns.push({
    columnName: 'Case $ %\nChange',
    columnType: InventoryColumns.casePricePercentChange,
    cellWidth: 100,
  });
  return separatedColumns;
};

export const costComparisonFunctionMap = {
  [InventoryColumns.glCode]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString(costComparisonReportRow?.glString),
    );
  },
  [InventoryColumns.productNumber]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString(costComparisonReportRow.productNumber),
    );
  },
  [InventoryColumns.productStatus]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      costComparisonReportRow.productStatus,
    );
  },
  [InventoryColumns.productType]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      costComparisonReportRow.productType,
    );
  },
  [InventoryColumns.productDescription]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      costComparisonReportRow.productDescription,
    );
  },
  [InventoryColumns.productBrand]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString(costComparisonReportRow.brand),
    );
  },
  [InventoryColumns.productPackageSize]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      costComparisonReportRow.packSize,
    );
  },
  [InventoryColumns.vendor]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      costComparisonReportRow.vendor,
    );
  },
  [InventoryColumns.unitByCase]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      costComparisonReportRow.unitsByCase,
    );
  },
  [InventoryColumns.firstCasePrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString(
        getRawDocumentCurrency(costComparisonReportRow.firstCasePrice),
      ),
    );
  },
  [InventoryColumns.firstEachPrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString(
        getRawDocumentCurrency(costComparisonReportRow.firstEachPrice),
      ),
    );
  },
  [InventoryColumns.secondCasePrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString(
        getRawDocumentCurrency(costComparisonReportRow.secondCasePrice),
      ),
    );
  },
  [InventoryColumns.secondEachPrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString(
        getRawDocumentCurrency(costComparisonReportRow.secondEachPrice),
      ),
    );
  },
  [InventoryColumns.casePriceChange]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      costComparisonReportRow.casePriceChange,
    );
  },
  [InventoryColumns.casePricePercentChange]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentString(costComparisonReportRow.casePricePercentChange),
    );
  },
  [InventoryColumns.inventoryOnePrices]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    const pricesToDisplay: number[] = [];
    const unitsToDisplay: string[] = [];
    if (costComparisonReportRow.catchWeightFlag) {
      pricesToDisplay.push(costComparisonReportRow.firstCasePrice);
      unitsToDisplay.push('LB');
    } else {
      pricesToDisplay.push(
        costComparisonReportRow.firstCasePrice,
        costComparisonReportRow.firstEachPrice,
      );
      unitsToDisplay.push('CS', costComparisonReportRow.firstEachUnit);
    }
    const bothPrices = getDocumentString(
      getDocumentMultipleCurrency(pricesToDisplay, unitsToDisplay),
    );
    return addValueToColumns(columnName, columnMap, bothPrices);
  },
  [InventoryColumns.inventoryTwoPrices]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    costComparisonReportRow: CostComparisonReportRow,
  ) => {
    const pricesToDisplay: number[] = [];
    const unitsToDisplay: string[] = [];
    if (costComparisonReportRow.catchWeightFlag) {
      pricesToDisplay.push(costComparisonReportRow.secondCasePrice);
      unitsToDisplay.push('LB');
    } else {
      pricesToDisplay.push(
        costComparisonReportRow.secondCasePrice,
        costComparisonReportRow.secondEachPrice,
      );
      unitsToDisplay.push('CS', costComparisonReportRow.secondEachUnit);
    }
    const bothPrices = getDocumentString(
      getDocumentMultipleCurrency(pricesToDisplay, unitsToDisplay),
    );
    return addValueToColumns(columnName, columnMap, bothPrices);
  },
};

export const stringifyCostComparison = (
  separatedColumns: SeperatedColumn[],
  firstDate: string,
  secondDate: string,
): string => {
  // Variable to hold the seperated columns as one string
  let stringified = '';
  stringified +=
    ',,,,,,,,' +
    firstDate +
    ',' +
    firstDate +
    ',' +
    secondDate +
    ',' +
    secondDate +
    '\r\n';

  // Create the Header Row
  const headerRow = separatedColumns
    .map(pMap => pMap.columnName.replace('_', ''))
    .join(',');
  // Add the header row to return string
  stringified += headerRow + '\r\n';
  // Add every row of data to return string
  for (let i = 0; i < separatedColumns[0].columnValues.length; i++) {
    const line = separatedColumns
      .map(pm => (pm.columnValues ? pm.columnValues[i] : pm.singleValue))
      .join(',');
    stringified += line + '\r\n';
  }
  return stringified;
};

export const addInventoryNamesToHeader = (
  doc: jsPDF,
  firstInventoryName: string,
  secondInventoryName: string,
) => {
  const isMobile = Capacitor.isNativePlatform();
  let runningXPosition = 140;
  const yPosition = isMobile ? 75 : 85;
  const inventory1 = 'Inventory 1: ';
  const inventory2 = 'Inventory 2: ';
  doc.setFontSize(SUB_HEADER_FONT);
  doc.setFont('helvetica', 'bold');
  doc.text(inventory1, runningXPosition, yPosition);
  runningXPosition += inventory1.length * 5.4;
  doc.setFont('helvetica', 'normal');
  doc.text(firstInventoryName, runningXPosition, yPosition);
  runningXPosition += firstInventoryName.length * 5.4 + 40;
  doc.setFont('helvetica', 'bold');
  doc.text(inventory2, runningXPosition, yPosition);
  runningXPosition += inventory2.length * 5.4;
  doc.setFont('helvetica', 'normal');
  doc.text(secondInventoryName, runningXPosition, yPosition);
};

export const addTodaysDateToHeader = (doc: jsPDF, todaysDate: string) => {
  doc.setFontSize(HEADER_FONT);
  doc.setFont('helvetica', 'bold');
  const lengthOfName = 652;
  doc.text(todaysDate, lengthOfName, 50);
};

export const addCostComparisonRowsToPdf = async (
  doc: jsPDF,
  costComparisonReportRows: CostComparisonReportRow[],
  columnsConfig: desiredColumn[],
  includeGlCodes: boolean,
): Promise<jsPDF> => {
  const isMobile = Capacitor.isNativePlatform();
  const separatedColumns = createCostComparisonColumns(
    costComparisonReportRows,
    columnsConfig,
  );
  const cellDefinitions: CellDef[][] = createHeadersForPDF(columnsConfig);
  const { productRows, imageKeys } = createCostComparisonProductRowsForPdf(
    separatedColumns,
  );
  const imageColumnIndexes = isMobile ? [] : includeGlCodes ? [3, 4] : [2, 3];
  const imageMap = await getImageMap(imageColumnIndexes, imageKeys);
  let nextTableYPosition = isMobile ? 135 : 145;
  const columnStyles = getColumnStyles(columnsConfig);
  autoTable(doc, {
    head: cellDefinitions,
    body: productRows,
    startY: nextTableYPosition,
    margin: {
      left: 10,
      right: 10,
      bottom: 100,
    },
    theme: 'grid',
    tableWidth: 'auto',
    pageBreak: 'auto',
    rowPageBreak: 'avoid',
    styles: {
      fontSize: BODY_FONT,
      halign: 'center',
      valign: 'middle',
      lineColor: PDFColors.pdfBlack,
      lineWidth: 1,
      minCellHeight: 40,
    },
    columnStyles,
    willDrawCell: (cellData: CellHookData) => {
      if (
        cellData.row.section == 'body' &&
        imageColumnIndexes.includes(cellData.column.index)
      ) {
        doc.setTextColor(PDFColors.pdfWhite);
      } else if (cellData.row.section == 'body') {
        doc.setTextColor(PDFColors.pdfBlack);
      }
    },
    didDrawCell: (cellData: CellHookData) => {
      if (
        cellData.row.section == 'body' &&
        imageColumnIndexes.includes(cellData.column.index)
      ) {
        let imageIcon = '';
        cellData.cell.text.forEach(text => (imageIcon += text));
        const imageFound = imageIcon !== '';
        if (imageFound) {
          try {
            doc.addImage(
              imageMap.get(imageIcon),
              'PNG',
              cellData.cell.x + 7.5,
              cellData.cell.y + (cellData.cell.height - 20) / 2,
              90,
              20,
            );
          } catch (ignore) {}
        }
      }
    },
  });
  return doc;
};
