export const NOTIFICATION_COUNT_KEY = 'notification-count';
export enum AlertTypeEnum {
  TMD = '100001',
  LDE = '100021',
  LDED = '100022',
  DELIVERY_EXCEPTION = '100023',
  NOT_STARTED_CUTOFF = '100024',
  IN_PROGRESS_CUTOFF = '100025',
  DELIVERY_HEADSUP = '100026',
  PYMT_DUE_THIS_WK = '100027',
  PYMT_DUE_TODAY = '100028',
  PYMT_PAST_DUE = '100029',
  TM_NOTE = '100032',
  ORDER_CONFIRMATION = '100040',
  ORDER_EXCEPTION = '100041',
  ORDER_CANCELLATION = '100042',
  DIRECT_SHIP_TRACKING = '100044',
  DIRECT_STANDARD_DELIVERY = '100045',
  DIRECT_ORDER_EXCEPTIONS = '100046',
  DIRECT_DELIVERY_EXCEPTIONS = '100047',
}
