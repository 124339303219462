import { Injectable } from '@angular/core';
import { Product } from '@shared/models/product.model';
import { ProductService } from '../product/product.service';
import { BehaviorSubject, take } from 'rxjs';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { ModalService } from '../modal/modal.service';
import { ModalController } from '@ionic/angular';
import { ModalWithNavComponent } from '@shared/components/modal-with-nav/modal-with-nav.component';
import { WorksWellWithModalComponent } from '@shared/components/works-well-with/modal/works-well-with-modal';
import Swiper from 'swiper';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { ProductAnalyticsService } from '@usf/ngrx-product';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';

@Injectable({
  providedIn: 'root',
})
export class WorksWellWithService {
  constructor(
    private productService: ProductService,
    private analyticsService: ProductAnalyticsService,
    private modalService: ModalService,
    private modalCtrl: ModalController,
    private panAppState: PanAppState,
    private platformService: PlatformService,
  ) {}

  worksWellWithSwiperRef$: BehaviorSubject<Swiper> = new BehaviorSubject(null);

  // below units are px
  WORKS_WELL_WITH_TABLET_MODAL_HEIGHT_PORTRAIT = 800;
  WORKS_WELL_WITH_TABLET_MODAL_HEIGHT_LANDSCAPE = 900;
  MOBILE_SMALL_PORTRAIT = 359;
  MOBILE_MEDIUM_PORTRAIT = 767;
  MOBILE_AVERAGE_PORTRAIT = 820;
  TABLET_SMALL_LANDSCAPE = 800;
  TABLET_MEDIUM_LANDSCAPE = 1024;
  TABLET_LARGE_LANDSCAPE = 1400;

  // below are percentages for modal breakpoints
  TABLET_SMALL_BREAKPOINT = 0.95;
  TABLET_MEDIUM_BREAKPOINT = 0.8;
  TABLET_LARGE_BREAKPOINT = 0.5;
  TABLET_DEFAULT_BREAKPOINT = 1.0;
  MOBILE_AVERAGE_BREAKPOINT = 0.95;
  MOBILE_DEFAULT_BREAKPOINT = 0.65;

  public getWorksWellWithProducts(productNumbers: number[]) {
    this.productService.loadProducts(productNumbers);
    return this.productService.getProducts(productNumbers);
  }

  // filter products that are discontinued or don't exist
  // sort the remaining products by whether or not the quantity box is enabled
  public filterAndSortProducts(productsMap: Map<number, Product>) {
    return Array.from(productsMap.values())
      .filter(
        product =>
          !!product &&
          !product?.notFound &&
          !product.summary.properties?.has(ProductPropertiesEnum.discontinued),
      )
      .sort((productA, productB) => {
        if (
          productA.summary.properties?.has(ProductPropertiesEnum.specialOrder)
        ) {
          return 1;
        }

        if (
          productB.summary.properties?.has(ProductPropertiesEnum.specialOrder)
        ) {
          return -1;
        }

        if (productA.orderable === productB.orderable) {
          return 0;
        }

        if (productA.orderable === true) {
          return -1;
        }

        if (productB.orderable === true) {
          return 1;
        }
      });
  }

  async handleViewButtonClick(product: Product, productList: Product[]) {
    let customer;
    this.panAppState.customer$.pipe(take(1)).subscribe(c => (customer = c));
    let productDetails = productList.map(product => {
      return {
        ...product,
        selectedCustomer: customer,
      } as ProductDetailViewModel;
    });

    this.modalService.setModalOptions(
      this.platformService?.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage: WorksWellWithModalComponent,
        rootPageParams: {
          product: product,
          worksWellWithProducts: productDetails,
          swiperRef$: this.worksWellWithSwiperRef$,
          isLandscapeTablet:
            this.platformService?.getPlatformType() ===
              this.platformService?.platformEnum.tablet &&
            !!this.platformService?.getBrowserDimensions().isLandscape,
        },
      },
      'works-well-with-modal',
    );

    if (
      this.platformService?.getPlatformType() ===
      this.platformService?.platformEnum.tablet
    ) {
      this.modalService.modalOptions.breakpoints = [
        0,
        this.calculateTabletModalBreakpoint(),
      ];
      this.modalService.modalOptions.initialBreakpoint = this.calculateTabletModalBreakpoint();
    }

    if (
      this.platformService?.getPlatformType() ===
      this.platformService?.platformEnum.mobile
    ) {
      this.modalService.modalOptions.breakpoints = [
        0,
        this.calculateMobileModalBreakpoint(),
      ];
      this.modalService.modalOptions.initialBreakpoint = this.calculateMobileModalBreakpoint();
    }

    const modal = await this.modalCtrl.create(this.modalService.modalOptions);

    await modal.present();
  }

  public trackProductWorksWellWithButtonEvent(products: Product[]) {
    let productData: any[] = [];
    products.forEach(data => {
      const product = {
        divisionApn: `${data.summary.divisionNumber}-${data.summary.productNumber}`,
        attributes: data.trackingAttributes,
      };
      productData.push(product);
    });

    if (productData.length > 0) {
      this.analyticsService.trackProductWorksWellWithButton(productData);
    }
  }

  public calculateTabletModalBreakpoint() {
    if (!!this.platformService.getBrowserDimensions().isLandscape) {
      // need to calculate different breakpoints for various tablet sizes to avoid jumping on open
      // modal is very sensitive to height changes

      const height = this.platformService.getBrowserDimensions().height;
      if (height >= this.TABLET_LARGE_LANDSCAPE) {
        return this.TABLET_LARGE_BREAKPOINT;
      } else if (height >= this.TABLET_MEDIUM_LANDSCAPE) {
        return this.TABLET_MEDIUM_BREAKPOINT;
      } else if (height >= this.TABLET_SMALL_LANDSCAPE) {
        return this.TABLET_SMALL_BREAKPOINT;
      } else {
        return this.TABLET_DEFAULT_BREAKPOINT;
      }
    } else {
      // default to portrait
      return (
        this.WORKS_WELL_WITH_TABLET_MODAL_HEIGHT_PORTRAIT /
        this.platformService.getBrowserDimensions().height
      );
    }
  }

  public calculateMobileModalBreakpoint() {
    const height = this.platformService.getBrowserDimensions().height;
    // all mobile heights under 850px look much better like this
    if (height <= this.MOBILE_AVERAGE_PORTRAIT) {
      return this.MOBILE_AVERAGE_BREAKPOINT;
    } else {
      return this.MOBILE_DEFAULT_BREAKPOINT;
    }
  }
}
