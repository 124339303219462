import { Injectable } from '@angular/core';
import { ItemTypes } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { selectedCustomer } from '@app/ngrx-customer/store';
import { Dictionary } from '@ngrx/entity';
import { DyfProductInfo, DyfViewModel } from '@order/models/dyf-view-model';
import { FrequentlyBoughtTogetherViewModel } from '@order/models/frequently-bought-together-view-model';
import { InspiredPicksViewModel } from '@order/models/inspired-picks-view-model';
import { MerchZoneViewModel } from '@order/models/merch-zone-view-model';
import {
  AlternativeCartItem,
  CartItem,
  HandPricingDetails,
  HandPricingProductDetails,
  OrderViewModel,
  Shipment,
  ShipmentTypeEnum,
} from '@order/models/order-view-model';
import { UserState } from '@panamax/app-state';
import { ImageVariantEnum } from '@product-detail/models/image-variant.enum';
import { ProductAlternativeTypeEnum } from '@product-detail/models/product-alternative';
import { getFirstImageURL } from '@product-detail/utils/product-images.util';
import { ProductFilteringEnum } from '@shared/constants/product-filtering.enum';
import { UsfProductCardModeEnum } from '@shared/constants/usf-product-card-mode.enum';
import { ProductFilter } from '@shared/helpers/product-filter.helpers';
import { Product } from '@shared/models/product.model';
import { UsfProductCardViewModel } from '@shared/models/usf-product-card.model';
import { Customer } from '@usf/customer-types';
import {
  OrderHeader,
  OrderItem,
  OrderItemState,
  OrderStatus,
  Tallies,
  UqeProductLimits,
  UqeProductNumber,
} from '@usf/ngrx-order';
import { DirectShippingState } from '@usf/ngrx-order/lib/models/state/direct-ship-state';
import { DirectShippingResponse } from '@usf/order-types';
import { PartnerPunchoutProfile } from '@usf/partner-types';
import { ProductPricing, SellerPriceDetails } from '@usf/price-types';
import { ProductPropertiesEnum } from '@usf/product-types';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class OrderViewModelService {
  static buildHandPricingDetails(
    orderItemState: OrderItemState,
    product: Product,
    orderItem: OrderItem,
    pricing: ProductPricing,
  ): HandPricingProductDetails {
    const alternativeOrderItem =
      orderItemState?.entities[product?.alternative?.product?.productNumber];
    const thumbnailImageURL = getFirstImageURL(
      product?.summary,
      ImageVariantEnum.Thumbnail,
    );
    const xlargeImageURL = getFirstImageURL(
      product?.summary,
      ImageVariantEnum.XLarge,
    );

    const currentDetails: HandPricingDetails = {
      productNumber: product?.productNumber,
      brand: product?.summary?.brand,
      breakable: product?.summary?.breakable,
      description: product?.summary?.productDescTxtl,
      packSize: product?.summary?.salesPackSize,
      eachPrice: pricing?.eachPrice,
      eachUom: product?.summary?.eachUom,
      currentPrice: pricing?.unitPrice,
      priceUom: product?.summary?.priceUom,
      xlargeImageURL,
      thumbnailImageURL,
      newUnitPrice: orderItem.unitPriceOverride,
    };

    let altProductDetails: HandPricingDetails | undefined = undefined;

    if (alternativeOrderItem) {
      const alternativeProduct = product.alternative.product;
      const altThumbnailImageURL = getFirstImageURL(
        alternativeProduct.summary,
        ImageVariantEnum.Thumbnail,
      );
      const altXLargeImageURL = getFirstImageURL(
        alternativeProduct.summary,
        ImageVariantEnum.XLarge,
      );

      altProductDetails = {
        productNumber: alternativeProduct.productNumber,
        brand: alternativeProduct.summary?.brand,
        breakable: alternativeProduct.summary?.breakable,
        description: alternativeProduct.summary?.productDescTxtl,
        packSize: alternativeProduct.summary?.salesPackSize,
        eachPrice: alternativeProduct.pricing?.eachPrice,
        eachUom: alternativeProduct.summary?.eachUom,
        currentPrice: alternativeProduct.pricing?.unitPrice,
        priceUom: alternativeProduct.summary?.priceUom,
        thumbnailImageURL: altThumbnailImageURL,
        xlargeImageURL: altXLargeImageURL,
        newUnitPrice: alternativeOrderItem.unitPriceOverride,
      };
    }

    return {
      currentDetails,
      altProductDetails,
    };
  }

  static determinePricing(
    orderHeader: OrderHeader,
    orderItem: any,
    pricingState: Dictionary<ProductPricing>,
    product: Product,
    uqeProductNumbers: UqeProductNumber[],
  ) {
    let pricing: ProductPricing;
    //For edit submitted order, using price from recently submitted order
    if (orderHeader?.orderStatus === OrderStatus.SUBMITTED) {
      //Price validation: If price available, means item is submitted else get new price for newly added item
      const eachPriceValidation = !!orderItem.eachPrice
        ? orderItem.eachPrice
        : pricingState[orderItem.productNumber]?.eachPrice;
      const unitPriceValidation = !!orderItem.unitPrice
        ? orderItem.unitPrice
        : pricingState[orderItem.productNumber]?.unitPrice;
      const uomValidation = !!orderItem.priceUOM
        ? orderItem.priceUOM
        : pricingState[orderItem.productNumber]?.priceUom;
      pricing = {
        eachPrice: eachPriceValidation,
        priceUom: uomValidation,
        productNumber: orderItem.productNumber,
        unitPrice: unitPriceValidation,
      } as ProductPricing;
    } else {
      // For the fresh order, use latest price from the pricing state
      pricing = pricingState[orderItem?.productNumber];
    }
    const uqeProductNumber: UqeProductNumber = {
      productNumber: orderItem?.productNumber,
      pimGroupId: Number(product?.summary?.categoryCode),
    };
    uqeProductNumbers.push(uqeProductNumber);
    return pricing;
  }
  static createUqeProductLimitsMap(unusualQuantities: UqeProductLimits[]) {
    return new Map<number, UqeProductLimits>(
      unusualQuantities.map(uqe => [uqe.productNumber, uqe]),
    );
  }
  static createSellerPriceMap(sellerPrices: SellerPriceDetails[]) {
    const sellerPrice = {};
    sellerPrices?.forEach(price => {
      sellerPrice[price.productNumber] = price;
    });
    return sellerPrice;
  }

  static applyUnitPriceOverride = (
    orderItem: OrderItem,
    loggedInUser: UserState,
    pricing: ProductPricing,
  ): number => {
    if (
      loggedInUser?.tmUser === 'Y' &&
      (!!orderItem?.unitPriceOverride || orderItem?.unitPriceOverride === 0) &&
      typeof (orderItem?.unitPriceOverride * 1) === 'number'
    ) {
      return orderItem?.unitPrice;
    } else {
      return pricing?.unitPrice || 0;
    }
  };
  static applyEachPriceOverride = (
    orderItem: OrderItem,
    loggedInUser: UserState,
    pricing: ProductPricing,
  ): number => {
    if (
      loggedInUser?.tmUser === 'Y' &&
      (!!orderItem?.unitPriceOverride || orderItem?.unitPriceOverride === 0) &&
      typeof (orderItem?.unitPriceOverride * 1) === 'number' &&
      !!orderItem?.eachPrice
    ) {
      return orderItem?.eachPrice;
    } else {
      return pricing?.eachPrice || 0;
    }
  };

  static addAutosubMessage = (
    orderStatus: OrderStatus,
    alternativeOrderItem: OrderItem,
    product: any,
    platformType,
  ) => {
    if (
      (orderStatus === OrderStatus.SUBMITTED ||
        orderStatus === OrderStatus.SUBMITTED_WITH_EXCEPTIONS ||
        orderStatus === OrderStatus.SUBMITTED_CREDIT_HOLD) &&
      !!alternativeOrderItem &&
      alternativeOrderItem.substituteFlag === 'A' &&
      alternativeOrderItem.substituteFor === product.productNumber
    ) {
      product = {
        ...product,
        drawerMsg: {
          message:
            'Auto-Subbed with product ' +
            alternativeOrderItem.productNumber +
            '. If your original product becomes available, we’ll ship it.',
          secondaryMessage: '',
          secondaryMessageDate: '',
          iconName: 'information-circle-outline',
          isWarning: undefined,
          isAutoSubbed: true,
        },
      };
      if (platformType !== 'desktop') {
        product = {
          ...product,
          productCardResolutionMsg: 'Auto-Sub Product',
        };
      }
    }
    return product;
  };

  static calculateOrderWeight = (
    grossWeight: number,
    cases: number,
    convFactor: number,
    eaches: number,
  ): number => {
    const grossMulCases = grossWeight * cases;
    const adjustedConvFactor = convFactor === 0 ? 1 : convFactor;
    const dividedByConv = !!convFactor ? grossWeight / adjustedConvFactor : 0;
    const convMulEaches = dividedByConv * eaches;
    const wgt = grossMulCases + convMulEaches;
    return wgt;
  };

  static createProductCardViewModel = (
    orderHeader: OrderHeader,
    product: Product,
    inProgressOrderItems,
    online: boolean,
    selectedCustomer,
    allowHandPricing: boolean,
    handPricingDetails: HandPricingProductDetails,
    loggedInUser: UserState,
    pricing: ProductPricing,
    pricingState: Dictionary<ProductPricing>,
    orderStatus: OrderStatus,
    platformType: string,
    showConfirmQuantityButton: boolean,
  ): UsfProductCardViewModel => {
    const orderItem =
      inProgressOrderItems?.entities[
        inProgressOrderItems?.ids.find(id => id === product?.productNumber)
      ];
    if (!!product && !!orderItem) {
      product.eachesOrdered = orderItem?.eachesOrdered;
      product.casesOrdered = orderItem?.unitsOrdered;
    }

    const unitPriceOverride = OrderViewModelService.applyUnitPriceOverride(
      orderItem,
      loggedInUser,
      pricing,
    );
    const eachPriceOverride = OrderViewModelService.applyEachPriceOverride(
      orderItem,
      loggedInUser,
      pricing,
    );

    let productWithPriceOverride = {
      ...product,
      pricing: {
        ...product?.pricing,
        unitPrice: unitPriceOverride,
        eachPrice: eachPriceOverride,
      },
    };

    if (showConfirmQuantityButton) {
      productWithPriceOverride = {
        ...productWithPriceOverride,
        hasAlternative: false,
      };
    }

    const alternativeOrderItem =
      inProgressOrderItems?.entities[
        inProgressOrderItems?.ids.find(
          id => id === product?.alternative?.product?.productNumber,
        )
      ];

    if (
      !!product &&
      !!alternativeOrderItem &&
      productWithPriceOverride.alternative.product?.pricing
    ) {
      product.alternative.product.eachesOrdered =
        alternativeOrderItem?.eachesOrdered;
      product.alternative.product.casesOrdered =
        alternativeOrderItem?.unitsOrdered;

      const alternativePriceOverride = OrderViewModelService.applyUnitPriceOverride(
        alternativeOrderItem,
        loggedInUser,
        pricingState[product.alternative.product.productNumber],
      );

      productWithPriceOverride = {
        ...productWithPriceOverride,
        alternative: {
          ...productWithPriceOverride.alternative,
          product: {
            ...productWithPriceOverride.alternative.product,
            pricing: {
              ...productWithPriceOverride.alternative.product.pricing,
              unitPrice: alternativePriceOverride,
            },
          },
        },
      };
    }

    productWithPriceOverride = OrderViewModelService.addAutosubMessage(
      orderStatus,
      alternativeOrderItem,
      productWithPriceOverride,
      platformType,
    );

    const productCardVM: UsfProductCardViewModel = {
      mode:
        orderHeader?.orderType === 'VS' &&
        !!orderHeader.tandemOrderNumber &&
        orderHeader.orderModifiable
          ? UsfProductCardModeEnum.modifyVS
          : UsfProductCardModeEnum.reviewOrder,
      product: productWithPriceOverride,
      itemType: ItemTypes.product,
      isOnline: online,
      pricingDisabled: selectedCustomer?.ogPrintPriceInd === 'N',
      allowHandPricing,
      handPricingDetails,
      showConfirmQuantityButton,
    };
    return productCardVM;
  };

  static calculateShipmentTallies = (
    shipment: Shipment,
    orderItem: OrderItem,
    newCartItem: CartItem,
    loggedInUser: UserState,
    directShippingSavings?: DirectShippingResponse,
  ): Tallies => {
    // Add Tallies to the shipment
    shipment.shipmentTallies.totalCases +=
      orderItem?.unitsOrdered?.currentValue || 0;
    shipment.shipmentTallies.totalEaches +=
      orderItem?.eachesOrdered?.currentValue || 0;
    shipment.shipmentTallies.totalItems +=
      (orderItem?.unitsOrdered?.currentValue || 0) +
      (orderItem?.eachesOrdered?.currentValue || 0);
    if (shipment.shipmentType === ShipmentTypeEnum.routed) {
      shipment.shipmentTallies.orderWeight += OrderViewModelService.calculateOrderWeight(
        newCartItem.productState?.grossWeight || 0,
        orderItem.unitsOrdered.currentValue || 0,
        newCartItem.productInventory?.eachConversionFactor || 0,
        orderItem.eachesOrdered.currentValue || 0,
      );
    } else {
      shipment.shipmentTallies.orderWeight = 0;
      shipment.shipmentTallies.totalDirectShipmentSavings = directShippingSavings?.directShippingVendors?.find(
        vendor => vendor.purchaseFromVendor === shipment.vendorNumber,
      )?.savings; //returns savings by current vendor shipment
    }

    const unitPrice = OrderViewModelService.applyUnitPriceOverride(
      orderItem,
      loggedInUser,
      newCartItem.pricing,
    );
    const eachPrice = OrderViewModelService.applyEachPriceOverride(
      orderItem,
      loggedInUser,
      newCartItem.pricing,
    );

    shipment.shipmentTallies.totalPrice +=
      OrderViewModelService.calculatePrice(
        orderItem?.unitsOrdered?.currentValue,
        unitPrice?.toString(),
        true,
        newCartItem.productState?.catchWeightFlag,
        newCartItem.productState?.netWeight,
        newCartItem.productInventory?.eachConversionFactor,
      ) +
      OrderViewModelService.calculatePrice(
        orderItem?.eachesOrdered?.currentValue,
        eachPrice?.toString(),
        false,
        newCartItem.productState?.catchWeightFlag,
        newCartItem.productState?.netWeight,
        newCartItem.productInventory?.eachConversionFactor,
      );
    return shipment.shipmentTallies;
  };

  static calculatePrice = (
    quantity: number,
    price: string,
    isCases: boolean,
    isCatchWeight: boolean,
    netWeight: number,
    eachConversionFactor: number,
  ): number => {
    if (quantity && price) {
      if (isCases) {
        return (
          parseFloat(price || '0') * quantity * (isCatchWeight ? netWeight : 1)
        );
      } else {
        const adjustedConversionFactor =
          eachConversionFactor === 0 ? 1 : eachConversionFactor;
        return (
          (parseFloat(price || '0') *
            quantity *
            (isCatchWeight ? netWeight : 1)) /
          (isCatchWeight ? adjustedConversionFactor : 1)
        );
      }
    } else {
      return 0;
    }
  };

  static calculateDeliveryDate = (vendorLeadTime: number): Date => {
    let deliveryDate = new Date();
    if (!!vendorLeadTime) {
      deliveryDate = dayjs().add(vendorLeadTime, 'day').toDate();
    }
    return deliveryDate;
  };

  static displayPricingLink = (
    productState,
    pricing: ProductPricing,
    sellerPrice: any,
    tmUser: boolean,
    online: boolean,
    isSpecialVendor: boolean,
  ) => {
    const cashAndCarryInd =
      !!productState?.cashCarryIndicator &&
      productState.cashCarryIndicator !== '2' &&
      productState.cashCarryIndicator !== '3';
    const hasPrice = !!pricing && !!parseFloat(`${pricing.unitPrice}`);
    const productNumber = productState?.productNumber;
    const priceOverrideInd =
      !!sellerPrice[productNumber]?.currentPrice?.priceControls
        ?.priceOverrideInd &&
      sellerPrice[productNumber].currentPrice.priceControls.priceOverrideInd ===
        'Y';
    return (
      tmUser &&
      cashAndCarryInd &&
      hasPrice &&
      priceOverrideInd &&
      online &&
      !isSpecialVendor
    );
  };

  static getDYFProductsForBanner = (
    dyfProductVM: DyfViewModel,
    selectedCustomer: Customer,
  ) => {
    // Apply shared dyfProduct filtering logic
    const filteredProductsMap = dyfProductVM?.products?.filter(productInfo =>
      ProductFilter.applyFilter(
        productInfo.product,
        selectedCustomer,
        ProductFilteringEnum.DYF,
      ),
    );

    if (!!filteredProductsMap?.length) {
      const dyfProductsWithImages = filteredProductsMap.filter(
        productInfo => !!productInfo.imageUrl,
      );
      const dyfProducts = !!dyfProductsWithImages?.length
        ? dyfProductsWithImages.slice(0, 4)
        : filteredProductsMap.slice(0, 4);
      return dyfProducts;
    }
    return [];
  };

  static getInspiredPicksProductsForBanner = (
    inspiredPickVM: InspiredPicksViewModel,
  ) => {
    if (!!inspiredPickVM) {
      const inspiredPicksProductsWithImages = inspiredPickVM?.products?.filter(
        productInfo => !!productInfo.imageUrl,
      );
      const ipProducts = !!inspiredPicksProductsWithImages?.length
        ? inspiredPicksProductsWithImages.slice(0, 4)
        : inspiredPickVM?.products?.slice(0, 4);
      return ipProducts;
    }
    return [];
  };

  static isRestrictedToOrderGuide = (
    customer: Customer,
    restrictedNationalFlagEnabled: boolean,
  ) => {
    if (restrictedNationalFlagEnabled) {
      return (
        customer?.restrictToOG === 'Y' ||
        customer?.restrictToOG === 'M' ||
        customer?.customerType === 'NA'
      );
    } else {
      return false;
    }
  };

  static newOrderTallies(): Tallies {
    return {
      totalCases: 0,
      totalEaches: 0,
      totalItems: 0,
      totalShipments: 0,
      totalPrice: 0,
      orderWeight: 0,
      totalDirectShipmentSavings: 0,
    } as Tallies;
  }

  static sortOrderItems(orderItemState: OrderItemState): OrderItem[] {
    let sortedOrderItems = [];
    if (orderItemState && orderItemState?.ids?.length > 0) {
      sortedOrderItems = Object.values(orderItemState.ids).map(
        id => orderItemState.entities[id],
      );
    }
    return sortedOrderItems;
  }

  static unitPriceOverride(orderItemState: OrderItemState, product: Product) {
    return orderItemState?.entities[
      product?.alternative?.product?.productNumber
    ];
  }

  static categorizeShipment(
    shipments: Shipment[],
    newCartItem: CartItem,
    product: Product,
    orderItem: OrderItem,
    loggedInUser: UserState,
    directShippingState: DirectShippingState,
    orderHeader: OrderHeader,
    orderItemState: OrderItemState,
    online: boolean,
    selectedCustomer: Customer,
    allowHandPricing: boolean,
    handPricingDetails: HandPricingProductDetails,
    pricing: ProductPricing,
    pricingState: Dictionary<ProductPricing>,
    platformType: string,
  ): Shipment[] {
    // Determine if the product should be in a routed or direct shipment
    const isRoutedShipment = OrderViewModelService.shouldRouteProduct(product);
    const orderStatus = orderHeader?.orderStatus;

    if (isRoutedShipment) {
      OrderViewModelService.addOrUpdateRoutedShipment(
        shipments,
        newCartItem,
        product,
        orderItem,
        loggedInUser,
        orderHeader,
        orderItemState,
        online,
        selectedCustomer,
        allowHandPricing,
        handPricingDetails,
        pricing,
        pricingState,
        orderStatus,
        platformType,
      );
    } else {
      OrderViewModelService.addOrUpdateDirectShipment(
        shipments,
        newCartItem,
        product,
        orderItem,
        loggedInUser,
        directShippingState,
        orderHeader,
        orderItemState,
        online,
        selectedCustomer,
        allowHandPricing,
        handPricingDetails,
        pricing,
        pricingState,
        orderStatus,
        platformType,
      );
    }
    return shipments;
  }

  static shouldRouteProduct(product: Product): boolean {
    return (
      ['0', '2', '3', '4', '8', '9'].includes(
        product?.inventory?.productStatus,
      ) ||
      (product?.inventory?.productStatus === '1' &&
        !product?.summary?.properties?.has(ProductPropertiesEnum.specialVendor))
    );
  }

  static addOrUpdateRoutedShipment(
    shipments: Shipment[],
    newCartItem: CartItem,
    product: Product,
    orderItem: OrderItem,
    loggedInUser: UserState,
    orderHeader: OrderHeader,
    orderItemState: OrderItemState,
    online: boolean,
    selectedCustomer: Customer,
    allowHandPricing: boolean,
    handPricingDetails: HandPricingProductDetails,
    pricing: ProductPricing,
    pricingState: Dictionary<ProductPricing>,
    orderStatus: OrderStatus,
    platformType: string,
  ): Shipment[] {
    // Find existing routed shipment or create a new one
    let routedShipment = shipments.find(
      shipment => shipment.shipmentType === ShipmentTypeEnum.routed,
    );
    if (!routedShipment) {
      routedShipment = OrderViewModelService.createNewRoutedShipment(
        orderHeader,
      );
      shipments.push(routedShipment);
    }

    // Update routed shipment with new cart item
    routedShipment.cartItems.push(newCartItem);
    routedShipment.containsItemBeingFiltered = routedShipment.cartItems.some(
      cartItem => cartItem.matchesSearchFilter,
    );
    routedShipment.productCardVms.push(
      OrderViewModelService.createProductCardViewModel(
        orderHeader,
        product,
        orderItemState,
        online,
        selectedCustomer,
        allowHandPricing,
        handPricingDetails,
        loggedInUser,
        pricing,
        pricingState,
        orderStatus,
        platformType,
        false,
      ),
    );
    routedShipment.shipmentTallies = OrderViewModelService.calculateShipmentTallies(
      routedShipment,
      orderItem,
      newCartItem,
      loggedInUser,
    );
    return shipments;
  }

  static addOrUpdateDirectShipment(
    shipments: Shipment[],
    newCartItem: CartItem,
    product: Product,
    orderItem: OrderItem,
    loggedInUser: UserState,
    directShippingState: DirectShippingState,
    orderHeader: OrderHeader,
    orderItemState: OrderItemState,
    online: boolean,
    selectedCustomer: Customer,
    allowHandPricing: boolean,
    handPricingDetails: HandPricingProductDetails,
    pricing: ProductPricing,
    pricingState: Dictionary<ProductPricing>,
    orderStatus: OrderStatus,
    platformType: string,
  ): void {
    // Find existing direct shipment or create a new one
    let directShipment = shipments.find(
      shipment =>
        shipment.shipmentType === ShipmentTypeEnum.vendor &&
        shipment.vendorNumber === product?.inventory?.purchaseFromVendor,
    );

    if (!directShipment) {
      directShipment = OrderViewModelService.createNewDirectShipment(product);
      shipments.push(directShipment);
    }

    // Update direct shipment with new cart item
    const updatedOrderItem: OrderItem = {
      ...orderItem,
    };
    newCartItem.orderItem = updatedOrderItem;

    directShipment.cartItems.push(newCartItem);
    directShipment.containsItemBeingFiltered = directShipment.cartItems.some(
      cartItem => cartItem.matchesSearchFilter,
    );
    directShipment.productCardVms.push(
      OrderViewModelService.createProductCardViewModel(
        orderHeader,
        product,
        orderItemState,
        online,
        selectedCustomer,
        allowHandPricing,
        handPricingDetails,
        loggedInUser,
        pricing,
        pricingState,
        orderStatus,
        platformType,
        false,
      ),
    );
    const directShppingSavings =
      directShippingState?.entities?.[orderHeader?.orderId]
        ?.directShippingSavings;
    directShipment.shipmentTallies = OrderViewModelService.calculateShipmentTallies(
      directShipment,
      orderItem,
      newCartItem,
      loggedInUser,
      directShppingSavings,
    );
  }

  static createNewRoutedShipment(orderHeader: OrderHeader): Shipment {
    return {
      productCardVms: [],
      shipmentType: ShipmentTypeEnum.routed,
      containsItemBeingFiltered: true,
      deliveryDate: orderHeader?.requestedDeliveryDate as Date,
      cartItems: [],
      shipmentTallies: OrderViewModelService.initializeShipmentTallies(),
    };
  }

  static createNewDirectShipment(product: Product): Shipment {
    return {
      productCardVms: [],
      shipmentType: ShipmentTypeEnum.vendor,
      containsItemBeingFiltered: true,
      vendorNumber: product?.inventory?.purchaseFromVendor,
      deliveryDate: OrderViewModelService.calculateDeliveryDate(
        product?.inventory?.vendorLeadTime,
      ),
      cartItems: [],
      shipmentTallies: OrderViewModelService.initializeShipmentTallies(),
    };
  }

  static initializeShipmentTallies(): Tallies {
    return {
      totalCases: 0,
      totalEaches: 0,
      totalItems: 0,
      totalPrice: 0,
      orderWeight: 0,
    };
  }

  static finalizeShipments(shipments: Shipment[], orderTallies: Tallies): void {
    orderTallies = OrderViewModelService.calculateOrderTallies(shipments);
    shipments = OrderViewModelService.sortShipments(shipments);
  }

  static calculateOrderTallies(shipments: Shipment[]): Tallies {
    let totalCases = 0,
      totalEaches = 0,
      totalItems = 0,
      totalPrice = 0,
      orderWeight = 0,
      totalShipments = 0,
      totalDirectShipmentSavings = 0;

    shipments.forEach(shipment => {
      totalCases += shipment.shipmentTallies.totalCases;
      totalEaches += shipment.shipmentTallies.totalEaches;
      totalItems += shipment.shipmentTallies.totalItems;
      totalPrice += shipment.shipmentTallies.totalPrice;
      orderWeight += shipment.shipmentTallies.orderWeight;
      totalShipments += 1;
      totalDirectShipmentSavings += !!shipment?.shipmentTallies
        ?.totalDirectShipmentSavings
        ? shipment.shipmentTallies.totalDirectShipmentSavings
        : 0;
    });

    return {
      totalCases,
      totalEaches,
      totalItems,
      totalPrice,
      orderWeight,
      totalShipments,
      totalDirectShipmentSavings,
    };
  }

  static sortShipments(shipments: Shipment[]): Shipment[] {
    return shipments.sort((a, b) =>
      a.shipmentType === ShipmentTypeEnum.vendor ? 1 : -1,
    );
  }

  static buildViewModel(
    shipments: Shipment[],
    orderHeader: OrderHeader,
    orderItemState: OrderItemState,
    alternatives: Map<number, AlternativeCartItem>,
    loggedInUser: UserState,
    punchoutProfile: PartnerPunchoutProfile,
    dyfProductVM: DyfViewModel,
    inspiredPickVM: InspiredPicksViewModel,
    merchZoneVM: MerchZoneViewModel,
    frequentlyBoughtTogetherVM: FrequentlyBoughtTogetherViewModel,
    sellerPrice: {},
    online: boolean,
    productsMap: Map<number, Product>,
    uqeProductNumbers: UqeProductNumber[],
    uqeProductLimits: Map<number, UqeProductLimits>,
    selectedCustomer: Customer,
    restrictedNationalFlagEnabled: boolean,
    uqeCards: UsfProductCardViewModel[],
    hasSpecialVendorItem: boolean,
  ): OrderViewModel {
    return {
      orderHeader,
      orderItemState,
      shipments,
      orderTallies: OrderViewModelService.calculateOrderTallies(shipments),
      alternatives,
      usfOrderDeliveryDate: OrderViewModelService.getUsfOrderDeliveryDate(
        orderHeader,
      ),
      sellerPrice,
      tmUser: loggedInUser?.tmUser === 'Y',
      online,
      allProductIds: OrderViewModelService.extractAllProductIds(
        orderItemState,
        alternatives,
      ),
      productsMap: productsMap,
      uqeProductNumbers,
      uqeProductLimits,
      uqeCards,
      dyfProducts: OrderViewModelService.filterDyfProducts(
        dyfProductVM,
        selectedCustomer,
        orderItemState,
      ),
      inspiredPickProducts: OrderViewModelService.filterInspiredPickProducts(
        inspiredPickVM,
      ),
      merchZoneProducts: OrderViewModelService.filterMerchZoneProducts(
        merchZoneVM,
      ),
      frequentlyBoughtTogetherProducts: frequentlyBoughtTogetherVM,
      totalDyfProducts: dyfProductVM?.products?.length,
      restrictToOG: this.isRestrictedToOrderGuide(
        selectedCustomer,
        restrictedNationalFlagEnabled,
      ),
      submitButtonText: punchoutProfile?.submitButton ?? undefined,
      partnerAutotransfer: punchoutProfile?.autoTransfer,
      disableSubmit: uqeCards.length > 0,
      additionalDeliveryInstructions: OrderViewModelService.handleSpecialInstructions(
        orderHeader,
        hasSpecialVendorItem,
        selectedCustomer,
      ),
    };
  }

  static extractAllProductIds(
    orderItemState: OrderItemState,
    alternatives: Map<number, AlternativeCartItem>,
  ): number[] {
    return [
      ...(orderItemState?.ids || []),
      ...Array.from(alternatives.keys()),
    ].map((id: string | number) => parseInt(id as string));
  }

  static filterDyfProducts(
    dyfProductVM: DyfViewModel,
    selectedCustomer: Customer,
    orderItemState: OrderItemState,
  ): DyfProductInfo[] {
    return OrderViewModelService.getDYFProductsForBanner(
      dyfProductVM,
      selectedCustomer,
    ).filter(
      // remove products from DYF banner which are already in the cart
      dyfProductInfo =>
        !(orderItemState.ids as Number[]).includes(
          dyfProductInfo.product.productNumber,
        ),
    );
  }

  static filterInspiredPickProducts(inspiredPickVM: InspiredPicksViewModel) {
    return {
      ...inspiredPickVM,
      products: OrderViewModelService.getInspiredPicksProductsForBanner(
        inspiredPickVM,
      ),
    };
  }

  static filterMerchZoneProducts(merchZoneVM: MerchZoneViewModel) {
    return {
      ...merchZoneVM,
      products: merchZoneVM?.products?.slice(0, 4),
    };
  }

  static getUsfOrderDeliveryDate(orderHeader: OrderHeader): string | Date {
    return orderHeader?.orderStatus === OrderStatus.SUBMITTED ||
      orderHeader?.orderStatus === OrderStatus.SUBMITTED_WITH_EXCEPTIONS
      ? orderHeader?.confirmedDeliveryDate
      : orderHeader?.requestedDeliveryDate;
  }

  static createCartItem(
    orderItem: OrderItem,
    product: Product,
    pricing: ProductPricing,
    allowHandPricing: boolean,
    thumbnailImageURL: string,
    xlargeImageURL: string,
    unusualQuantityLimit: number,
    matchesSearchFilter: boolean,
  ): CartItem {
    return {
      orderItem,
      productState: product?.summary,
      productInventory: product?.inventory,
      pricing,
      altProductNumber: product?.alternative?.product?.productNumber,
      displayUpdatePricingLink: allowHandPricing,
      warningMsg: product?.productCardWarningMsg,
      isAvailable: product?.orderable,
      thumbnailImageURL,
      xlargeImageURL,
      unusualQuantityLimit,
      matchesSearchFilter,
    };
  }

  static processAutoSubbedProducts(
    orderItemState: OrderItemState,
    selectedCustomer: Customer,
    productsMap: Map<number, Product>,
  ): Map<number, Product> {
    if (!selectedCustomer?.autoSubProducts) {
      return productsMap;
    }

    const orderItems = orderItemState.ids.map(
      id => orderItemState.entities[id],
    );

    orderItems
      .filter(item => item.substituteFlag === 'A')
      .forEach(item => {
        const originalItem = orderItemState.entities[item.substituteFor];
        if (originalItem) {
          const origProduct = productsMap.get(item.substituteFor);
          if (origProduct) {
            const modifiedProduct = {
              ...origProduct,
              productCardResolutionMsg: 'Auto-Sub Product',
              alternative: {
                available: true,
                prioritizeAlternative: false,
                required: true,
                product: productsMap.get(item.productNumber),
                type: ProductAlternativeTypeEnum.substitute,
              },
            };
            productsMap.set(origProduct.productNumber, modifiedProduct);
          }
        }
      });

    return productsMap;
  }

  static processAlternativeProducts(
    product: Product,
    orderItem: OrderItem,
    orderItemState: OrderItemState,
    uqeProductLimits: Map<number, UqeProductLimits>,
  ): Map<number, AlternativeCartItem> {
    const alternatives = new Map<number, AlternativeCartItem>();

    if (
      product?.alternative?.required &&
      product?.alternative?.available &&
      !product?.alternative?.prioritizeBaseProduct
    ) {
      const altProduct =
        orderItemState?.entities[product?.alternative?.product?.productNumber];
      const unusualQuantityLimit = uqeProductLimits.get(
        altProduct?.productNumber,
      )?.quantityLimit;

      alternatives.set(product.alternative.product?.productNumber, {
        parentProductNumber: orderItem.productNumber,
        productAlternative: product.alternative,
        eachesOrdered: altProduct?.eachesOrdered || {
          previousValue: 0,
          currentValue: 0,
          loading: false,
          error: null,
        },
        unitsOrdered: altProduct?.unitsOrdered || {
          previousValue: 0,
          currentValue: 0,
          loading: false,
          error: null,
        },
        unusualQuantityLimit: unusualQuantityLimit || 0,
        unitPriceOverride: altProduct?.unitPriceOverride,
        specialVendor: altProduct?.specialVendor,
        vendorLeadTime: altProduct?.vendorLeadTime,
      });
    }

    return alternatives;
  }

  static orderItemExceedsQuantityLimit = (
    orderItem: OrderItem,
    quantityLimit: number,
  ): boolean => {
    if (
      !orderItem.quantityAccepted &&
      orderItem.unitsOrdered?.currentValue > quantityLimit
    )
      return true;
    return false;
  };

  static addAltProductToUqe(
    uqeProductNumbers: UqeProductNumber[],
    productsMap: Map<number, Product>,
    product: Product,
  ) {
    if (product?.alternative?.product?.productNumber) {
      const categoryCode = productsMap.get(
        product.alternative.product.productNumber,
      )?.summary?.categoryCode;
      const uqAlternativeProductNumber: UqeProductNumber = {
        productNumber: product.alternative.product.productNumber,
        pimGroupId: categoryCode ? Number(categoryCode) : undefined,
      };
      if (
        !uqeProductNumbers.find(
          uq => uq.productNumber === product.alternative.product.productNumber,
        )
      ) {
        uqeProductNumbers.push(uqAlternativeProductNumber);
      }
    }
  }

  static validateOrderItemStateVendorDetails(
    productsMap: Map<number, Product>,
    orderHeader: OrderHeader,
    orderItemState: OrderItemState,
  ): OrderItemState {
    let updatedOrderItemState = orderItemState;
    if (
      orderHeader.orderStatus === OrderStatus.IN_PROGRESS ||
      orderHeader.orderStatus === OrderStatus.SUBMISSION_ERROR ||
      orderHeader.orderStatus === OrderStatus.ORDER_ERROR ||
      orderHeader.orderStatus === OrderStatus.SUBMITTED ||
      orderHeader.orderStatus === OrderStatus.SUBMITTED_WITH_EXCEPTIONS ||
      orderHeader.orderStatus === OrderStatus.SUBMITTED_CREDIT_HOLD
    ) {
      for (let orderItem of Object.values(orderItemState.entities)) {
        const product = productsMap.get(orderItem.productNumber);
        updatedOrderItemState.entities[
          orderItem.productNumber
        ] = OrderViewModelService.updateOrderItemVendorDetails(
          product,
          orderItem,
        );
      }
    }
    return updatedOrderItemState;
  }
  static updateOrderItemVendorDetails(
    product: Product,
    orderItem: OrderItem,
  ): OrderItem {
    let updatedOrderItem = orderItem;

    if (
      orderItem?.purchasedFromVendor !== product?.summary?.purchaseFromVendor ||
      orderItem?.specialVendor !==
        !!product?.summary?.properties?.has(
          ProductPropertiesEnum.specialVendor,
        ) ||
      orderItem?.vendorLeadTime !== product?.inventory?.vendorLeadTime
    ) {
      updatedOrderItem = {
        ...orderItem,
        purchasedFromVendor: product?.summary?.purchaseFromVendor,
        specialVendor: !!product?.summary?.properties?.has(
          ProductPropertiesEnum.specialVendor,
        ),
        vendorLeadTime: product?.inventory?.vendorLeadTime,
      };
    }
    return updatedOrderItem;
  }

  static handleSpecialInstructions(
    orderHeader: OrderHeader,
    hasSpecialVendorItem: boolean,
    selectedCustomer: Customer,
  ): string {
    if (
      orderHeader?.orderType === 'RT' &&
      ((hasSpecialVendorItem &&
        selectedCustomer?.deliveryInstructions ===
          orderHeader?.specialDeliveryInstructions) ||
        !hasSpecialVendorItem)
    ) {
      return '';
    } else {
      return orderHeader.specialDeliveryInstructions;
    }
  }
}
