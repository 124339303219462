import { Injectable } from '@angular/core';
import { Papa } from 'ngx-papaparse';

@Injectable({
  providedIn: 'root',
})
export class DocumentImportService {
  constructor(private papa: Papa) {}

  breakDownCSV = async (csvString: string) => {
    const csvAsArray = (await this.papa.parse(csvString)?.data) ?? [];
    return csvAsArray;
  };

  iterateOverKeysAndAddToMap = (
    keys: string[],
    row: string[],
    columnIndexMap: Map<number, string>,
  ) => {
    keys.forEach(key => {
      for (var j = 0; j < row.length; j++) {
        if (row[j].toUpperCase() === key.toUpperCase()) {
          columnIndexMap.set(j, key);
        }
      }
    });
  };

  convertRowToObject = (row: string[], columnIndexMap: Map<number, string>) => {
    let objectToBeReturned = {};
    for (var j = 0; j < row.length; j++) {
      if (columnIndexMap.has(j)) {
        objectToBeReturned[columnIndexMap.get(j)] = row[j];
      }
    }
    return objectToBeReturned;
  };

  trimCSVArraytoJustDesiredColumns = (
    columnsRequired: string[],
    csvArray: string[][],
    columnsDesired?: string[],
  ) => {
    const columnIndexMap = new Map<number, string>();
    let trimmedContent = [];
    let columnsFound = false;

    for (var i = 0; i < csvArray.length; i++) {
      const row = csvArray[i];

      if (!columnsFound) {
        this.iterateOverKeysAndAddToMap(columnsRequired, row, columnIndexMap);
        columnsFound = columnsRequired.length === columnIndexMap.size;
        if (columnsFound && columnsDesired) {
          this.iterateOverKeysAndAddToMap(columnsDesired, row, columnIndexMap);
        }
      } else {
        trimmedContent.push({
          ...this.convertRowToObject(row, columnIndexMap),
          row: i + 1,
        });
      }
    }
    return trimmedContent;
  };
}
